import { memo, useCallback } from 'react';
import { ContractsFilterFieldRow } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterFieldRow/ContractsFilterFieldRow';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import { DatePeriodPicker } from '@/components/DatePeriodPicker/DatePeriodPicker';
import { DatePeriodPickerType } from '@/components/DatePeriodPicker/types';

export const ContractsFilterExecutionDate = memo(() => {
  const { filter } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) =>
      setFilter((prev) => ({ ...prev, executionDateStart: startDate, executionDateEnd: endDate })),
    [setFilter],
  );

  return (
    <ContractsFilterFieldRow
      label="Срок исполнения"
      field={
        <DatePeriodPicker
          onDatesChange={handleDatesChange}
          startDate={filter.executionDateStart}
          endDate={filter.executionDateEnd}
        />
      }
    />
  );
});

ContractsFilterExecutionDate.displayName = 'ContractsFilterExecutionDate';
