import { Input, InputProps, InputRef } from 'antd';
import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import styles from './DebounceInput.module.scss';

export type DebounceInputUpdatedOptions = {
  field: string;
  e?: ChangeEvent<HTMLInputElement>;
};

type DebounceInputProps = {
  field?: string;
  syncOnBlurOnly?: boolean;
  truncate?: boolean;
  debounceMs?: number;
  onDebouncedChange: (value: string, options: DebounceInputUpdatedOptions) => void;
} & InputProps;

export const DebounceInput = memo(
  forwardRef<InputRef, DebounceInputProps>(
    (
      {
        value,
        className,
        field = '',
        debounceMs = 500,
        syncOnBlurOnly = false,
        truncate = false,
        onDebouncedChange,
        onBlur,
        onFocus,
        ...inputProps
      }: DebounceInputProps,
      ref,
    ) => {
      const [inputValue, setInputValue] = useState(value);
      const isFocusedRef = useRef(false);

      const debouncedChangeValue = useMemo(
        () =>
          debounce((value: string, e: ChangeEvent<HTMLInputElement>) => {
            onDebouncedChange(value, { field, e });
          }, debounceMs),
        [debounceMs, onDebouncedChange, field],
      );

      const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setInputValue(value);
          debouncedChangeValue(value, e);
        },
        [debouncedChangeValue],
      );

      const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          isFocusedRef.current = true;
          onFocus?.(event);
        },
        [onFocus],
      );

      const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          isFocusedRef.current = false;
          setInputValue(value);
          onBlur?.(event);
        },
        [onBlur, value],
      );

      useEffect(() => {
        if (!syncOnBlurOnly || !isFocusedRef.current) {
          setInputValue(value);
        }
      }, [value, syncOnBlurOnly]);

      return (
        <Input
          {...inputProps}
          ref={ref}
          className={cx(className, {
            [styles.truncated]: truncate,
          })}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      );
    },
  ),
);

DebounceInput.displayName = 'DebounceInput';
