import { memo, PropsWithChildren, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import styles from './NmckGridHeader.module.scss';
import { Button, Flex } from 'antd';
import New from '/public/icons/new.svg';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { NmckGridCompatibilityWarning } from '@/components/NmckGrid/NmckGridCompatibilityWarning/NmckGridCompatibilityWarning';
import { NmckGridHeaderNameInput } from '@/components/NmckGrid/NmckGridHeader/components/NmckGridHeaderNameInput/NmckGridHeaderNameInput';
import { NmckGridHeaderResponsibleInput } from '@/components/NmckGrid/NmckGridHeader/components/NmckGridHeaderResponsibleInput/NmckGridHeaderResponsibleInput';
import { NmckGridHeaderUnderminedAmountSwitch } from '@/components/NmckGrid/NmckGridHeader/components/NmckGridHeaderUnderminedAmountSwitch/NmckGridHeaderUnderminedAmountSwitch';

type Props = {
  newCalculationLink: (typeof pageRoutes)['nmckAnalysis'] | (typeof pageRoutes)['nmckMedDevices'];
} & PropsWithChildren;

export const NmckGridHeader = memo(({ children, newCalculationLink }: Props) => {
  const router = useRouter();

  const handleNewCalculationClick = useCallback(() => {
    sendEventGTM(eventsGTM.nmckNewCalculationClick);
    router.push(newCalculationLink);
    router.refresh();
  }, [newCalculationLink, router]);

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        gap={16}
        className={styles.wrapper}
      >
        <Flex
          align="center"
          justify="space-between"
          gap={32}
        >
          <div className={styles.title}>{children}</div>

          <NmckGridHeaderNameInput />
          <NmckGridHeaderResponsibleInput />
        </Flex>

        <Flex
          align="center"
          justify="space-between"
          gap={24}
        >
          <Button
            icon={<New />}
            className="button-with-icon"
            onClick={handleNewCalculationClick}
          >
            Новый расчёт
          </Button>
          <NmckGridHeaderUnderminedAmountSwitch />
        </Flex>
      </Flex>
      <NmckGridCompatibilityWarning />
    </>
  );
});

NmckGridHeader.displayName = 'NmckGridHeader';
