import { memo, useCallback } from 'react';
import { DebounceInput } from '@/components';
import { Tooltip } from 'antd';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { useGetNmckById } from '@/api/nmckApi/nmckApi';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { eventsGTM, sendEventGTM } from '@/metrika';

export const NmckGridHeaderNameInput = memo(() => {
  const { nmckId } = useNmckGridContext();
  const { data } = useGetNmckById(nmckId);
  const { updateNmckWithFallback } = useNmckFallbackDataHandler();

  const handleNameChange = useCallback(
    async (value: string) => {
      if (data) {
        sendEventGTM(eventsGTM.nmckNameChange, { value });
        await updateNmckWithFallback({ ...data, name: value });
      }
    },
    [data, updateNmckWithFallback],
  );

  return (
    <Tooltip
      arrow={false}
      title={data?.name}
      placement="bottom"
    >
      <DebounceInput
        value={data?.name}
        onDebouncedChange={handleNameChange}
        className="input-line"
        placeholder="Название"
        variant="borderless"
        disabled={!data}
      />
    </Tooltip>
  );
});

NmckGridHeaderNameInput.displayName = 'NmckGridHeaderNameInput';
