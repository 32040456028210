import { ConfigProvider, Select, TableProps } from 'antd';
import { memo, useCallback, useContext, useMemo } from 'react';
import moment from 'moment';
import Link from 'next/link';
import { ContractDocument } from '@/api/docsApi/docsApiTypes';
import { SearchContext } from '@/widgets/Docs/context/context';
import { actions } from '@/widgets/Docs/context/actions';
import { DocsRow, EisPagination, ElipsisLabel } from '@/widgets/Docs/components';
import { formatPrice } from '@/utils/formatPrice';
import Vicon from '/public/icons/v.svg';
import colors from '@/styles/colors.module.scss';
import styles from './DocsTable.module.scss';
import { UiEmpty } from '@/components/Ui/UiEmpty/UiEmpty';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';
import { PAGE_SIZE_OPTIONS } from '@/components/Table/TablePagination/constants';

const columns: TableProps<ContractDocument>['columns'] = [
  {
    title: 'Жалоба ФАС',
    dataIndex: 'hasFasComplaint',
    key: 'hasFasComplaint',
    render: (_, { hasFasComplaint, fasComplaintHref }, index) => (
      <Link
        key={index}
        href={fasComplaintHref}
        target="_blank"
      >
        {hasFasComplaint ? 'Да' : 'Нет'}
      </Link>
    ),
  },
  {
    title: 'Закупка в ЕИС',
    dataIndex: 'itemName',
    key: 'itemName',
    width: 552,
    render: (_, { itemName, contractHref }, index) => (
      <ElipsisLabel
        href={contractHref}
        key={index}
        label={itemName}
      />
    ),
  },
  // {
  //   title: 'Участников',
  //   dataIndex: 'supplierCandidateCount',
  //   key: 'supplierCandidateCount',
  // },
  {
    title: 'Ед. изм.',
    dataIndex: 'measurementUnits',
    key: 'measurementUnits',
    render: (_, { measurementUnit }) => <p>{measurementUnit}</p>,
  },
  {
    title: 'ОКПД2/КТРУ',
    dataIndex: 'okpdKtruCode',
    key: 'okpdKtruCode',
  },
  // {
  //   title: 'Характеристики',
  //   dataIndex: 'characteristics',
  //   key: 'characteristics',
  //   width: 272,
  //   render: (_, { characteristics }) => <CharacteristicsRow items={characteristics} />,
  // },
  {
    title: 'Документация',
    dataIndex: 'docs',
    key: 'docs',
    className: styles.documentation,
    render: (_, { docs }) => <DocsRow items={docs} />,
  },
  {
    title: 'Дата',
    dataIndex: 'publicationDate',
    key: 'publicationDate',
    render: (_, { publicationDate }) => <p>{moment(publicationDate).format('DD.MM.YYYY')}</p>,
  },
  {
    title: 'Цена за ед., ₽',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    render: (_, { unitPrice }) => formatPrice(unitPrice),
  },
  {
    title: 'Регион',
    dataIndex: 'customerRegion',
    key: 'customerRegion',
    className: styles.region,
  },
  {
    title: 'Способ закупки',
    dataIndex: 'purchaseMethod',
    key: 'purchaseMethod',
    width: 170,
    render: (_, { purchaseMethod }, index) => (
      <ElipsisLabel
        key={index}
        label={purchaseMethod}
      />
    ),
  },
  {
    title: 'Нацрежим и преф.',
    dataIndex: 'nationalRegime',
    key: 'nationalRegime',
    width: 200,
    render: (_, { preferencesAndRestrictions }, index) => (
      <ElipsisLabel
        key={index}
        label={preferencesAndRestrictions}
      />
    ),
  },
  {
    title: 'Заказчики',
    dataIndex: 'customerName',
    key: 'customerName',
    width: 240,
    render: (_, { customerName }) => (
      <ElipsisLabel
        label={customerName}
        width={400}
        placement="left"
      />
    ),
  },
];

const dropdownStyle = {
  width: 'max-content',
};

const theme = {
  components: {
    Table: {
      headerBg: colors.white,
      headerColor: colors.light_gray,
      fontWeightStrong: 500,
      borderColor: colors.gray,
      footerBg: colors.white,
    },
  },
};

type DocsTableProps = {
  data: ContractDocument[];
  loading: boolean;
  items?: number;
  pageChange: (page: number) => void;
  loadMore: VoidFunction;
};

export const DocsTable = memo(({ data, loading, items, pageChange, loadMore }: DocsTableProps) => {
  const { state, dispatch } = useContext(SearchContext);

  const handleShowOnPageSelectChange = useCallback(
    (value: number) => {
      dispatch(actions.setItemsPerPage(value));
      dispatch(actions.setEis(undefined));
      dispatch(actions.setRank(undefined));
    },
    [dispatch],
  );

  // TODO: Использовать стандартный TableFooter !!
  const footer = useCallback(
    () => (
      <div className={styles.tableFooter}>
        <EisPagination
          total={items}
          loadMore={loadMore}
          current={state.currentPage}
          pageSize={state.itemsPerPage}
          showSizeChanger={false}
          onChange={pageChange}
          className={styles.pagination}
        />
        <div className={styles.pageSize}>
          Показывать на странице:
          <Select
            dropdownStyle={dropdownStyle}
            variant="borderless"
            suffixIcon={<Vicon className={styles.iconV} />}
            value={state.itemsPerPage}
            onChange={handleShowOnPageSelectChange}
            options={PAGE_SIZE_OPTIONS}
          />
        </div>
      </div>
    ),
    [
      handleShowOnPageSelectChange,
      items,
      loadMore,
      pageChange,
      state.currentPage,
      state.itemsPerPage,
    ],
  );

  const tableLocal = useMemo<TableProps['locale']>(
    () => ({
      emptyText: (
        <UiEmpty
          description={
            state.code
              ? undefined
              : 'Введите код ОКПД2/КТРУ для поиска документов в опубликованных на ЕИС закупках'
          }
        />
      ),
    }),
    [state.code],
  );

  return (
    <div className={styles.wrapper}>
      <ConfigProvider theme={theme}>
        <AutoScrollTable
          rowKey="eisId"
          loading={loading}
          columns={columns}
          dataSource={data}
          className={styles.table}
          pagination={false}
          rowClassName={styles.row}
          locale={tableLocal}
          footer={footer}
        />
      </ConfigProvider>
    </div>
  );
});

DocsTable.displayName = 'DocsTable';
