import { memo, useCallback } from 'react';
import { ContractsFilterFieldRow } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterFieldRow/ContractsFilterFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterCount.module.scss';
import { Flex } from 'antd';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';

export const ContractsFilterCount = memo(() => {
  const {
    filter: { countFrom, countUpTo },
  } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleCountFromChange = useCallback(
    (value: DebounceInputNumberValueType) => {
      setFilter((prev) => ({ ...prev, countFrom: value }));
    },
    [setFilter],
  );

  const handleCountToChange = useCallback(
    (value: DebounceInputNumberValueType) => {
      setFilter((prev) => ({ ...prev, countUpTo: value }));
    },
    [setFilter],
  );

  return (
    <ContractsFilterFieldRow
      label="Количество"
      field={
        <Flex gap={16}>
          <DebounceInputNumber
            min={0}
            debounceMs={800}
            value={countFrom}
            placeholder="Цена, от"
            className={styles.input}
            onDebouncedChange={handleCountFromChange}
          />

          <DebounceInputNumber
            min={0}
            debounceMs={800}
            value={countUpTo}
            placeholder="Цена, до"
            className={styles.input}
            onDebouncedChange={handleCountToChange}
          />
        </Flex>
      }
    />
  );
});

ContractsFilterCount.displayName = 'ContractsFilterCount';
