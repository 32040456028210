'use client';

import { OkpdDetailBreadcrumbs } from '@/widgets/OkpdDetail/components';
import styles from './KtruDetailPage.module.scss';
import { Spin } from 'antd';
import { memo, useEffect } from 'react';
import { useGetKtruDetail } from '@/api/okpdApi/okpdApi';
import { OkpdKtruDetailInfo } from '@/components/OkpdKtruDetail/OkpdKtruDetailInfo/OkpdKtruDetailInfo';
import { KtruDetailTabs } from '@/widgets/KtruDetail/KtruDetailTabs/KtruDetailTabs';
import { SetOkpdKtruModalPageParamsType } from '@/components/OkpdFlowModal/types';
import { eventsGTM, sendEventGTM } from '@/metrika';

type OkpdDetailPage = {
  code: string;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruDetailPage = memo(({ code, setModalPageParams }: OkpdDetailPage) => {
  const { data, isLoading } = useGetKtruDetail(code);

  useEffect(() => {
    sendEventGTM(eventsGTM.ktruDetailPageView, {
      value: code,
      info: setModalPageParams ? 'modal' : 'page',
    });
  }, [code, setModalPageParams]);

  if (!isLoading && !data?.code)
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperError}>КТРУ с таким кодом не существует</div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Spin />
        </div>
      )}
      {!isLoading && data && (
        <>
          <div className={styles.content}>
            <OkpdDetailBreadcrumbs
              parents={data.parents}
              setModalPageParams={setModalPageParams}
            />
            <OkpdKtruDetailInfo
              data={data}
              setModalPageParams={setModalPageParams}
              showStandardContracts={false}
            />
          </div>
          <KtruDetailTabs
            ktru={data}
            code={code}
            setModalPageParams={setModalPageParams}
          />
        </>
      )}
    </div>
  );
});
KtruDetailPage.displayName = 'KtruDetailPage';
