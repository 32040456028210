import { memo, useCallback, useContext, useState } from 'react';
import Cross from '/public/icons/cross.svg';
import Vicon from '/public/icons/v.svg';
import Plus from '/public/icons/plus.svg';
import Import from '/public/icons/import.svg';
import { Button, Flex } from 'antd';
import { DebounceInput, DirectContractEditModal } from '@/components';
import cx from 'classnames';
import { BDUIFilterParam, BDUIFilterResponse } from '@/components/BDUIFilter/types';
import { ReestrDirectContext } from '@/widgets/ReestrDirect/context/context';
import { actions } from '@/widgets/ReestrDirect/context/actions';
import styles from './ReestrDirectPageHeader.module.scss';
import { DirectContractCauses } from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { BDUIFilter } from '@/components/BDUIFilter/BDUIFilter';

const clearIcon = { clearIcon: <Cross className={styles.crossIcon} /> };

type ReestrDirectPageHeaderProps = {
  filtersResponse?: BDUIFilterResponse[];
  isContractsLoading: boolean;
  refreshContracts: () => void;
  causes: DirectContractCauses | undefined;
  isCausesLoading: boolean;
};

export const ReestrDirectPageHeader = memo(
  ({
    filtersResponse = [],
    isContractsLoading,
    refreshContracts,
    causes,
    isCausesLoading,
  }: ReestrDirectPageHeaderProps) => {
    const { state, dispatch } = useContext(ReestrDirectContext);
    const [isFiltersShown, setIsFiltersShown] = useState(true);
    const [isOpenEditModal, setOpenEditModal] = useState(false);

    const handleFiltersChange = useCallback(
      (newFilters: BDUIFilterParam[]) => {
        dispatch(actions.filtersUpdate(newFilters));
      },
      [dispatch],
    );

    const handleAdditionalParamsClick = useCallback(() => {
      setIsFiltersShown((prev) => !prev);
    }, []);

    const handleChangeSearch = useCallback(
      (value: string) => {
        dispatch(actions.setSearch(value));
      },
      [dispatch],
    );

    const addNewContract = useCallback(() => {
      setOpenEditModal(true);
    }, []);

    const closeEditModal = useCallback(() => {
      setOpenEditModal(false);
    }, []);

    return (
      <div className={styles.wrapper}>
        <DirectContractEditModal
          isOpenEditModal={isOpenEditModal}
          closeEditModal={closeEditModal}
          refreshContracts={refreshContracts}
          causes={causes}
          isCausesLoading={isCausesLoading}
        />
        <div className={styles.flex}>
          <Flex gap={64}>
            <Flex gap={32}>
              <div className={styles.title}>Прямые договоры</div>
              <DebounceInput
                value={state.search}
                onDebouncedChange={handleChangeSearch}
                placeholder="Поиск"
                variant="borderless"
                className={cx('input-line', styles.searchInput)}
                allowClear={clearIcon}
              />
            </Flex>
            <Flex gap={36}>
              <Button
                className={cx(styles.expandButton, {
                  [styles.active]: isFiltersShown,
                })}
                type="link"
                icon={
                  <Vicon
                    className={cx(styles.expandIcon, {
                      [styles.active]: isFiltersShown,
                    })}
                  />
                }
                iconPosition="end"
                onClick={handleAdditionalParamsClick}
              >
                Уточняющие параметры
              </Button>
              <Button
                type="primary"
                icon={<Plus />}
                className="button-with-icon"
                onClick={addNewContract}
              >
                Добавить договор
              </Button>
              <Button
                type="link"
                icon={<Import />}
                className="button-with-icon"
              >
                Загрузить из файла
              </Button>
            </Flex>
          </Flex>
          {isFiltersShown && (
            <>
              <BDUIFilter
                filtersResponse={filtersResponse}
                currentFilterParams={state.filters}
                isFiltersLoading={isContractsLoading}
                onFiltersChange={handleFiltersChange}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);
ReestrDirectPageHeader.displayName = 'ReestrDirectPageHeader';
