import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { NMCK_ANALYSIS_BY_ID_FALLBACK } from '@/widgets/NmckAnalysis/constants';

export const getNmckAnalysisFallbackDataWithSearchParams = ({
  codeSearchParam,
  nameSearchParam,
  isSearchParamsExist,
}: {
  codeSearchParam: string | null;
  nameSearchParam: string | null;
  isSearchParamsExist: boolean;
}): NmckByIdResponse =>
  isSearchParamsExist
    ? {
        ...NMCK_ANALYSIS_BY_ID_FALLBACK,
        rows: NMCK_ANALYSIS_BY_ID_FALLBACK.rows.with(0, {
          ...NMCK_ANALYSIS_BY_ID_FALLBACK.rows[0],
          code: codeSearchParam,
          name: nameSearchParam ?? '',
        }),
      }
    : NMCK_ANALYSIS_BY_ID_FALLBACK;
