import { Flex, PopoverProps } from 'antd';
import styles from './ContractsTablePriceColumn.module.scss';

export const coefficientPopOverContent: PopoverProps['content'] = (
  <Flex
    gap={20}
    vertical
    className={styles.coefficientPopOverContent}
  >
    <div>
      Коэф. по способу (максимальный размер): для аукционов - 13% для конкурсов - 10% для запросов
      предложений или запросов котировок - 17%
    </div>
    <div>
      Коэф. по периоду рассчитан для цен старше 6 месяцев в соответствии с рекомендациями п.3.18
      Приказа №567
    </div>
  </Flex>
);
