import { memo, useEffect } from 'react';
import styles from '@/widgets/NmckGuard/NmckGuardPage.module.scss';
import { useNmckGuardActionsContext } from '@/widgets/NmckGuard/context/useNmckGuardActionsContext';
import { NmckGuardByIdResponse } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { NmckGuardHeader } from '@/widgets/NmckGuard/components/NmckGuardHeader/NmckGuardHeader';
import { NmckGuardMainForm } from '@/widgets/NmckGuard/components/NmckGuardMainForm/NmckGuardMainForm';
import { NmckGuardPosts } from '@/widgets/NmckGuard/components/NmckGuardPosts/NmckGuardPosts';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { LoadingWrapper } from '@/components';

type Props = {
  nmckId: NmckGuardByIdResponse['id'];
};

export const NmckGuardPageContent = memo(({ nmckId }: Props) => {
  const { updateNmckId } = useNmckGuardActionsContext();
  const {
    nmckGuardByIdResponse: { isLoading },
  } = useNmckGuardFallbackDataHandler();

  useEffect(() => {
    updateNmckId(nmckId);
  }, [nmckId, updateNmckId]);

  return (
    <div className={styles.wrapper}>
      <LoadingWrapper
        isLoading={isLoading}
        alwaysShowChildren
      >
        <NmckGuardHeader />
        <NmckGuardMainForm />
        <NmckGuardPosts />
      </LoadingWrapper>
    </div>
  );
});

NmckGuardPageContent.displayName = 'NmckGuardPageContent';
