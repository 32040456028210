import { memo } from 'react';
import { PAGE_SIZE_OPTIONS } from '@/components/Table/TablePagination/constants';
import { Select, SelectProps } from 'antd';
import { LabelInValueType } from 'rc-select/lib/Select';
import styles from './PageSizeSelect.module.scss';
import cx from 'classnames';
import VIcon from 'public/icons/v.svg';

type Props = {
  onPageSizeChange: (pageSize: number) => void;
  pageSize: number;
  className?: string;
};

const pageSizeSelectLabelRender: SelectProps['labelRender'] = ({ value }: LabelInValueType) => (
  <div className={styles.selectLabel}>Показывать на странице: {value}</div>
);

export const PageSizeSelect = memo(({ onPageSizeChange, pageSize, className }: Props) => {
  return (
    <Select
      labelRender={pageSizeSelectLabelRender}
      variant="borderless"
      onChange={onPageSizeChange}
      value={pageSize}
      options={PAGE_SIZE_OPTIONS}
      suffixIcon={<VIcon className="v-icon" />}
      className={cx(className)}
    />
  );
});

PageSizeSelect.displayName = 'PageSizeSelect';
