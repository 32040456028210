import { identity, pickBy } from 'lodash';
import { TokenBasedPagination } from '@/types/types';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';

export const generateUrl = (url: string, options = {}) => {
  const params = new URLSearchParams(pickBy(options, identity));
  return `${url}?${params}`;
};

export const generateOkpdKtruRequestUrl = (
  url: string,
  {
    pagination,
    filter,
  }: {
    pagination?: TokenBasedPagination;
    filter: OkpdKtruListFilter;
  },
): string | null => {
  const params = new URLSearchParams();

  if (filter.code) params.append('code', filter.code);
  if (filter.searchString) params.append('searchstring', filter.searchString);
  if (pagination?.token) params.append('token', pagination.token);
  if (pagination?.count) params.append('count', String(pagination.count));
  filter.characteristics?.forEach(({ name, value }) => {
    params.append('characteristic', name);
    params.append('characteristicValue', value);
  });

  return filter.code || filter.searchString ? `${url}?${params.toString()}` : null;
};

export const getFormattedNkmi = (nkmis: KtruResult['nkmis'] | undefined): NmckRow['nkmiId'] => {
  const code = nkmis?.find(({ code }) => code)?.code;
  return code ? Number(code) : null;
};
