import { memo, useEffect, useMemo, useState } from 'react';
import { Flex, Select } from 'antd';
import VIcon from '/public/icons/v.svg';
import styles from './GuardPost.module.scss';
import { useGetNmckGuard } from '@/api/nmckGuardApi/nmckGuardApi';
import { ResultsDeadline } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsDeadline/ResultsDeadline';
import { ResultsDictionaryData } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsDictionaryData/ResultsDictionaryData';
import { ResultsDirectCosts } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsDirectCosts/ResultsDirectCosts';
import { GuardPostForm } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/GuardPostForm/GuardPostForm';
import { AdditionalCoeffs } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/AdditionalCoeffs/AdditionalCoeffs';
import { useNmckGuardContext } from '@/widgets/NmckGuard/context/useNmckGuardContext';

type GuardPostProps = {
  postIndex: number;
};

export const GuardPost = memo(({ postIndex }: GuardPostProps) => {
  const { nmckId } = useNmckGuardContext();
  const [selectedYear, setSelectedYear] = useState<number>();
  const { data: guardData } = useGetNmckGuard(nmckId);

  const postResults = useMemo(
    () => guardData?.resultsForEachPost[postIndex],
    [guardData?.resultsForEachPost, postIndex],
  );

  useEffect(() => {
    guardData?.generalDataByYears.length && setSelectedYear(guardData.generalDataByYears[0].year);
  }, [guardData]);

  const yearOptions = useMemo(() => {
    if (guardData?.generalDataByYears) {
      return guardData.generalDataByYears.map((years) => ({
        label: years.year,
        value: years.year,
      }));
    }
  }, [guardData]);

  return (
    <Flex
      gap={126}
      className={styles.wrapper}
    >
      <Flex
        vertical
        gap={40}
        className={styles.left}
      >
        <GuardPostForm index={postIndex} />
        <AdditionalCoeffs postIndex={postIndex} />
      </Flex>
      <Flex className={styles.right}>
        <Flex
          vertical
          gap={16}
        >
          <ResultsDeadline result={postResults} />
          {!!guardData?.generalDataByYears.length && (
            <Select
              value={selectedYear}
              placeholder="Год"
              suffixIcon={<VIcon />}
              className={styles.yearSelect}
              options={yearOptions}
              onSelect={setSelectedYear}
            />
          )}
          <ResultsDictionaryData selectedYear={selectedYear} />
          <ResultsDirectCosts
            result={postResults}
            selectedYear={selectedYear}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});

GuardPost.displayName = 'GuardPost';
