import { NmckContractsFilter, RegionType } from '@/components/NmckGrid/NmckGridContracts/types';

export const getInitialContractsFilterState = (): NmckContractsFilter => ({
  purchaseMethodIds: [],
  priceFrom: null,
  priceUpTo: null,
  unitMeasure: undefined,
  conclusionDateStart: undefined,
  conclusionDateEnd: undefined,
  executionDateStart: undefined,
  executionDateEnd: undefined,
  country: undefined,
  countFrom: null,
  countUpTo: null,
  regionIds: [],
  regionType: RegionType.SUPPLIER,
  characteristics: [],
});

export const regionTypeOptions = [
  { label: 'Поставщик', value: RegionType.SUPPLIER },
  { label: 'Заказчик', value: RegionType.BUYER },
];
