import { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  OkpdKtruSuppliersFilter,
  SuppliersFilter,
} from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';
import { OkpdKtruSuppliersTable } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersTable/OkpdKtruSuppliersTable';
import { KtruDetail, OkpdDetail } from '@/api/okpdApi/okpdApiTypes';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { DEFAULT_PAGE_SIZE } from '@/components/Table/TablePagination/constants';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  okpdCode?: string;
  ktruCode?: string;
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

export const OkpdKtruSuppliers = memo(({ okpdCode, ktruCode, okpdKtruDetailData }: Props) => {
  const [filter, setFilter] = useState<SuppliersFilter>({
    region: [],
    contractDateFrom: moment().subtract(1, 'year'),
    contractDateTo: moment(),
    smp: false,
    prom: false,
    hasPenalty: true,
    okpdCode,
    ktruCode,
  });
  const [pagination, setPagination] = useState<TokenBasedPaginationStateWithPageSize>({
    token: null,
    pageSize: DEFAULT_PAGE_SIZE,
    hasMoreResults: false,
  });

  const handleFilterChange = useCallback((filterFields: Partial<SuppliersFilter>) => {
    setPagination((prev) => ({ ...prev, token: null }));
    setFilter((prev) => ({
      ...prev,
      ...filterFields,
    }));
  }, []);

  useEffect(() => {
    sendEventGTM(eventsGTM.suppliersTabView, { value: okpdCode ?? ktruCode });
  }, [ktruCode, okpdCode]);

  return (
    <div>
      <OkpdKtruSuppliersFilter
        filterParams={filter}
        onFilterParamsChange={handleFilterChange}
      />
      <OkpdKtruSuppliersTable
        filter={filter}
        pagination={pagination}
        setPagination={setPagination}
        okpdKtruDetailData={okpdKtruDetailData}
      />
    </div>
  );
});

OkpdKtruSuppliers.displayName = 'OkpdKtruSuppliers';
