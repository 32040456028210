import { Table, TableProps } from 'antd';
import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TableRef } from 'antd/lib/table';
import styles from './AutoScrollTable.module.scss';
import cx from 'classnames';
import {
  SCROLL_SPEED,
  SCROLL_ZONE_WIDTH,
  ZONE_STYLES,
} from '@/components/Ui/AutoScrollTable/AutoScrollTable.constants';
import {
  getAntdTableContentElement,
  getAntdTableFooter,
  getAntdTableHeader,
  isScrollZoneAvailable,
} from '@/components/Ui/AutoScrollTable/AutoScrollTable.utils';
import {
  AutoScrollTableProps,
  ScrollDirectionType,
} from '@/components/Ui/AutoScrollTable/AutoScrollTable.types';

export const AutoScrollTable = memo(
  ({ sliceFooterHeight = true, sliceHeaderHeight = true, ...props }: AutoScrollTableProps) => {
    const tableRef = useRef<TableRef>(null);
    const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>(null);
    const [isAtLeft, setIsAtLeft] = useState(true);
    const [isAtRight, setIsAtRight] = useState(false);
    const [zoneStyles, setZoneStyles] = useState<CSSProperties>(ZONE_STYLES);
    const animationFrame = useRef<number | null>(null);
    const tableScroll = useMemo(
      (): TableProps['scroll'] => ({
        ...props.scroll,
        x: 'max-content',
      }),
      [props.scroll],
    );

    useEffect(() => {
      if (!scrollDirection || !tableRef.current) {
        return cancelAnimationFrame(animationFrame.current!);
      }

      const scrollTable = () => {
        const table = getAntdTableContentElement(tableRef.current);
        if (!table) return;

        if (scrollDirection === 'left') {
          table.scrollLeft = Math.max(0, table.scrollLeft - SCROLL_SPEED);
        } else {
          table.scrollLeft = Math.min(
            table.scrollWidth - table.clientWidth,
            table.scrollLeft + SCROLL_SPEED,
          );
        }

        animationFrame.current = requestAnimationFrame(scrollTable);
      };

      scrollTable();

      return () => {
        if (animationFrame.current) cancelAnimationFrame(animationFrame.current);
      };
    }, [scrollDirection]);

    const handleMouseMove = useCallback(
      (event: React.MouseEvent) => {
        if (!tableRef.current) return;

        const { clientX } = event;
        const { left, right } = tableRef.current.nativeElement.getBoundingClientRect();

        if (!isAtLeft && clientX - left < SCROLL_ZONE_WIDTH) {
          setScrollDirection('left');
        } else if (!isAtRight && right - clientX < SCROLL_ZONE_WIDTH) {
          setScrollDirection('right');
        } else {
          setScrollDirection(null);
        }
      },
      [isAtLeft, isAtRight],
    );

    const handleScroll = useCallback(() => {
      const table = getAntdTableContentElement(tableRef.current);

      setIsAtLeft(isScrollZoneAvailable(table, 'left'));
      setIsAtRight(isScrollZoneAvailable(table, 'right'));
    }, []);

    const handleMouseLeave = useCallback(() => setScrollDirection(null), []);

    const handleWindowResize = useCallback(() => {
      handleScroll();
      const header = getAntdTableHeader(tableRef.current);
      const footer = getAntdTableFooter(tableRef.current);

      if (header && sliceHeaderHeight) {
        setZoneStyles((prev) => ({ ...prev, top: header.clientHeight }));
      }
      if (footer && sliceFooterHeight) {
        setZoneStyles((prev) => ({ ...prev, bottom: footer.clientHeight }));
      }
    }, [handleScroll, sliceFooterHeight, sliceHeaderHeight]);

    useEffect(() => {
      const table = getAntdTableContentElement(tableRef.current);
      table?.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleWindowResize);
      handleWindowResize();

      return () => {
        table?.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleWindowResize);
      };
    }, [handleScroll, handleWindowResize]);

    return (
      <div
        className={styles.wrapper}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={cx(styles.zone, styles.zoneLeft, {
            [styles.zoneActive]: scrollDirection === 'left',
            [styles.zoneInactive]: isAtLeft,
          })}
          style={zoneStyles}
        />
        <div
          className={cx(styles.zone, styles.zoneRight, {
            [styles.zoneActive]: scrollDirection === 'right',
            [styles.zoneInactive]: isAtRight,
          })}
          style={zoneStyles}
        />
        <Table
          ref={tableRef}
          {...props}
          scroll={tableScroll}
        />
      </div>
    );
  },
);

AutoScrollTable.displayName = 'AutoScrollTable';
