import { memo, ReactNode } from 'react';
import { Flex } from 'antd';
import styles from './ContractsFilterFieldRow.module.scss';

type Props = {
  label: ReactNode;
  hint?: ReactNode;
  field: ReactNode;
};

export const ContractsFilterFieldRow = memo(({ label, field, hint }: Props) => {
  return (
    <Flex align="center">
      <Flex
        component="label"
        align="center"
        className={styles.label}
      >
        {label}
        <span className={styles.colon}>:</span>
        {hint && <div className={styles.hint}>{hint}</div>}
      </Flex>
      {field}
    </Flex>
  );
});

ContractsFilterFieldRow.displayName = 'ContractsFilterFieldRow';
