import { memo, useCallback, useEffect, useState } from 'react';

import { putSummarySgos, useGetSummarySgos } from '@/api/summaryApi/summaryApi';
import { formatPrice, parsePrice } from '@/utils/formatPrice';
import { SgosResponse } from '@/api/summaryApi/summaryApiTypes';
import { SgosSubrow, TooltipLabel, SumDisplay } from '@/widgets/Summary/components';
import { DebounceInput } from '@/components';

import New from '/public/icons/new.svg';

import styles from './SgosDisplay.module.scss';

type SgosDisplayProps = {
  dateFrom: string;
  dateTo: string;
};

const sgosTooltip =
  'Сумма по столбцу "Запланировано в году" (на выбранный финансовый год) + суммы прямых договоров';
const requestsTooltip =
  'Сумма по столбцу "Запланировано в году" (на выбранный финансовый год) для контрактов с расторжением: сумма из "Оплачено" (без учета закупок по пп.а,г п.2 ч.10 ст.24)';
const st24Tooltip =
  'Сумма по столбцу "Запланировано в году" (на выбранный финансовый год) для контрактов с расторжением: сумма из "Оплачено" (для запросов котировок по пп.а,г п.2 ч.10 ст.24)';
const contractsTooltip = 'Сумма прямых договоров';
const smpSgosTooltip =
  'СГОЗ за вычетом оплат по контрактам с единственным поставщиком (включая прямые договоры)';
const smpTooltip =
  'Сумма по столбцу "Запланировано в году" (на выбранный финансовый год) из контрактов с СМП, для контрактов с расторжением: сумма из "Оплачено"';

export const SgosDisplay = memo(({ dateFrom, dateTo }: SgosDisplayProps) => {
  const [sgosInput, setSgosInput] = useState<string>();
  const [directContractsInput, setDirectContractsInput] = useState<string>();
  const [sgosSmpInput, setSgosSmpInput] = useState<string>();
  const [currentSgos, setCurrentSgos] = useState<SgosResponse>();

  const { data: sgos } = useGetSummarySgos(dateFrom, dateTo);

  useEffect(() => {
    setCurrentSgos(sgos);
  }, [sgos]);

  useEffect(() => {
    const setSgos = async () => {
      const updatedSgos = await putSummarySgos(dateFrom, dateTo, {
        sgosEdited: sgosInput ? parsePrice(sgosInput) : null,
        directContractsEdited: directContractsInput ? parsePrice(directContractsInput) : null,
        sgosSmpEdited: sgosSmpInput ? parsePrice(sgosSmpInput) : null,
      });

      setCurrentSgos(updatedSgos);
    };
    if (sgosInput || directContractsInput || sgosSmpInput) {
      setSgos();
    } else {
      setCurrentSgos(sgos);
    }
  }, [sgosInput, directContractsInput, sgosSmpInput, sgos, dateFrom, dateTo]);

  const handleSgosChange = useCallback(
    (value: string) => {
      setSgosInput(formatPrice(value));
    },
    [setSgosInput],
  );

  const handleSgosReset = useCallback(() => {
    if (!sgos) return;
    setSgosInput(formatPrice(sgos.sgos));
  }, [sgos]);

  const handleDirectContractsChange = useCallback(
    (value: string) => {
      setDirectContractsInput(formatPrice(value));
    },
    [setDirectContractsInput],
  );

  const handleDirectContractsReset = useCallback(() => {
    if (!sgos) return;
    setDirectContractsInput(formatPrice(sgos.directContracts));
  }, [sgos]);

  const handleSgosSmpChange = useCallback(
    (value: string) => {
      setSgosSmpInput(formatPrice(value));
    },
    [setSgosSmpInput],
  );

  const handleSgosSmpReset = useCallback(() => {
    if (!sgos) return;
    setSgosSmpInput(formatPrice(sgos.sgosSmp));
  }, [sgos]);

  return (
    <>
      {currentSgos && (
        <div className={styles.wrapper}>
          <div className={styles.sgoz}>
            <div className={styles.row}>
              <TooltipLabel
                label="СГОЗ"
                tooltip={sgosTooltip}
              />
              <div className={styles.inputContainer}>
                <DebounceInput
                  defaultValue={formatPrice(currentSgos.sgos)}
                  variant="borderless"
                  value={sgosInput}
                  onDebouncedChange={handleSgosChange}
                  suffix={'₽'}
                  className={styles.input}
                />
                <New
                  onClick={handleSgosReset}
                  className={styles.reset}
                />
              </div>
            </div>
            <SgosSubrow
              label="Запросы котировок"
              amount={currentSgos.zaprosKotirovok}
              tooltip={requestsTooltip}
              percentage={currentSgos.zaprosKotirovokPercent}
            />
            <SgosSubrow
              label="Запросы котировок п.2 ч.10 ст.24"
              amount={currentSgos.zaprosKotirovokSt24}
              tooltip={st24Tooltip}
              percentage={currentSgos.zaprosKotirovokSt24Percent}
            />
            <div className={styles.requestSubrow}>
              <TooltipLabel
                label="Прямые договоры"
                tooltip={contractsTooltip}
              />
              <div className={styles.rowRight}>
                <div className={styles.inputContainer}>
                  <DebounceInput
                    defaultValue={formatPrice(currentSgos.directContracts)}
                    variant="borderless"
                    value={directContractsInput}
                    onDebouncedChange={handleDirectContractsChange}
                    suffix={'₽'}
                    className={styles.input}
                  />
                  <New
                    onClick={handleDirectContractsReset}
                    className={styles.reset}
                  />
                </div>
                <span className={styles.percentage}>{currentSgos.directContractsPercent} %</span>
              </div>
            </div>
          </div>
          <div className={styles.sgoz}>
            <div className={styles.row}>
              <TooltipLabel
                label="СГОЗ для СМП"
                tooltip={smpSgosTooltip}
              />
              <div className={styles.inputContainer}>
                <DebounceInput
                  defaultValue={formatPrice(currentSgos.sgosSmp)}
                  variant="borderless"
                  value={sgosSmpInput}
                  onDebouncedChange={handleSgosSmpChange}
                  suffix={'₽'}
                  className={styles.input}
                />
                <New
                  onClick={handleSgosSmpReset}
                  className={styles.reset}
                />
              </div>
            </div>
            <SgosSubrow
              label="СМП"
              amount={currentSgos.smp}
              tooltip={smpTooltip}
              percentage={currentSgos.smpPercent}
              className={styles.bottomRow}
            />
          </div>
          <SumDisplay
            text="Заключено контрактов на сумму"
            sum={currentSgos.contractsSum}
            className={styles.sum}
          />
        </div>
      )}
    </>
  );
});

SgosDisplay.displayName = 'SgosDisplay';
