import { usePathname, useSearchParams } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import {
  NMCK_CODE_SEARCH_PARAM_KEY,
  NMCK_NAME_SEARCH_PARAM_KEY,
  NMCK_NKMI_SEARCH_PARAM_KEY,
} from '@/components/OkpdKtruDetail/OkpdKtruDetailInfo/constants';

export const useNmckPathname = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isNmckMedDevices = pathname.startsWith(pageRoutes.nmckMedDevices);

  const codeSearchParam = searchParams.get(NMCK_CODE_SEARCH_PARAM_KEY);
  const nameSearchParam = searchParams.get(NMCK_NAME_SEARCH_PARAM_KEY);
  const nkmiSearchParam = searchParams.get(NMCK_NKMI_SEARCH_PARAM_KEY);
  const isSearchParamsExist = !!codeSearchParam || !!nameSearchParam || !!nkmiSearchParam;

  return {
    isNmckMedDevices,
    codeSearchParam,
    nameSearchParam,
    nkmiSearchParam,
    isSearchParamsExist,
  };
};
