import { Flex, SelectProps } from 'antd';
import styles from './ContractsTableSortSelect.module.scss';

export const labelRender: SelectProps['labelRender'] = ({ label }) => (
  <Flex
    align="center"
    gap={4}
  >
    <label className={styles.label}>Сортировать по: </label>
    <span className={styles.value}>{label}</span>
  </Flex>
);
