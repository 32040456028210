import { memo, useCallback, useState } from 'react';
import { Button, Flex } from 'antd';
import styles from '@/widgets/NmckGuard/components/NmckGuardMainForm/NmckGuardMainForm.module.scss';
import { formatPrice } from '@/utils/formatPrice';
import Export from 'public/icons/export.svg';
import { getNmckGuardXlsx } from '@/api/nmckGuardApi/nmckGuardApi';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';

export const ResultFooter = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    nmckId,
  } = useNmckGuardFallbackDataHandler();
  const [isReportButtonLoading, setIsReportButtonLoading] = useState(false);

  const price = data?.totalPrice ? `${formatPrice(data.totalPrice)} ₽` : '—';

  const handleGererateReportButtonClick = useCallback(async () => {
    if (data) {
      setIsReportButtonLoading(true);
      try {
        await getNmckGuardXlsx(nmckId, data.name);
      } catch (err: unknown) {
        console.error(err);
      } finally {
        setIsReportButtonLoading(false);
      }
    }
  }, [data, nmckId]);

  return (
    <Flex
      gap={44}
      align="center"
    >
      <div className={styles.title}>Итого: {price}</div>
      <Button
        type="primary"
        icon={<Export />}
        className="button-with-icon"
        disabled={!nmckId}
        loading={isReportButtonLoading}
        onClick={handleGererateReportButtonClick}
      >
        Сформировать отчёт
      </Button>
    </Flex>
  );
});

ResultFooter.displayName = 'ResultFooter';
