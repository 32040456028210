import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Flex, Tabs } from 'antd';
import Cross from '/public/icons/cross.svg';
import Plus from '/public/icons/plus.svg';
import styles from './NmckGuardPosts.module.scss';
import { GuardPost } from './components';
import { Badge } from '@/components';
import { Tab } from 'rc-tabs/lib/interface';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { NMCK_GUARD_BY_ID_FALLBACK } from '@/widgets/NmckGuard/constants';

export const NmckGuardPosts = memo(() => {
  const {
    nmckGuardByIdResponse: { data, isLoading },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();
  const [activeKey, setActiveKey] = useState('0');
  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  const handleAddPostButtonClick = useCallback(async () => {
    if (data) {
      setIsAddButtonLoading(true);
      try {
        const updatedNmck = await updateNmckGuardWithFallback({
          ...data,
          nmckGuardRawRequest: {
            ...data.nmckGuardRawRequest,
            posts: [
              ...data.nmckGuardRawRequest.posts,
              NMCK_GUARD_BY_ID_FALLBACK.nmckGuardRawRequest.posts[0],
            ],
          },
        });
        setActiveKey(String(updatedNmck.nmckGuardRawRequest.posts.length - 1));
      } catch (err: unknown) {
        console.error(err);
      } finally {
        setIsAddButtonLoading(false);
      }
    }
  }, [data, updateNmckGuardWithFallback]);

  const removeTab = useCallback(
    async (targetKey: number) => {
      if (data) {
        await updateNmckGuardWithFallback({
          ...data,
          nmckGuardRawRequest: {
            ...data.nmckGuardRawRequest,
            posts: data.nmckGuardRawRequest.posts.filter((_, index) => targetKey !== index),
          },
        });
        setActiveKey('0');
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  const tabItems = useMemo(() => {
    const posts = isLoading
      ? NMCK_GUARD_BY_ID_FALLBACK.nmckGuardRawRequest.posts // Мок, чтобы при загрузке данных, показывать форму
      : data?.nmckGuardRawRequest.posts;

    return (
      posts?.map(
        ({ name, postCount }, index): Tab => ({
          key: `${index}`,
          label: (
            <Flex
              align="center"
              gap={8}
              className={styles.tabItem}
            >
              {name || `Пост ${index + 1}`}
              <Badge count={postCount || 1} />
              <Button
                type="link"
                icon={<Cross />}
                className={styles.crossButton}
                disabled={posts.length === 1}
                onClick={(event) => {
                  event.stopPropagation();
                  removeTab(index);
                }}
              />
            </Flex>
          ),
          children: <GuardPost postIndex={index} />,
        }),
      ) ?? []
    );
  }, [data?.nmckGuardRawRequest.posts, isLoading, removeTab]);

  const items = useMemo(
    () => [
      ...tabItems,
      {
        key: 'add',
        label: (
          <Button
            type="link"
            icon={<Plus />}
            className="button-with-icon"
            loading={isAddButtonLoading}
            onClick={handleAddPostButtonClick}
          >
            Добавить пост
          </Button>
        ),
        disabled: true,
        children: null,
      },
    ],
    [tabItems, isAddButtonLoading, handleAddPostButtonClick],
  );

  return (
    <div className={styles.wrapper}>
      <Tabs
        type="line"
        className={styles.tabs}
        onChange={setActiveKey}
        activeKey={activeKey}
        items={items}
      />
    </div>
  );
});

NmckGuardPosts.displayName = 'NmckGuardPosts';
