import { Breadcrumb } from 'antd';
import styles from './OkpdDetailBreadcrumbs.module.scss';
import { useCallback, useMemo } from 'react';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { pageRoutes } from '@/app/pageRoutes';

type BreadcrumbsProps = {
  parents: string[];
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdDetailBreadcrumbs = ({ parents, setModalPageParams }: BreadcrumbsProps) => {
  const handleBreadcrumbItemClick = useCallback(
    (code: string) => () =>
      setModalPageParams &&
      setModalPageParams((prev) => ({
        ...prev,
        page: code ? OkpdKtruPageTypes.OKPD : OkpdKtruPageTypes.LISTS,
        code,
      })),
    [setModalPageParams],
  );

  const renderBreadcrumbItem = useCallback(
    (title: string, code: string, isLast: boolean) =>
      isLast ? (
        <div className={styles.lastBreadcrumbItem}>{title}</div>
      ) : (
        <OkpdLink
          isLink={!setModalPageParams}
          onClick={handleBreadcrumbItemClick(code)}
          href={`${pageRoutes.okpd2}/${code}`}
          className={styles.link}
        >
          {title}
        </OkpdLink>
      ),
    [handleBreadcrumbItemClick, setModalPageParams],
  );

  const items = useMemo(
    () => [
      { title: renderBreadcrumbItem('ОКПД 2', '', false) },
      ...parents.map((item, index: number) => ({
        title: renderBreadcrumbItem(item, item, parents.length - 1 === index),
      })),
    ],
    [parents, renderBreadcrumbItem],
  );

  return <Breadcrumb items={items} />;
};
