import { Dispatch, memo, ReactNode, SetStateAction } from 'react';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';
import { Flex } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { OrderedColumnTypeMap } from '@/components/Table/TableColumnsSettings/types';
import {
  PageNumberChangeCallbackType,
  PageSizeChangeCallbackType,
  PaginationType,
  PaginationV1,
} from '@/components/Table/TablePagination/types';
import { TokenBasedPaginationState } from '@/api/commonApi/commonApiTypes';
import { TablePagination } from '@/components/Table/TablePagination/TablePagination';
import { TableTokenBasedPagination } from '@/components/Table/TablePagination/TableTokenBasedPagination/TableTokenBasedPagination';
import { LoadMoreCallbackType } from '@/components/Table/TablePagination/TableTokenBasedPagination/types';
import cx from 'classnames';

type Pagination = PaginationType | TokenBasedPaginationState;

export type TableFooterProps<
  TRecord,
  TPagination extends Pagination = PaginationV1,
  TTokenBasedPagination extends TokenBasedPaginationState = TokenBasedPaginationState,
> = {
  columnsSettings?: {
    columns: ColumnType<TRecord>[];
    orderedColumnsMap: OrderedColumnTypeMap<TRecord>;
    setOrderedColumnsMap: Dispatch<SetStateAction<OrderedColumnTypeMap<TRecord>>>;
  };

  pageBasedPagination?: {
    pagination: TPagination;
    onPageNumberChange: PageNumberChangeCallbackType;
    onPageSizeChange: PageSizeChangeCallbackType;
    count: number;
  };

  tokenBasedPagination?: {
    /*
     * @deprecated
     * Используйте useInfiniteList и передавайте флаг hasMoreResults.
     * В этом случае токен обрабатывается автоматически, и работать с ним вручную не требуется.
     */
    pagination?: TTokenBasedPagination;
    /*
     * Когда все экраны, использующие токен-бейзд пагинацию переедут на useInfiniteList,
     * Сделать hasMoreResults обязательным полем и удалить поле pagination.
     */
    hasMoreResults?: boolean;
    pageSize: number;
    onLoadMore: LoadMoreCallbackType;
    onPageSizeChange: PageSizeChangeCallbackType;
    isDataLoading?: boolean;
  };

  wrapperClassName?: string;
};

const TableFooterComponent = <
  TRecord,
  TPageBasedPagination extends PaginationType = PaginationType,
  TTokenBasedPagination extends TokenBasedPaginationState = TokenBasedPaginationState,
>({
  columnsSettings,
  pageBasedPagination,
  tokenBasedPagination,
  wrapperClassName,
}: TableFooterProps<TRecord, TPageBasedPagination, TTokenBasedPagination>) => {
  return (
    <>
      <Flex
        align="center"
        gap={42}
        className={cx(wrapperClassName)}
      >
        {pageBasedPagination && (
          <TablePagination<TPageBasedPagination>
            filter={pageBasedPagination.pagination}
            onPageNumberChange={pageBasedPagination.onPageNumberChange}
            onPageSizeChange={pageBasedPagination.onPageSizeChange}
            count={pageBasedPagination.count}
          />
        )}
        {tokenBasedPagination && (
          <TableTokenBasedPagination<TTokenBasedPagination>
            pagination={tokenBasedPagination.pagination}
            hasMoreResults={tokenBasedPagination.hasMoreResults}
            pageSize={tokenBasedPagination.pageSize}
            onLoadMoreClick={tokenBasedPagination.onLoadMore}
            onPageSizeChange={tokenBasedPagination.onPageSizeChange}
            isDataLoading={tokenBasedPagination.isDataLoading ?? false}
          />
        )}
        {columnsSettings && (
          <TableColumnsSettings<TRecord>
            orderedColumnMap={columnsSettings.orderedColumnsMap}
            setOrderedColumnMap={columnsSettings.setOrderedColumnsMap}
            columnsData={columnsSettings.columns}
          />
        )}
      </Flex>
    </>
  );
};

export const TableFooter = memo(TableFooterComponent) as <
  TRecord,
  TPageBasedPagination extends PaginationType = PaginationV1,
  TTokenBasedPagination extends TokenBasedPaginationState = TokenBasedPaginationState,
>(
  props: TableFooterProps<TRecord, TPageBasedPagination, TTokenBasedPagination>,
) => ReactNode;
