import { useContext } from 'react';
import { NmckGridActionsContext } from '@/components/NmckGrid/context/NmckGridContext';

export const useNmckGridActionsContext = () => {
  const context = useContext(NmckGridActionsContext);

  if (!context) {
    throw new Error('NmckGridActionsContext must be used within a NmckGridProvider');
  }

  return context;
};
