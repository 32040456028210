import { memo } from 'react';
import { Divider, Flex } from 'antd';
import styles from './NmckGuardMainForm.module.scss';
import { WorkingPeriod } from '@/widgets/NmckGuard/components/NmckGuardMainForm/components/WorkingPeriod/WorkingPeriod';
import { DirectCosts } from '@/widgets/NmckGuard/components/NmckGuardMainForm/components/DirectCosts/DirectCosts';
import { AdditionalServices } from '@/widgets/NmckGuard/components/NmckGuardMainForm/components/AdditionalServices/AdditionalServices';
import { ResultFooter } from '@/widgets/NmckGuard/components/NmckGuardMainForm/components/ResultFooter/ResultFooter';

export const NmckGuardMainForm = memo(() => {
  return (
    <Flex
      vertical
      gap={24}
      className={styles.additionalServices}
    >
      <WorkingPeriod />
      <DirectCosts />
      <AdditionalServices />

      <Divider className={styles.antDivider} />

      <ResultFooter />
    </Flex>
  );
});
NmckGuardMainForm.displayName = 'NmckGuardMainForm';
