import { getGPBToken } from '@/api/userApi/userApiUtils';
import { openUnauthorizedModal } from '@/utils/openUnauthorizedModal';
import { UnauthorizedResponse } from '@/api/commonApi/commonApiTypes';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export const fetcher = async (url: string, options: RequestInit = {}) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${getGPBToken()}`,
      ...(options.headers ?? {}),
    },
  });

  if (response.status === 401) {
    const data: UnauthorizedResponse = await response.clone().json();
    openUnauthorizedModal(data);

    return Promise.reject('Пользователь не авторизован');
  }

  if (response.status >= 400) {
    return Promise.reject();
  }

  return response;
};

export const downloadXlsx = async (response: Response, fileName: string) => {
  const blob = await response.blob();
  const urlObject = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = urlObject;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(urlObject);
};

export const getData = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const response = await fetcher(url, options);
  if (!response.ok) {
    const error = new Error('An error occurred while fetching the data.');

    error.message = await response.json();
    error.cause = response.status;
    throw error;
  }
  return response.json();
};

export const putData = async <T, U = T>(url: string, body: U): Promise<T> => {
  const response = await fetcher(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

export const postData = async <T>(url: string, body?: object): Promise<T> => {
  const response = await fetcher(url, {
    method: 'POST',
    ...(body
      ? {
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      : {}),
  });

  try {
    return await response.json();
  } catch (error) {
    return null as T;
  }
};

export const deleteData = async <T>(url: string, body?: T): Promise<T | null> => {
  const response = await fetcher(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  try {
    return await response.json();
  } catch (error) {
    return null;
  }
};
