import { NmckGuardPost } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { Option } from '@/types/types';

export const additionalCoeffCheckboxes: Option<
  keyof Pick<NmckGuardPost, 'ud1' | 'ud2' | 'ud3' | 'ud4' | 'ud5'>
>[] = [
  {
    label: 'Uд1 – наличие спецсредств',
    value: 'ud1',
  },
  {
    label: 'Uд2 – наличие служебного оружия',
    value: 'ud2',
  },
  {
    label: 'Uд3 – обеспечение порядка в местах массовых мероприятий',
    value: 'ud3',
  },
  {
    label: 'Uд4 – наличие требований к антитеррористической защищенности',
    value: 'ud4',
  },
  {
    label: 'Uд5 – наличие допуска к государственной тайне',
    value: 'ud5',
  },
];

export const graphicOptions: Option[] = [
  { value: 'CALENDAR_DAYS', label: 'Все дни с ПН по ВС' },
  { value: 'WORKDAYS', label: 'Рабочие дни с ПН по ПТ' },
  { value: 'EXTENDED_WORKDAYS', label: 'Рабочие дни с ПН по СБ' },
  { value: 'HOLIDAYS', label: 'Рабочие дни в выходные и праздничные дни' },
];
