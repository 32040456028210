import { SelectProps } from 'antd';
import { NmckContractsCountry } from '@/components/NmckGrid/NmckGridContracts/types';

export const nmckContractsCountriesOptions: SelectProps['options'] = [
  {
    label: 'Все страны',
    value: NmckContractsCountry.ALL,
  },
  {
    label: 'Россия',
    value: NmckContractsCountry.RUSSIA,
  },
  {
    label: 'ЕАЭС',
    value: NmckContractsCountry.EAES,
  },
];
