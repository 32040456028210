import { memo, PropsWithChildren } from 'react';
import { Flex, Spin } from 'antd';
import cx from 'classnames';
import styles from './LoadingWrapper.module.scss';

type Props = {
  isLoading: boolean;
  wrapperClassName?: string;
  alwaysShowChildren?: boolean;
} & PropsWithChildren;

export const LoadingWrapper = memo(
  ({ isLoading, wrapperClassName, alwaysShowChildren = false, children }: Props) => {
    if (alwaysShowChildren) {
      return (
        <>
          {children}
          {isLoading && (
            <Flex
              justify="center"
              className={styles.withChildrenOverlay}
            >
              <Spin className={styles.withChildrenSpinner} />
            </Flex>
          )}
        </>
      );
    }

    return isLoading ? (
      <Flex
        justify="center"
        className={cx(wrapperClassName)}
      >
        <Spin />
      </Flex>
    ) : (
      children
    );
  },
);

LoadingWrapper.displayName = 'LoadingWrapper';
