import { Flex, InputRef } from 'antd';
import { memo, useCallback, useEffect, useRef } from 'react';
import styles from './Header.module.scss';
import { DebounceInput } from '@/components';
import cx from 'classnames';
import { eventsGTM, sendEventGTM } from '@/metrika';

type HeaderProps = {
  search: string;
  handleSearchChange: (value: string) => void;
};

export const Header = memo(({ search, handleSearchChange }: HeaderProps) => {
  const inputRef = useRef<InputRef>(null);

  const onSearchChange = useCallback(
    (value: string) => {
      const updatedValue = value.trim();
      handleSearchChange(updatedValue);
      sendEventGTM(eventsGTM.okpdSearch, { value: updatedValue });
    },
    [handleSearchChange],
  );

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'end',
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Flex gap={32}>
        <div className={styles.title}>ОКПД 2 / КТРУ</div>
        <DebounceInput
          value={search}
          onDebouncedChange={onSearchChange}
          className={cx('input-line', styles.input)}
          variant="borderless"
          placeholder="Название / код"
          autoFocus
          ref={inputRef}
        />
      </Flex>
    </div>
  );
});

Header.displayName = 'Header';
