import { memo } from 'react';
import { Flex } from 'antd';
import { NmckRow, Proposition, UpdatePropositionDTO } from '@/api/nmckApi/nmckApiTypes';
import styles from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/NmckGridPropositionInputSuffix.module.scss';
import { NmckGridPropositionClearButton } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/components/NmckGridPropositionClearButton/NmckGridPropositionClearButton';
import { NmckGridPropositionDescription } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/components/NmckGridPropositionDescription/NmckGridPropositionDescription';
import { NmckGridPropositionWarning } from '@/components/NmckGrid/NmckGridProposition/NmckGridPropositionInput/components/NmckGridPropositionInputSuffix/components/NmckGridPropositionWarning/NmckGridPropositionWarning';
import cx from 'classnames';

type Props = {
  rowId: NmckRow['id'];
  proposition: Proposition;
  updateProposition: (
    body: Partial<Pick<UpdatePropositionDTO, 'price' | 'description' | 'forAllRows'>>,
  ) => Promise<void>;
};

export const NmckGridPropositionInputSuffix = memo(
  ({ proposition, updateProposition, rowId }: Props) => {
    return (
      <Flex
        gap={4}
        align="center"
        justify="end"
        className={cx(styles.suffix, {
          [styles.suffixWithWarning]: proposition.warning,
        })}
      >
        <NmckGridPropositionDescription
          proposition={proposition}
          updateProposition={updateProposition}
        />

        <NmckGridPropositionWarning proposition={proposition} />

        <NmckGridPropositionClearButton
          rowId={rowId}
          proposition={proposition}
        />
      </Flex>
    );
  },
);

NmckGridPropositionInputSuffix.displayName = 'NmckGridPropositionInputSuffix';
