import { Moment } from 'moment';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';

export type Characteristic = { name: string; value: string };

export enum RegionType {
  SUPPLIER = 'SUPPLIER',
  BUYER = 'BUYER',
}

export enum NmckContractsCountry {
  ALL = 'ALL',
  RUSSIA = 'RUSSIA',
  EAES = 'EAES',
}

export enum NmckContractsSortType {
  RELEVANCE = 'RELEVANCE',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
}

export type NmckContractsFilter = {
  purchaseMethodIds: number[];
  priceFrom: number | null;
  priceUpTo: number | null;
  unitMeasure?: number;
  conclusionDateStart?: Moment;
  conclusionDateEnd?: Moment;
  executionDateStart?: Moment;
  executionDateEnd?: Moment;
  countFrom: number | null;
  countUpTo: number | null;
  regionIds: number[];
  regionType: RegionType;
  country?: NmckContractsCountry;
  characteristics: Characteristic[];
};

export type NmckContractsRequestParams = {
  filter: NmckContractsFilter;
  rowInfo: Pick<NmckRow, 'name' | 'code'>;
  pageSize: number;
  sort: NmckContractsSortType;
};
