import { memo } from 'react';
import { Select } from 'antd';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import VIcon from 'public/icons/v.svg';
import { nmckContractsSortSelectOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableSortSelect/constants';
import { labelRender } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableSortSelect/utils';
import styles from './ContractsTableSortSelect.module.scss';

export const ContractsTableSortSelect = memo(() => {
  const { sort } = useNmckGridContractsContext();
  const { changeSort } = useNmckGridContractsActionsContext();

  return (
    <Select
      options={nmckContractsSortSelectOptions}
      value={sort}
      onChange={changeSort}
      variant="borderless"
      labelRender={labelRender}
      className={styles.select}
      suffixIcon={<VIcon className="v-icon" />}
    />
  );
});

ContractsTableSortSelect.displayName = 'ContractsTableSortSelect';
