import { memo, useMemo } from 'react';
import { additionalCoeffCheckboxes } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/constants';
import styles from './AdditionalCoeffs.module.scss';
import { Flex } from 'antd';
import { CoeffCheckbox } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/AdditionalCoeffs/components/CoeffCheckbox/CoeffCheckbox';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { getMappedNmckGuardWithUpdatedPost } from '@/widgets/NmckGuard/utilts';

type Props = {
  postIndex: number;
};

export const AdditionalCoeffs = memo(({ postIndex }: Props) => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();
  const post = useMemo(
    () => data?.nmckGuardRawRequest.posts[postIndex],
    [data?.nmckGuardRawRequest.posts, postIndex],
  );

  const udChangeHandlers = useMemo(() => {
    return additionalCoeffCheckboxes.reduce(
      (acc, { value: valueKey }) => {
        acc[valueKey] = async (checked: boolean) => {
          if (data && post) {
            await updateNmckGuardWithFallback(
              getMappedNmckGuardWithUpdatedPost(data, postIndex, {
                ...post,
                [valueKey]: checked,
              }),
            );
          }
        };
        return acc;
      },
      {} as { [key: string]: (checked: boolean) => Promise<void> },
    );
  }, [data, post, updateNmckGuardWithFallback, postIndex]);

  return (
    <Flex
      vertical
      gap={18}
      className={styles.wrapper}
    >
      <div>Дополнительные коэффициенты</div>

      {post &&
        additionalCoeffCheckboxes.map(({ label, value }) => (
          <CoeffCheckbox
            key={value}
            post={post}
            postValueKey={value}
            onChange={udChangeHandlers[value]}
          >
            {label}
          </CoeffCheckbox>
        ))}
    </Flex>
  );
});

AdditionalCoeffs.displayName = 'AdditionalCoeffs';
