import { memo, useCallback } from 'react';
import styles from './WorkingPeriod.module.scss';
import { Flex, Tooltip } from 'antd';
import { UiDatePicker } from '@/components';
import moment from 'moment/moment';
import Question from '/public/icons/questionRound.svg';
import { Moment } from 'moment';
import { formatDate } from '@/utils';
import { dateFormat } from '@/constants';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { WorkingPeriodHoliday } from '@/widgets/NmckGuard/components/NmckGuardMainForm/components/WorkingPeriod/components/WorkingPeriodHoliday/WorkingPeriodHoliday';

export const WorkingPeriod = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const startDate = data?.startDate ? moment(data.startDate) : undefined;
  const endDate = data?.endDate ? moment(data.endDate) : undefined;

  const onStartDateChange = useCallback(
    async (date: Moment | null) => {
      if (data) {
        await updateNmckGuardWithFallback({
          ...data,
          startDate: date ? formatDate(date, dateFormat.serverFormat) : null,
        });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  const onEndDateChange = useCallback(
    async (date: Moment | null) => {
      if (data) {
        await updateNmckGuardWithFallback({
          ...data,
          endDate: date ? formatDate(date, dateFormat.serverFormat) : null,
        });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  return (
    <Flex
      vertical
      gap={24}
    >
      <div className={styles.title}>Период работы</div>
      <Flex
        vertical
        gap={16}
      >
        <Flex
          gap={16}
          align="center"
          wrap
        >
          <div className={styles.label}>Срок оказания услуги:</div>
          <UiDatePicker
            value={startDate}
            className={styles.datepicker}
            placeholder="Дата от..."
            onChange={onStartDateChange}
            maxDate={endDate}
          />
          <div>—</div>
          <UiDatePicker
            value={endDate}
            className={styles.datepicker}
            placeholder="Дата по..."
            onChange={onEndDateChange}
            minDate={startDate}
          />
          <Tooltip
            arrow={false}
            title="Количество дней и часов указывается за 1 пост. В итоговый расчет принимается с учетом количества постов."
            placement="bottom"
          >
            <Question className="hint-icon" />
          </Tooltip>
        </Flex>

        {data?.generalDataByYears.map((holiday, index) => (
          <WorkingPeriodHoliday
            key={holiday.year}
            holiday={holiday}
            index={index}
          />
        ))}
      </Flex>
    </Flex>
  );
});

WorkingPeriod.displayName = 'WorkingPeriod';
