import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Flex } from 'antd';
import styles from './NmckGridContractsFilter.module.scss';
import { getInitialContractsFilterState } from '@/components/NmckGrid/NmckGridContracts/constants';
import { CharacteristicsParam } from '@/types/types';
import { saveRegionsToStorage } from '@/components/NmckGrid/NmckGridContracts/helpers';
import PlusIcon from '/public/icons/plus.svg';
import MinusIcon from '/public/icons/minus.svg';
import { CharacteristicsFilter } from '@/widgets';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import { useInfiniteList } from '@/hooks';
import { useGetContractsInfinite } from '@/api/nmckApi/nmckApi';
import { ContractsFilterRegion } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterRegion/ContractsFilterRegion';
import { ContractsFilterExecutionDate } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterExecutionDate/ContractsFilterExecutionDate';
import { ContractsFilterPurchaseMethod } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterPurchaseMethod/ContractsFilterPurchaseMethod';
import { ContractsFilterPrice } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterPrice/ContractsFilterPrice';
import { ContractsFilterUnitMeasure } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterUnitMeasure/ContractsFilterUnitMeasure';
import { ContractsFilterConclusionDate } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterConclusionDate/ContractsFilterConclusionDate';
import { ContractsFilterCount } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterCount/ContractsFilterCount';
import { ContractsFilterCountry } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterCountry/ContractsFilterCountry';

export const NmckGridContractsFilter = memo(() => {
  const { filter, requestFilter } = useNmckGridContractsContext();
  const { setFilter } = useNmckGridContractsActionsContext();
  const { firstResponse, isLoading } = useInfiniteList(useGetContractsInfinite, requestFilter);
  const characteristics = useMemo(() => firstResponse?.characteristics ?? [], [firstResponse]);
  const [isAllFieldsShown, setIsAllFieldsShown] = useState(false);

  const handleCharacteristicsChange = useCallback(
    (newChars: CharacteristicsParam[]) => {
      setFilter((prev) => ({
        ...prev,
        characteristics: newChars,
      }));
    },
    [setFilter],
  );

  const resetAll = useCallback(() => {
    setFilter(getInitialContractsFilterState());
    saveRegionsToStorage([]);
  }, [setFilter]);

  const handleToggleFieldsShown = useCallback(() => setIsAllFieldsShown((prev) => !prev), []);

  return (
    <Flex
      gap={24}
      className={styles.wrapper}
    >
      <Flex
        className={styles.search}
        vertical
        gap={24}
      >
        <h3 className={styles.title}>Поиск исполненных без неустоек контрактов</h3>

        <ContractsFilterRegion />
        <ContractsFilterExecutionDate />
        <ContractsFilterCountry />
        {isAllFieldsShown && (
          <>
            <ContractsFilterPurchaseMethod />
            <ContractsFilterPrice />
            <ContractsFilterUnitMeasure />
            <ContractsFilterConclusionDate />
            <ContractsFilterCount />
          </>
        )}

        <Button
          icon={isAllFieldsShown ? <MinusIcon /> : <PlusIcon />}
          type="link"
          onClick={handleToggleFieldsShown}
          className={styles.toggleShownButton}
        >
          {isAllFieldsShown ? 'Меньше фильтров' : 'Больше фильтров'}
        </Button>
      </Flex>

      <CharacteristicsFilter
        list={characteristics}
        isListLoading={isLoading}
        characteristicsFilter={filter.characteristics}
        addButtonPosition="underList"
        headerJustify="space-between"
        selectClassName={styles.characteristicsSelect}
        onCharacteristicsChange={handleCharacteristicsChange}
        onReset={resetAll}
      />
    </Flex>
  );
});

NmckGridContractsFilter.displayName = 'NmckGridContractsFilter';
