import { memo } from 'react';
import { Divider } from 'antd';
import { NmckGridContractsNotFound } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsNotFound/NmckGridContractsNotFound';
import { ContractItem, ContractItemsResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { useGetContractsInfinite } from '@/api/nmckApi/nmckApi';
import styles from './NmckGridContracts.module.scss';
import { NmckGridContractsFilter } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/NmckGridContractsFilter';
import { useInfiniteList } from '@/hooks';
import { NmckGridContractsTable } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { NmckContractsRequestParams } from '@/components/NmckGrid/NmckGridContracts/types';

type Props = {
  record: NmckRow;
};

export const NmckGridContracts = memo(({ record }: Props) => {
  const { requestFilter } = useNmckGridContractsContext();
  const { list, isLoading } = useInfiniteList<
    NmckContractsRequestParams,
    ContractItem,
    ContractItemsResponse
  >(useGetContractsInfinite, requestFilter);

  return (
    <>
      <div className={styles.container}>
        <NmckGridContractsFilter />
        <div className={styles.divider}>
          <Divider />
        </div>
        {!!list?.length && <NmckGridContractsTable record={record} />}
      </div>
      {!list?.length && (
        <NmckGridContractsNotFound
          isContractsLoading={isLoading}
          code={record.code}
        />
      )}
    </>
  );
});
NmckGridContracts.displayName = 'NmckGridContracts';
