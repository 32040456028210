import { useContext } from 'react';
import { NmckGuardContext } from '@/widgets/NmckGuard/context/NmckGuardContext';

export const useNmckGuardContext = () => {
  const nmckGuardContext = useContext(NmckGuardContext);

  if (!nmckGuardContext) {
    throw new Error('NmckGuardContext must be used within a NmckGuardProvider');
  }

  return nmckGuardContext;
};
