'use client';

import { memo } from 'react';
import { ConfigProvider, ConfigProviderProps } from 'antd';
import colors from '@/styles/colors.module.scss';
import { NmckGuardProvider } from '@/widgets/NmckGuard/context/NmckGuardContext';
import { NmckGuardPageContent } from '@/widgets/NmckGuard/NmckGuardPageContent';

const theme: ConfigProviderProps['theme'] = {
  components: {
    InputNumber: {
      handleVisible: true as true | 'auto' | undefined,
    },
    Checkbox: {
      fontSize: 12,
      colorText: colors.light_gray,
    },
  },
};

type GuardByIdProps = {
  nmckId: number;
};

export const NmckGuardPage = memo(({ nmckId }: GuardByIdProps) => {
  return (
    <ConfigProvider theme={theme}>
      <NmckGuardProvider initialNmckId={nmckId}>
        <NmckGuardPageContent nmckId={nmckId} />
      </NmckGuardProvider>
    </ConfigProvider>
  );
});

NmckGuardPage.displayName = 'NmckGuardPage';
