import React, { memo, useMemo } from 'react';
import { Empty, EmptyProps, Spin } from 'antd';

type Props = {
  loading?: boolean;
} & EmptyProps;

export const UiEmpty = memo(
  ({ image = Empty.PRESENTED_IMAGE_SIMPLE, loading, description, ...props }: Props) => {
    const formattedDescription = useMemo(
      () => (loading ? <Spin /> : description),
      [description, loading],
    );

    return (
      <Empty
        {...props}
        image={image}
        description={formattedDescription}
      />
    );
  },
);

UiEmpty.displayName = 'UiEmpty';
