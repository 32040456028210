import { memo, useCallback } from 'react';
import styles from './WorkingPeriodHoliday.module.scss';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { inputNumberControls } from '@/constants/ui';
import { onlyNumberParser } from '@/utils/onlyNumberParser';
import { Flex } from 'antd';
import { NmckGuardGeneralDataByYear } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';

type Props = {
  holiday: NmckGuardGeneralDataByYear;
  index: number;
};

export const WorkingPeriodHoliday = memo(({ holiday, index }: Props) => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const handleHolidayCountChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data) {
        await updateNmckGuardWithFallback({
          ...data,
          generalDataByYears: data.generalDataByYears.with(index, {
            ...holiday,
            regionalHolidaysAmount: value || 0,
          }),
        });
      }
    },
    [data, holiday, updateNmckGuardWithFallback, index],
  );

  return (
    <Flex
      align="center"
      gap={8}
    >
      <div className={styles.label}>Региональных праздников в {holiday.year}:</div>
      <DebounceInputNumber
        value={holiday.regionalHolidaysAmount}
        type="number"
        className={styles.inputNumber}
        min={0}
        placeholder="0"
        controls={inputNumberControls}
        parser={onlyNumberParser}
        onDebouncedChange={handleHolidayCountChange}
      />
    </Flex>
  );
});

WorkingPeriodHoliday.displayName = 'WorkingPeriodHoliday';
