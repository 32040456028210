import { Button, Flex } from 'antd';
import cx from 'classnames';
import styles from './Pagination.module.scss';
import { memo } from 'react';

type PaginationProps = {
  isMoreLoading: boolean;
  onShowMore: () => void;
  hasMoreResults: boolean;
};

export const Pagination = memo(({ isMoreLoading, onShowMore, hasMoreResults }: PaginationProps) => {
  const disabled = isMoreLoading;

  return (
    hasMoreResults && (
      <Flex className={styles.pagination}>
        <Button
          type="link"
          size="small"
          className={cx(styles.button, {
            [styles.buttonDisabled]: disabled,
          })}
          onClick={onShowMore}
          disabled={disabled}
          loading={isMoreLoading}
        >
          Показать еще
        </Button>
      </Flex>
    )
  );
});

Pagination.displayName = 'Pagination';
