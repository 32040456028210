import { memo, useCallback, useMemo, useState } from 'react';
import { BadgeType } from '@/api/nmckApi/nmckApiTypes';
import cx from 'classnames';
import styles from './NmckGridCompatibilityWarning.module.scss';
import WarningIcon from '/public/icons/warning.svg';
import WarningNotFilledIcon from 'public/icons/warningNotFilled.svg';
import { IncompatibleModal } from '@/components/NmckGrid/NmckGridCompatibilityWarning/IncompatibleModal/IncompatibleModal';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { splitNmckById, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { setSplittedNmckIds } from '@/utils';
import { pageRoutes } from '@/app/pageRoutes';
import { useRouter } from 'next/navigation';
import { Button, Flex } from 'antd';

export const NmckGridCompatibilityWarning = memo(() => {
  const { nmckId } = useNmckGridContext();
  const { data } = useGetNmckById(nmckId);
  const router = useRouter();

  const [incompatibleModalOpen, setIncompatibleModalOpen] = useState(false);
  const [isSplitButtonLoading, setIsSplitButtonLoading] = useState(false);

  const hasIncompatibleCode = data?.badge === BadgeType.HAS_INCOMPATIBLE_CODES;
  const warningText = hasIncompatibleCode
    ? 'Несовместимые коды ОКПД2/КТРУ.'
    : 'В закупке содержатся коды ОКПД2/КТРУ, которые попадают под ограничения и запреты.';
  const warningIcon = useMemo(
    () => (hasIncompatibleCode ? <WarningIcon /> : <WarningNotFilledIcon />),
    [hasIncompatibleCode],
  );

  const handleModal = useCallback(() => {
    setIncompatibleModalOpen(!incompatibleModalOpen);
  }, [incompatibleModalOpen]);

  const handleSplit = useCallback(async () => {
    setIsSplitButtonLoading(true);
    try {
      const splitIds = await splitNmckById(nmckId);
      setSplittedNmckIds(splitIds);
      router.push(pageRoutes.nmckHistory);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSplitButtonLoading(false);
    }
  }, [nmckId, router]);

  return (
    data &&
    data.badge !== BadgeType.NO_BADGE && (
      <>
        <Flex
          align="center"
          justify="center"
          gap={16}
          className={cx(styles.warning, {
            [styles.warningBorder]: hasIncompatibleCode,
          })}
        >
          <Flex
            align="center"
            gap={8}
            className={styles.warningText}
          >
            {warningIcon}
            {warningText}
          </Flex>

          <Button
            type="link"
            size="small"
            className={styles.button}
            onClick={handleModal}
          >
            Подробнее
          </Button>

          {hasIncompatibleCode && (
            <Button
              type="link"
              size="small"
              className={styles.button}
              loading={isSplitButtonLoading}
              onClick={handleSplit}
            >
              Разделить закупку
            </Button>
          )}
        </Flex>
        <IncompatibleModal
          nmckId={nmckId}
          open={incompatibleModalOpen}
          name={data.name}
          onCancel={handleModal}
        />
      </>
    )
  );
});

NmckGridCompatibilityWarning.displayName = 'NmckGridCompatibilityWarning';
