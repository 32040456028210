import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { NmckGuardByIdResponse } from '@/api/nmckGuardApi/nmckGuardApiTypes';

type NmckGuardContextType = {
  nmckId: NmckGuardByIdResponse['id'];
};
type NmckGuardActionsContextType = {
  updateNmckId: (nmckId: NmckGuardByIdResponse['id']) => void;
};
type ProviderProps = {
  initialNmckId: number;
} & PropsWithChildren;

export const NmckGuardContext = createContext<NmckGuardContextType | null>(null);
export const NmckGuardActionsContext = createContext<NmckGuardActionsContextType | null>(null);

export const NmckGuardProvider = ({ children, initialNmckId }: ProviderProps) => {
  const [nmckId, setNmckId] = useState<NmckGuardContextType['nmckId']>(initialNmckId);

  const updateNmckId = useCallback((nmckId: NmckGuardByIdResponse['id']) => setNmckId(nmckId), []);

  const values = useMemo<NmckGuardContextType>(
    () => ({
      nmckId,
    }),
    [nmckId],
  );
  const actions = useMemo<NmckGuardActionsContextType>(
    () => ({
      updateNmckId,
    }),
    [updateNmckId],
  );

  return (
    <NmckGuardContext.Provider value={values}>
      <NmckGuardActionsContext.Provider value={actions}>
        {children}
      </NmckGuardActionsContext.Provider>
    </NmckGuardContext.Provider>
  );
};
