import { NPA, NPAType } from '@/api/okpdApi/okpdApiTypes';
import styles from './NPAList.module.scss';
import { memo } from 'react';
import { NPACard } from '@/widgets/OkpdKtru/components';

type NPAListProps = {
  npa?: NPA;
};

const keysList = [NPAType.NATIONAL, NPAType.PREF, NPAType.OTHER];

export const NPAList = memo(({ npa }: NPAListProps) => {
  if (!npa) return null;
  return (
    <div className={styles.container}>
      {keysList.map((key) => (
        <div
          className={styles.column}
          key={key}
        >
          <NPACard
            npaItems={npa[key]}
            npaType={key}
          />
        </div>
      ))}
    </div>
  );
});

NPAList.displayName = 'NPAList';
