import { useCallback } from 'react';
import { omit } from 'lodash';
import { mutate } from 'swr';
import { pageRoutes } from '@/app/pageRoutes';
import { postNmckGuard, putNmckGuard, useGetNmckGuard } from '@/api/nmckGuardApi/nmckGuardApi';
import { useNmckGuardContext } from '@/widgets/NmckGuard/context/useNmckGuardContext';
import { useNmckGuardActionsContext } from '@/widgets/NmckGuard/context/useNmckGuardActionsContext';
import { NmckGuardByIdResponse } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { getNmckGuardByIdKey } from '@/widgets/NmckGuard/utilts';

export const useNmckGuardFallbackDataHandler = () => {
  const { nmckId } = useNmckGuardContext();
  const { updateNmckId } = useNmckGuardActionsContext();
  const nmckGuardByIdResponse = useGetNmckGuard(nmckId);
  const { mutate: refreshNmckGuard } = nmckGuardByIdResponse;

  const navigateToPageWithIdShallow = useCallback(
    (nmckId: NmckGuardByIdResponse['id']) => {
      updateNmckId(nmckId);
      window.history.pushState({}, '', `${pageRoutes.nmckGuard}/${nmckId}`);
    },
    [updateNmckId],
  );

  const updateNmckGuardWithFallback = useCallback(
    async (...args: Parameters<typeof putNmckGuard>) => {
      if (!nmckId) {
        const nmck = await postNmckGuard();
        const updatedNmck = await putNmckGuard({ id: nmck.id, ...omit(...args, ['id']) });

        await mutate(getNmckGuardByIdKey(updatedNmck.id), updatedNmck);
        navigateToPageWithIdShallow(updatedNmck.id);
        return updatedNmck;
      } else {
        const updatedNmck = await putNmckGuard(...args);
        await refreshNmckGuard();
        return updatedNmck;
      }
    },
    [navigateToPageWithIdShallow, nmckId, refreshNmckGuard],
  );

  return {
    updateNmckGuardWithFallback,

    nmckId,
    nmckGuardByIdResponse,
  };
};
