import { memo, useMemo } from 'react';
import { ResultsList } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsList/ResultsList';
import { GuardResultItem } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/types';
import { NmckGuardResultsForEachPost } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { formatNumber } from '@/utils/formatNumber';

type Props = {
  result?: NmckGuardResultsForEachPost;
  selectedYear?: number;
};

export const ResultsDirectCosts = memo(({ result, selectedYear }: Props) => {
  const dataEachPostForSelectedYear = useMemo(
    () => result?.postDataByYears.find((year) => year.year === selectedYear),
    [result, selectedYear],
  );

  const list: GuardResultItem[] = useMemo(
    () =>
      result && dataEachPostForSelectedYear
        ? [
            {
              label: 'Базовая заработная плата (БЗП)',
              value: formatNumber(dataEachPostForSelectedYear.bzp),
            },
            {
              label: 'Доплата за ночные часы (Дн)',
              value: formatNumber(dataEachPostForSelectedYear.dn),
            },
            {
              label: 'Доплата за районный коэффициент (Дрк)',
              value: formatNumber(dataEachPostForSelectedYear.drk),
            },
            {
              label: 'Доплата за праздничные дни (Двп)',
              value: formatNumber(dataEachPostForSelectedYear.dvp),
            },
            {
              label: 'Резерв на отпуск (РО)',
              value: formatNumber(dataEachPostForSelectedYear.ro),
            },
            {
              label: 'Страховые взносы (СВ)',
              value: formatNumber(dataEachPostForSelectedYear.sv),
            },
            {
              label: 'Корректирующий коэффициент (U)',
              value: formatNumber(result.u),
              children: [
                {
                  label: 'Базовый коэффициент (U6)',
                  value: result.u6,
                },
                {
                  label: 'Uд1',
                  value: result.ud1,
                },
                {
                  label: 'Uд2',
                  value: result.ud2,
                },
                {
                  label: 'Uд3',
                  value: result.ud3,
                },
                {
                  label: 'Uд4',
                  value: result.ud4,
                },
                {
                  label: 'Uд5',
                  value: result.ud5,
                },
                {
                  label: 'Uд5',
                  value: result.ud5,
                },
              ],
            },
          ]
        : [],
    [dataEachPostForSelectedYear, result],
  );

  return (
    <ResultsList
      title="Прямые затраты, ₽/час"
      list={list}
    />
  );
});

ResultsDirectCosts.displayName = 'ResultsDirectCosts';
