import { useContext } from 'react';
import { NmckGridContext } from '@/components/NmckGrid/context/NmckGridContext';

export const useNmckGridContext = () => {
  const context = useContext(NmckGridContext);

  if (!context) {
    throw new Error('NmckGridContext must be used within a NmckGridProvider');
  }

  return context;
};
