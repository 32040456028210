import { InputNumber, InputNumberProps } from 'antd';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { InputNumberRef } from 'rc-input-number';

export type DebounceInputNumberValueType = number | null;

export type DebounceInputNumberUpdatedOptions = {
  field: string;
};

type DebounceInputProps = {
  field?: string;
  debounceMs?: number;
  onDebouncedChange: (
    value: DebounceInputNumberValueType,
    options: DebounceInputNumberUpdatedOptions,
  ) => void;
} & InputNumberProps<number>;

export const DebounceInputNumber = memo(
  forwardRef<InputNumberRef, DebounceInputProps>(
    (
      { value, field = '', debounceMs = 500, onDebouncedChange, ...inputProps }: DebounceInputProps,
      ref,
    ) => {
      const [inputValue, setInputValue] = useState(value);

      const debouncedChangeValue = useMemo(
        () =>
          debounce((value: DebounceInputNumberValueType) => {
            onDebouncedChange(value, { field });
          }, debounceMs),
        [debounceMs, onDebouncedChange, field],
      );

      const handleInputChange = useCallback(
        (value: DebounceInputNumberValueType) => {
          setInputValue(value);
          debouncedChangeValue(value);
        },
        [debouncedChangeValue],
      );

      useEffect(() => {
        setInputValue(value);
      }, [value]);

      return (
        <InputNumber<number>
          {...inputProps}
          ref={ref}
          value={inputValue}
          onChange={handleInputChange}
        />
      );
    },
  ),
);

DebounceInputNumber.displayName = 'DebounceInputNumber';
