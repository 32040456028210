import { memo, useCallback } from 'react';
import { ContractsFilterFieldRow } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/components/ContractsFilterFieldRow/ContractsFilterFieldRow';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';
import styles from './ContractsFilterUnitMeasure.module.scss';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';
import { OptionWithNumberValue } from '@/types/types';

export const ContractsFilterUnitMeasure = memo(() => {
  const { setFilter } = useNmckGridContractsActionsContext();

  const handleUnitChange = useCallback(
    (option?: OptionWithNumberValue) => {
      setFilter((prev) => ({
        ...prev,
        unitMeasure: option?.value,
      }));
    },
    [setFilter],
  );

  return (
    <ContractsFilterFieldRow
      label="Единица измерения"
      field={
        <UnitMeasureSelect
          allowClear
          className={styles.select}
          placeholder="Выберите единицу измерения"
          onChange={handleUnitChange}
        />
      }
    />
  );
});

ContractsFilterUnitMeasure.displayName = 'ContractsFilterUnitMeasure';
