import { memo, useCallback, useContext, useState } from 'react';

import Cross from '/public/icons/cross.svg';
import Vicon from '/public/icons/v.svg';
import { Flex } from 'antd';
import { DebounceInput } from '@/components';
import cx from 'classnames';
import styles from './ReestrAllPageHeader.module.scss';
import { BDUIFilterParam, BDUIFilterResponse } from '@/components/BDUIFilter/types';
import { ReestrAllContext } from '@/widgets/ReestrAll/context/context';
import { actions } from '@/widgets/ReestrAll/context/actions';
import { RadioFilters } from './components/RadioFilters/RadioFilters';
import { BDUIFilter } from '@/components/BDUIFilter/BDUIFilter';

const clearIcon = { clearIcon: <Cross className={styles.crossIcon} /> };

type ReestrAllPageHeaderProps = {
  filtersResponse?: BDUIFilterResponse[];
  isContractsLoading: boolean;
};

export const ReestrAllPageHeader = memo(
  ({ filtersResponse = [], isContractsLoading }: ReestrAllPageHeaderProps) => {
    const { state, dispatch } = useContext(ReestrAllContext);
    const [additionalParams, setAdditonalParams] = useState(true);

    const handleFiltersChange = useCallback(
      (newFilters: BDUIFilterParam[]) => {
        dispatch(actions.filtersUpdate(newFilters));
      },
      [dispatch],
    );

    const handleAdditionalParamsClick = useCallback(() => {
      setAdditonalParams((prev) => !prev);
    }, []);

    const handleChangeSearch = useCallback(
      (value: string) => {
        dispatch(actions.setSearch(value));
      },
      [dispatch],
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.flex}>
          <Flex gap={64}>
            <Flex gap={32}>
              <div className={styles.title}>Все закупки из ЕИС</div>
              <DebounceInput
                value={state.search}
                onDebouncedChange={handleChangeSearch}
                placeholder="Поиск"
                variant="borderless"
                className={cx('input-line', styles.searchInput)}
                allowClear={clearIcon}
              />
            </Flex>
            <Flex
              justify="center"
              align="center"
              gap={8}
              className={cx(styles.expand, {
                [styles.active]: additionalParams,
              })}
              onClick={handleAdditionalParamsClick}
            >
              <div>Уточняющие параметры</div>
              <Vicon
                className={cx(styles.expandIcon, {
                  [styles.active]: additionalParams,
                })}
              ></Vicon>
            </Flex>
          </Flex>
          {additionalParams && (
            <>
              <RadioFilters />
              <BDUIFilter
                filtersResponse={filtersResponse}
                currentFilterParams={state.filters}
                isFiltersLoading={isContractsLoading}
                onFiltersChange={handleFiltersChange}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);
ReestrAllPageHeader.displayName = 'ReestrAllPageHeader';
