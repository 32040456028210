import { NmckGuardByIdResponse, NmckGuardPost } from '@/api/nmckGuardApi/nmckGuardApiTypes';

export const getMappedNmckGuardWithUpdatedPost = (
  data: NmckGuardByIdResponse,
  postIndex: number,
  post: NmckGuardPost,
): NmckGuardByIdResponse => {
  return {
    ...data,
    nmckGuardRawRequest: {
      ...data.nmckGuardRawRequest,
      posts: data.nmckGuardRawRequest.posts.with(postIndex, post),
    },
  };
};

export const getNmckGuardByIdKey = (nmckId: NmckGuardByIdResponse['id']) => `/nmck/guard/${nmckId}`;
