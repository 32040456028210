import { useContext } from 'react';
import { NmckGuardActionsContext } from '@/widgets/NmckGuard/context/NmckGuardContext';

export const useNmckGuardActionsContext = () => {
  const nmckGuardActionsContext = useContext(NmckGuardActionsContext);

  if (!nmckGuardActionsContext) {
    throw new Error('NmckGuardActionsContext must be used within a NmckGuardProvider');
  }

  return nmckGuardActionsContext;
};
