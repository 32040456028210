import { ChangeEvent, Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { Button, Checkbox, Flex, Input } from 'antd';
import Trash from '/public/icons/trash.svg';
import styles from './NmckGridPropositionForm.module.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Proposition, UpdatePropositionDTO } from '@/api/nmckApi/nmckApiTypes';
import { deleteNmckProposition, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';

type Props = {
  proposition: Proposition;
  propositionForm: Pick<UpdatePropositionDTO, 'description' | 'forAllRows'>;
  setPropositionForm: Dispatch<
    SetStateAction<Pick<UpdatePropositionDTO, 'description' | 'forAllRows'>>
  >;
  togglePopoverVisible: (open: boolean) => void;
};

export const NmckGridPropositionForm = memo(
  ({ proposition, propositionForm, setPropositionForm, togglePopoverVisible }: Props) => {
    const { nmckId } = useNmckGridContext();
    const { mutate: refreshNmck } = useGetNmckById(nmckId);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleForAllRowsCheckboxChange = useCallback(
      ({ target: { checked } }: CheckboxChangeEvent) => {
        setPropositionForm((state) => ({ ...state, forAllRows: checked }));
      },
      [setPropositionForm],
    );

    const handlePropositionDescription = useCallback(
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setPropositionForm((state) => ({ ...state, description: value }));
      },
      [setPropositionForm],
    );

    const handleUpdateProposition = useCallback(async () => {
      togglePopoverVisible(false);
    }, [togglePopoverVisible]);

    const handleDeleteProposition = useCallback(async () => {
      setIsDeleteLoading(true);
      await deleteNmckProposition(nmckId, proposition.columnNumber);
      await refreshNmck();
      setIsDeleteLoading(false);
    }, [nmckId, proposition.columnNumber, refreshNmck]);

    return (
      <Flex
        vertical
        gap={16}
      >
        <Flex
          gap={16}
          align="center"
        >
          <Input
            className={styles.input}
            value={propositionForm.description ?? ''}
            onChange={handlePropositionDescription}
            onPressEnter={handleUpdateProposition}
          />
          <Button
            type="link"
            className={styles.trashButton}
            icon={<Trash />}
            loading={isDeleteLoading}
            onClick={handleDeleteProposition}
          />
        </Flex>
        <Flex align="center">
          <Checkbox
            checked={propositionForm.forAllRows}
            onChange={handleForAllRowsCheckboxChange}
          >
            Изменить для всех значений “Цена {proposition.columnNumber}”
          </Checkbox>
        </Flex>
      </Flex>
    );
  },
);

NmckGridPropositionForm.displayName = 'NmckGridPropositionForm';
