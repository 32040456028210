import {
  deleteNmckProposition,
  getNmckXlsx,
  postCopyNmckRow,
  postNewNmck,
  postNewNmckRow,
  postNewProposition,
  updateNmck,
  updateNmckRow,
  updateProposition,
  useGetNmckById,
} from '@/api/nmckApi/nmckApi';
import { omit } from 'lodash';
import { pageRoutes } from '@/app/pageRoutes';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { mutate } from 'swr';
import { useCallback } from 'react';
import { postNewNmckMedDevice } from '@/api/nmckMedDevicesApi/nmckMedDevicesApi';
import { useNmckPathname } from '@/components/NmckGrid/hooks/useNmckPathname';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { useNmckGridActionsContext } from '@/components/NmckGrid/context/useNmckGridActionsContext';

export const useNmckFallbackDataHandler = () => {
  const { nmckId } = useNmckGridContext();
  const { updateNmckId } = useNmckGridActionsContext();
  const { isNmckMedDevices, isSearchParamsExist } = useNmckPathname();

  const { mutate: refreshNmck, data } = useGetNmckById(nmckId);

  const createNmck = useCallback(async () => {
    if (isNmckMedDevices) {
      return postNewNmckMedDevice({
        copyFromId: null,
      });
    } else {
      return postNewNmck({
        copyFromId: null,
      });
    }
  }, [isNmckMedDevices]);

  const updateNmckRowFromFallbackData = useCallback(
    async (createdNmckId: NmckByIdResponse['id'], createdRows: NmckByIdResponse['rows']) => {
      if (data && isSearchParamsExist) {
        return updateNmckRow(createdNmckId, { ...data.rows[0], id: createdRows[0].id });
      }
    },
    [data, isSearchParamsExist],
  );

  const navigateToDetailPage = useCallback(
    (nmckId: NmckByIdResponse['id']) => {
      const path = isNmckMedDevices ? pageRoutes.nmckMedDevices : pageRoutes.nmckAnalysis;
      updateNmckId(nmckId);
      window.history.pushState({}, '', `${path}/${nmckId}`);
    },
    [isNmckMedDevices, updateNmckId],
  );

  const updateNmckWithFallback = useCallback(
    async (...args: Parameters<typeof updateNmck>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        const updatedNmckAfterUpdateRow = await updateNmckRowFromFallbackData(nmck.id, nmck.rows);

        const updatedNmck = await updateNmck({
          ...(updatedNmckAfterUpdateRow ?? nmck),
          ...omit(...args, ['id', 'versionNumber']),
        });
        await mutate(`/nmck/${nmck.id}`, updatedNmck, false);
        navigateToDetailPage(nmck.id);
      } else {
        await updateNmck(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  const updateNmckRowWithFallback = useCallback(
    async (...args: Parameters<typeof updateNmckRow>) => {
      if (!nmckId) {
        const { id } = await createNmck();
        const updatedNmck = await updateNmckRow(id, args[1]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await updateNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck],
  );

  const updatePropositionWithFallback = useCallback(
    async (...args: Parameters<typeof updateProposition>) => {
      if (!nmckId) {
        const { id, rows } = await createNmck();
        await updateNmckRowFromFallbackData(id, rows);
        const updatedNmck = await updateProposition(id, rows[0].id, args[2], args[3]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await updateProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  const createPropositionWithFallback = useCallback(
    async (...args: Parameters<typeof postNewProposition>) => {
      if (!nmckId) {
        const { id, rows } = await createNmck();
        await updateNmckRowFromFallbackData(id, rows);
        const updatedNmck = await postNewProposition(id);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await postNewProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  const deletePropositionWithFallback = useCallback(
    async (...args: Parameters<typeof deleteNmckProposition>) => {
      if (!nmckId) {
        const { id, rows } = await createNmck();
        await updateNmckRowFromFallbackData(id, rows);
        const updatedNmck = await deleteNmckProposition(id, args[1]);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await deleteNmckProposition(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  const createNmckRowWithFallback = useCallback(
    async (...args: Parameters<typeof postNewNmckRow>) => {
      if (!nmckId) {
        const { id, rows } = await createNmck();
        await updateNmckRowFromFallbackData(id, rows);
        const updatedNmck = await postNewNmckRow(id);
        await mutate(`/nmck/${id}`, updatedNmck, false);
        navigateToDetailPage(id);
      } else {
        await postNewNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  const downloadNmckExcelWithFallback = useCallback(
    async (...args: Parameters<typeof getNmckXlsx>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        await updateNmckRowFromFallbackData(nmck.id, nmck.rows);
        await mutate(`/nmck/${nmck.id}`, nmck, false);
        navigateToDetailPage(nmck.id);

        await getNmckXlsx(nmck.id, args[1]);
      } else {
        await getNmckXlsx(...args);
      }
    },
    [createNmck, navigateToDetailPage, nmckId, updateNmckRowFromFallbackData],
  );

  const createCopyNmckRow = useCallback(
    async (...args: Parameters<typeof postCopyNmckRow>) => {
      if (!nmckId) {
        const nmck = await createNmck();
        await updateNmckRowFromFallbackData(nmck.id, nmck.rows);
        const updatedNmck = await postCopyNmckRow(nmck.id, args[1]);
        await mutate(`/nmck/${nmck.id}`, updatedNmck, false);
        navigateToDetailPage(nmck.id);
      } else {
        await postCopyNmckRow(...args);
        await refreshNmck();
      }
    },
    [createNmck, navigateToDetailPage, nmckId, refreshNmck, updateNmckRowFromFallbackData],
  );

  return {
    updateNmckWithFallback,
    updateNmckRowWithFallback,
    updatePropositionWithFallback,
    createPropositionWithFallback,
    deletePropositionWithFallback,
    createNmckRowWithFallback,
    createCopyNmckRow,
    downloadNmckExcelWithFallback,
  };
};
