import { memo, useCallback } from 'react';
import { DebounceInput } from '@/components';
import { Tooltip } from 'antd';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { useGetNmckById } from '@/api/nmckApi/nmckApi';
import { useNmckFallbackDataHandler } from '@/components/NmckGrid/hooks/useNmckFallbackDataHandler';
import { eventsGTM, sendEventGTM } from '@/metrika';

export const NmckGridHeaderResponsibleInput = memo(() => {
  const { nmckId } = useNmckGridContext();
  const { data } = useGetNmckById(nmckId);
  const { updateNmckWithFallback } = useNmckFallbackDataHandler();

  const handleResponsibleChange = useCallback(
    async (value: string) => {
      if (data) {
        sendEventGTM(eventsGTM.nmckResponsibleChange, { value });
        await updateNmckWithFallback({ ...data, responsible: value });
      }
    },
    [data, updateNmckWithFallback],
  );

  return (
    <Tooltip
      arrow={false}
      title={data?.responsible}
      placement="bottom"
    >
      <DebounceInput
        value={data?.responsible}
        onDebouncedChange={handleResponsibleChange}
        className="input-line"
        placeholder="Ответственный"
        variant="borderless"
        disabled={!data}
      />
    </Tooltip>
  );
});

NmckGridHeaderResponsibleInput.displayName = 'NmckGridHeaderResponsibleInput';
