import {
  TokenBasedPaginationState,
  TokenBasedPaginationTokenValue,
} from '@/api/commonApi/commonApiTypes';

export const getSWRInfinitePaginationToken = <TResponse extends TokenBasedPaginationState>(
  index: number,
  previousPageData: TResponse | null,
): TokenBasedPaginationTokenValue => {
  if (previousPageData && !previousPageData.hasMoreResults) return null;
  return index === 0 ? null : (previousPageData?.token ?? null);
};
