import { useContext } from 'react';
import { NmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/NmckGridContractsContext';

export const useNmckGridContractsActionsContext = () => {
  const nmckGridContractsContext = useContext(NmckGridContractsActionsContext);

  if (!nmckGridContractsContext) {
    throw new Error(
      'NmckGridContractsActionsContext must be used within a NmckGridContractsProvider',
    );
  }

  return nmckGridContractsContext;
};
