import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AutoComplete, Flex, InputRef, Spin } from 'antd';
import { OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { renderOption } from '@/components/NmckGrid/NmckGridTruInput/NmckGridTruInput';
import styles from './OkpdKtruAutocomplete.module.scss';
import { DebounceInput, OkpdFlowModal } from '@/components';
import { Tooltip } from 'antd/lib';
import Question from '/public/icons/questionRound.svg';
import DotsIcon from '/public/icons/dots.svg';
import { OkpdKtruModalPageParams } from '@/components/OkpdFlowModal/types';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { useGetOkpdList } from '@/api/okpdApi/okpdApi';

const okpdKtruTooltipTitle =
  'Наиболее часто пользователи применяют КТРУ 80.10.12.000-00000002 и 80.10.12.000-00000003.';

export const OkpdKtruAutocomplete = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const [searchString, setSearchString] = useState('');
  const [isOkpdFlowModalOpen, setIsOkpdFlowModalOpen] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const { data: okpdList, isLoading } = useGetOkpdList({ searchString });

  const options = useMemo(
    () =>
      okpdList?.results.map((item: OkpdResult) => ({
        value: item.code,
        data: item,
        label: renderOption(item),
      })) ?? [],
    [okpdList],
  );

  const onSelect = useCallback(
    async (_: unknown, { data: option }: { data: OkpdResult }) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, okpdCode: option.code });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  const handleOkpdKtruSearchInputChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const handleOpenModal = useCallback(() => setIsOkpdFlowModalOpen(true), []);

  const handleAddToCalculationFromModal = useCallback(
    async ({ code }: OkpdKtruModalPageParams) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, okpdCode: code });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  return (
    <>
      <Flex
        align="center"
        gap={8}
      >
        <AutoComplete
          options={options}
          popupMatchSelectWidth={500}
          onSelect={onSelect}
          value={data?.okpdCode ?? ''}
          className={styles.autoComplete}
        >
          <DebounceInput
            ref={inputRef}
            value={data?.okpdCode ?? ''}
            placeholder="ОКПД2/КТРУ"
            suffix={
              isLoading ? (
                <Spin size="small" />
              ) : (
                <DotsIcon
                  className={styles.okpd2InputIcon}
                  onClick={handleOpenModal}
                />
              )
            }
            className="input-line"
            variant="borderless"
            onDebouncedChange={handleOkpdKtruSearchInputChange}
          />
        </AutoComplete>
        <Tooltip
          arrow={false}
          title={okpdKtruTooltipTitle}
          placement="bottom"
        >
          <Question className="hint-icon" />
        </Tooltip>
      </Flex>
      <OkpdFlowModal
        isOpen={isOkpdFlowModalOpen}
        setIsOpen={setIsOkpdFlowModalOpen}
        searchString={searchString}
        onAddToCalculation={handleAddToCalculationFromModal}
      />
    </>
  );
});

OkpdKtruAutocomplete.displayName = 'OkpdKtruAutocomplete';
