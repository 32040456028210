import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import CrossIcon from '/public/icons/cross.svg';
import Vicon from '/public/icons/v.svg';
import Calendar from '/public/icons/calendar.svg';
import { Button, Flex, Input, Select } from 'antd';
import { Option, OptionWithNumberValue } from '@/types/types';
import { filterOption } from '@/utils/filterOptions';
import { LoadingOutlined } from '@ant-design/icons';
import { DebounceInput, UiDatePicker } from '@/components';
import styles from './BDUIFilterItem.module.scss';
import moment, { Moment } from 'moment';
import {
  BDUIComponentType,
  BDUIFilterItemType,
  BDUIFilterRangeValue,
} from '@/components/BDUIFilter/types';
import { RegionsTreeSelect } from '@/components/RegionsTreeSelect/RegionsTreeSelect';
import { UnitMeasureSelect } from '@/components/UnitMeasureSelect/UnitMeasureSelect';
import { PurchaseMethodsTreeSelect } from '@/components/PurchaseMethodsTreeSelect/PurchaseMethodsTreeSelect';
import { dateFormat } from '@/constants';

type FiltersItemProps = {
  names: Option[];
  values: Record<string, Option[]>;
  item: BDUIFilterItemType;
  isOptionsLoading: boolean;
  onChangeItem: (value: BDUIFilterItemType) => void;
  onDeleteItem: (item: BDUIFilterItemType) => void;

  isFiltersChanged: boolean;
  rowId?: number;
};

export const BDUIFilterItem = memo(
  ({
    names,
    values,
    item,
    isOptionsLoading,
    isFiltersChanged,
    onChangeItem,
    onDeleteItem,
    rowId,
  }: FiltersItemProps) => {
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [minValue, setMinValue] = useState<string>('');
    const [maxValue, setMaxValue] = useState<string>('');
    const [warning, setWarning] = useState(false);

    const valueOptions = useMemo(() => {
      return values[item.name?.label ?? ''] ?? [];
    }, [values, item.name?.label]);

    const clearState = useCallback(() => {
      setStartDate(null);
      setEndDate(null);
      setMinValue('');
      setMaxValue('');
      setWarning(false);
    }, []);

    const handleChangeName = useCallback(
      (_: unknown, option: Option) => {
        onChangeItem({
          ...item,
          name: option,
          value: undefined,
          type: option.type,
        });
        clearState();
      },
      [onChangeItem, item, clearState],
    );

    const handleSelectChange = useCallback(
      (_: unknown, option: Option) => {
        onChangeItem({
          ...item,
          value: { type: 'single', value: option.value },
        });
      },
      [onChangeItem, item],
    );

    const handleUnitMeasureChange = useCallback(
      (option?: OptionWithNumberValue) => {
        onChangeItem({
          ...item,
          value: { type: 'single', value: option?.value },
        });
      },
      [item, onChangeItem],
    );

    const handleTreeSelectChange = useCallback(
      (newIds: number[]) => {
        onChangeItem({
          ...item,
          value: { type: 'multiselect', values: newIds },
        });
      },
      [onChangeItem, item],
    );

    const handleInputChange = useCallback(
      (value: string) => {
        onChangeItem({
          ...item,
          value: { type: 'single', value },
        });
      },
      [onChangeItem, item],
    );

    const handleMinChange = useCallback(
      (value: string) => {
        const newValue = parseFloat(value);
        const currentValue = item.value as BDUIFilterRangeValue | undefined;
        const max = currentValue?.max ?? '';

        if (max && newValue > parseFloat(max)) {
          setWarning(true);
          return;
        }

        setMinValue(value);
        setWarning(false);
        onChangeItem({
          ...item,
          value: {
            type: 'range',
            min: value,
            max,
          },
        });
      },
      [onChangeItem, item],
    );

    const handleMaxChange = useCallback(
      (value: string) => {
        const newValue = parseFloat(value);
        const currentValue = item.value as BDUIFilterRangeValue | undefined;
        const min = currentValue?.min ?? '';

        if (min && newValue < parseFloat(min)) {
          setWarning(true);
          return;
        }

        setMaxValue(value);
        setWarning(false);
        onChangeItem({
          ...item,
          value: {
            type: 'range',
            min,
            max: value,
          },
        });
      },
      [onChangeItem, item],
    );

    const handleStartDateChange = useCallback(
      (newDate: Moment | null) => {
        setStartDate(newDate);

        const currentValue = item.value as BDUIFilterRangeValue | undefined;
        onChangeItem({
          ...item,
          value: {
            type: 'range',
            min: newDate ? newDate.format(dateFormat.serverFormat) : undefined,
            max: currentValue?.max,
          },
        });
      },
      [onChangeItem, item],
    );

    const handleEndDateChange = useCallback(
      (newDate: Moment | null) => {
        setEndDate(newDate);

        const currentValue = item.value as BDUIFilterRangeValue | undefined;
        onChangeItem({
          ...item,
          value: {
            type: 'range',
            min: currentValue?.min,
            max: newDate ? newDate.format(dateFormat.serverFormat) : undefined,
          },
        });
      },
      [onChangeItem, item],
    );

    const handleDelete = useCallback(() => {
      onDeleteItem(item);
    }, [onDeleteItem, item]);

    const disableStartDate = useMemo(() => {
      return (currentDate: Moment) => {
        return endDate ? currentDate.isAfter(endDate, 'day') : false;
      };
    }, [endDate]);

    const disableEndDate = useMemo(() => {
      return (currentDate: Moment) => {
        return startDate ? currentDate.isBefore(startDate, 'day') : false;
      };
    }, [startDate]);

    useEffect(() => {
      if (!isFiltersChanged) {
        if (item.value?.type === 'range' && item.type === BDUIComponentType.DATE_INTERVAL) {
          const minDate = moment(item.value.min);
          const maxDate = moment(item.value.max);

          minDate.isValid() && setStartDate(minDate);
          maxDate.isValid() && setEndDate(maxDate);
        }
      }
    }, [isFiltersChanged, item]);

    return (
      <Flex
        gap={16}
        align="center"
      >
        <Select
          options={names}
          value={item.name}
          className={styles.select}
          placeholder="Выберите фильтр"
          showSearch
          filterOption={filterOption}
          suffixIcon={isOptionsLoading ? <LoadingOutlined spin /> : <Vicon className="v-icon" />}
          onSelect={handleChangeName}
        />
        {item.type === BDUIComponentType.EMPTY && (
          <Input
            disabled
            className={styles.input}
            placeholder="Выберите поле для фильтрации"
          />
        )}
        {item.type === BDUIComponentType.INPUT && (
          <DebounceInput
            value={item.value?.type === 'single' ? item.value.value : ''}
            className={styles.input}
            placeholder="Введите значение"
            onDebouncedChange={handleInputChange}
          />
        )}
        {item.type === BDUIComponentType.SELECT && (
          <Select
            options={valueOptions}
            value={item.value?.type === 'single' ? item.value.value : undefined}
            className={styles.select}
            showSearch
            placeholder="Выберите значение"
            suffixIcon={isOptionsLoading ? <LoadingOutlined spin /> : <Vicon className="v-icon" />}
            onSelect={handleSelectChange}
          />
        )}
        {item.type === BDUIComponentType.DATE_INTERVAL && (
          <>
            <UiDatePicker
              value={startDate}
              className={styles.datePicker}
              placeholder="Дата, от"
              suffixIcon={<Calendar />}
              onChange={handleStartDateChange}
              disabledDate={disableStartDate}
            />
            <UiDatePicker
              value={endDate}
              className={styles.datePicker}
              placeholder="Дата, до"
              suffixIcon={<Calendar />}
              onChange={handleEndDateChange}
              disabledDate={disableEndDate}
            />
          </>
        )}
        {item.type === BDUIComponentType.DOUBLE_INTERVAL && (
          <>
            <DebounceInput
              type="number"
              value={item.value?.type === 'range' ? item.value.min : ''}
              className={styles.doubleInput}
              placeholder="От"
              onDebouncedChange={handleMinChange}
              max={maxValue}
              status={warning ? 'error' : ''}
            />
            <DebounceInput
              type="number"
              value={item.value?.type === 'range' ? item.value.max : ''}
              className={styles.doubleInput}
              placeholder="До"
              onDebouncedChange={handleMaxChange}
              min={minValue}
              status={warning ? 'error' : ''}
            />
          </>
        )}
        {item.type === BDUIComponentType.REGION && (
          <RegionsTreeSelect
            value={item.value?.type === 'multiselect' ? item.value.values : []}
            className={styles.select}
            onRegionsChange={handleTreeSelectChange}
          />
        )}
        {item.type === BDUIComponentType.PURCHASE_METHOD && (
          <PurchaseMethodsTreeSelect
            value={item.value?.type === 'multiselect' ? item.value.values : []}
            className={styles.select}
            onPurchaseMethodsChange={handleTreeSelectChange}
          />
        )}
        {item.type === BDUIComponentType.MEASURE_UNIT && (
          <UnitMeasureSelect
            className={styles.select}
            rowId={rowId}
            allowClear
            onChange={handleUnitMeasureChange}
          />
        )}
        <Button
          icon={<CrossIcon />}
          type="link"
          onClick={handleDelete}
        />
      </Flex>
    );
  },
);

BDUIFilterItem.displayName = 'BDUIFilterItem';
