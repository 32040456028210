import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { NMCK_ANALYSIS_BY_ID_FALLBACK } from '@/widgets/NmckAnalysis/constants';

type NmckGridContextType = {
  nmckId: NmckByIdResponse['id'];
};
type NmckGridActionsContextType = {
  updateNmckId: (nmckId: NmckByIdResponse['id']) => void;
};

export const NmckGridContext = createContext<NmckGridContextType | null>(null);
export const NmckGridActionsContext = createContext<NmckGridActionsContextType | null>(null);

export const NmckGridProvider = ({ children }: PropsWithChildren) => {
  const [nmckId, setNmckId] = useState<NmckGridContextType['nmckId']>(
    NMCK_ANALYSIS_BY_ID_FALLBACK['id'],
  );

  const updateNmckId = useCallback((nmckId: NmckByIdResponse['id']) => setNmckId(nmckId), []);

  const values = useMemo<NmckGridContextType>(
    () => ({
      nmckId,
    }),
    [nmckId],
  );
  const actions = useMemo<NmckGridActionsContextType>(
    () => ({
      updateNmckId,
    }),
    [updateNmckId],
  );

  return (
    <NmckGridContext.Provider value={values}>
      <NmckGridActionsContext.Provider value={actions}>{children}</NmckGridActionsContext.Provider>
    </NmckGridContext.Provider>
  );
};
