import { memo, useEffect } from 'react';
import { NPA, NPAType } from '@/api/okpdApi/okpdApiTypes';
import { Flex } from 'antd';
import { OkpdKtruDetailNPACard } from '@/components/OkpdKtruDetail/OkpdKtruDetailNPACard/OkpdKtruDetailNPACard';
import { eventsGTM, sendEventGTM } from '@/metrika';

type OkpdDetailNPAListProps = {
  npa: NPA;
  okpdCode: string;
  okpdName: string;
};

const keysList = [NPAType.NATIONAL, NPAType.PREF, NPAType.OTHER];

export const OkpdKtruDetailNPAList = memo(({ npa, okpdName, okpdCode }: OkpdDetailNPAListProps) => {
  useEffect(() => {
    sendEventGTM(eventsGTM.npaTabView, { value: okpdCode });
  }, [okpdCode]);

  return (
    <Flex gap={16}>
      {keysList.map((key) => (
        <OkpdKtruDetailNPACard
          key={key}
          npa={npa[key]}
          npaType={key}
          okpdName={okpdName}
          okpdCode={okpdCode}
        />
      ))}
    </Flex>
  );
});

OkpdKtruDetailNPAList.displayName = 'OkpdKtruDetailNPAList';
