import { memo } from 'react';
import { Flex, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import styles from './NPACard.module.scss';
import { NPAItem, NPAType } from '@/api/okpdApi/okpdApiTypes';

type NPACardProps = {
  npaItems: NPAItem[];
  npaType: NPAType;
};

const NPATitlesMap = {
  [NPAType.NATIONAL]: 'Национальный режим (44-ФЗ)',
  [NPAType.PREF]: 'Преференции (44-ФЗ)',
  [NPAType.OTHER]: 'Прочее (44-ФЗ, 223-ФЗ)',
};

export const NPACard = memo(({ npaItems, npaType }: NPACardProps) => {
  return (
    <>
      <Tooltip title={NPATitlesMap[npaType]}>
        <div className={styles.title}>{NPATitlesMap[npaType]}</div>
      </Tooltip>
      <Flex
        wrap
        className={styles.chipsList}
      >
        {npaItems.map((npa, index) => (
          <div
            key={npa.code + index}
            className={cx(styles.chips, {
              [styles.applicable]: npa.applicable,
            })}
          >
            <Typography.Paragraph
              className={styles.chipsText}
              ellipsis={{ rows: 1, tooltip: npa.code }}
            >
              {npa.code}
            </Typography.Paragraph>
          </div>
        ))}
      </Flex>
    </>
  );
});

NPACard.displayName = 'NPACard';
