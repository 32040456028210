import { Key, memo, useCallback, useState } from 'react';
import { Button, Flex, Spin } from 'antd';
import ListIcon from '../../../../public/icons/list.svg';
import styles from './OkpdKtruDetailTRU.module.scss';
import {
  postOkpdKtruToTRUList,
  useGetKtruDetail,
  useGetOkpdDetail,
  useGetTruCount,
} from '@/api/okpdApi/okpdApi';
import { Badge } from '@/components';
import { isNumber } from 'lodash';
import { OkpdKtruDetailCompatibilityModal } from '@/components/OkpdKtruDetail/OkpdKtruDetailCompatibilityModal/OkpdKtruDetailCompatibilityModal';
import { getFormattedNkmi } from '@/widgets/OkpdKtru/utils';
import { useOkpdRoute } from '@/widgets/OkpdKtru/hooks/useOkpdRoute';
import { OkpdKtruModalPageParams } from '@/components/OkpdFlowModal/types';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
};

export const OkpdKtruDetailTRU = memo(({ modalPageParams }: Props) => {
  const { code, isOkpdPage, isKtruPage } = useOkpdRoute({ modalPageParams });
  const { data: okpd } = useGetOkpdDetail(isOkpdPage ? code : '');
  const { data: ktru } = useGetKtruDetail(isKtruPage ? code : '');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState<Key>();
  const { data: { count } = {}, mutate: refreshCount } = useGetTruCount(!okpd && !ktru);
  const isCountExist = isNumber(count);

  const handleAddToListButtonClick = useCallback(async () => {
    await postOkpdKtruToTRUList({
      okpdCode: okpd?.code,
      ktruCode: ktru?.code,
      nkmiId: getFormattedNkmi(ktru?.nkmis ?? []),
    });
    await refreshCount();
    setModalKey(Math.random());
  }, [ktru?.code, ktru?.nkmis, okpd?.code, refreshCount]);

  const handleShowListButtonClick = useCallback(() => setIsModalOpen(true), []);

  return (
    (okpd || ktru) && (
      <>
        <Flex
          align="center"
          gap={32}
        >
          <Button
            type="link"
            icon={<ListIcon />}
            className={styles.button}
            onClick={handleAddToListButtonClick}
          >
            <Flex
              gap={8}
              align="center"
              component="span"
              className={styles.buttonFlex}
            >
              <span>Добавить к списку</span>
              {isCountExist ? <Badge count={count} /> : <Spin size="small" />}
            </Flex>
          </Button>
          <Button
            type="link"
            className={styles.button}
            disabled={!count}
            onClick={handleShowListButtonClick}
          >
            Показать список
          </Button>
        </Flex>
        <OkpdKtruDetailCompatibilityModal
          modalKey={modalKey}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          refreshCount={refreshCount}
        />
      </>
    )
  );
});

OkpdKtruDetailTRU.displayName = 'OkpdKtruDetailTRU';
