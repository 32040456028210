import { memo, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Checkbox, CheckboxProps, Flex, Spin } from 'antd';
import cx from 'classnames';
import styles from './CoeffCheckbox.module.scss';
import { NmckGuardPost } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type Props = {
  post: NmckGuardPost;
  postValueKey: keyof Pick<NmckGuardPost, 'ud1' | 'ud2' | 'ud3' | 'ud4' | 'ud5'>;
  onChange: (checked: boolean) => Promise<void>;
} & Omit<CheckboxProps, 'onChange' | 'checked'> &
  PropsWithChildren;

export const CoeffCheckbox = memo(({ post, postValueKey, children, onChange }: Props) => {
  const [checked, setChecked] = useState(post[postValueKey]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckedChange = useCallback(
    async ({ target: { checked } }: CheckboxChangeEvent) => {
      try {
        setIsLoading(true);
        setChecked(checked);
        await onChange(checked);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setChecked(post[postValueKey]);
  }, [post, postValueKey]);

  return (
    <Flex
      align="center"
      gap={12}
    >
      <Checkbox
        checked={checked}
        disabled={isLoading}
        className={cx({ [styles.checked]: checked })}
        onChange={handleCheckedChange}
      >
        {children}
      </Checkbox>
      {isLoading && <Spin size="small" />}
    </Flex>
  );
});

CoeffCheckbox.displayName = 'CoeffCheckbox';
