import useSWR from 'swr';
import { deleteData, downloadXlsx, fetcher, getData, postData } from '@/api/commonApi/commonApi';
import {
  CharacteristicForFilter,
  KtruResponse,
  Tru,
  TruCountResponse,
} from '@/api/okpdApi/okpdApiTypes';
import {
  AllNpa,
  KtruDetail,
  OkpdDetail,
  OkpdInitialList,
  OkpdResponse,
  SuppliersResponse,
} from './okpdApiTypes';
import { generateOkpdKtruRequestUrl, generateUrl } from '@/widgets/OkpdKtru/utils';
import { formatDate } from '@/utils';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types';
import { SuppliersFilter } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { NmckCompatibilityByIdResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { CompatibilityUpdateBody } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import useSWRImmutable from 'swr/immutable';
import useSWRInfinite from 'swr/infinite';
import { getSWRInfinitePaginationToken } from '@/utils/getSWRInfinitePaginationToken';
import { DEFAULT_OKPD_KTRU_PAGINATION_COUNT } from '@/widgets/OkpdKtru/constants';

export const useGetOkpdInitialList = () => {
  return useSWR('/okpd-list', getData<OkpdInitialList>, {
    revalidateOnFocus: false,
  });
};

export const useGetAllNpa = () => {
  return useSWR('/all-npa', getData<AllNpa>, {
    revalidateOnFocus: false,
  });
};

export const useGetKtruCharacteristics = ({
  okpdCode,
  searchString,
}: {
  okpdCode?: string;
  searchString?: string;
}) => {
  return useSWR(
    () =>
      okpdCode || searchString
        ? generateUrl('/ktru-characteristics', {
            okpdCode,
            searchstring: searchString,
          })
        : null,
    getData<CharacteristicForFilter[]>,
    {
      revalidateOnFocus: false,
    },
  );
};
export const useGetSuppliers = (
  filter: SuppliersFilter,
  pagination: TokenBasedPaginationStateWithPageSize,
) => {
  const params = new URLSearchParams();

  if (pagination.token) params.append('token', pagination.token);
  params.append('pageSize', String(pagination.pageSize));
  if (filter.okpdCode) params.append('okpdCode', filter.okpdCode);
  if (filter.ktruCode) params.append('ktruCode', filter.ktruCode);
  params.append('smp', String(filter.smp));
  params.append('prom', String(filter.prom));
  params.append('hasPenalty', String(filter.hasPenalty));
  if (filter.contractDateFrom)
    params.append('contractDateFrom', formatDate(filter.contractDateFrom, 'YYYY-MM-YY'));
  if (filter.contractDateTo)
    params.append('contractDateTo', formatDate(filter.contractDateTo, 'YYYY-MM-YY'));
  filter.region.forEach((item) => {
    params.append('regions', String(item));
  });

  return useSWR(`/suppliers?${params}`, getData<SuppliersResponse>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetOkpdList = (filter: OkpdKtruListFilter) => {
  return useSWRImmutable(generateOkpdKtruRequestUrl('/okpd', { filter }), getData<OkpdResponse>);
};

export const useGetOkpdListInfinite = (filter: OkpdKtruListFilter) => {
  return useSWRInfinite<OkpdResponse>(
    (index, previousPageData: OkpdResponse) => {
      const token = getSWRInfinitePaginationToken<OkpdResponse>(index, previousPageData);
      return generateOkpdKtruRequestUrl('/okpd', {
        filter,
        pagination: { token, count: DEFAULT_OKPD_KTRU_PAGINATION_COUNT },
      });
    },
    getData<OkpdResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
    },
  );
};

export const useGetKtruListInfinite = (filter: OkpdKtruListFilter) => {
  return useSWRInfinite<KtruResponse>(
    (index, previousPageData: KtruResponse) => {
      const token = getSWRInfinitePaginationToken<KtruResponse>(index, previousPageData);
      return generateOkpdKtruRequestUrl('/ktru', {
        filter,
        pagination: { token, count: DEFAULT_OKPD_KTRU_PAGINATION_COUNT },
      });
    },
    getData<KtruResponse>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: false,
    },
  );
};

export const useGetOkpdDetail = (code: string) => {
  return useSWRImmutable(code ? `/okpd/${code}` : null, getData<OkpdDetail>);
};

export const useGetKtruDetail = (code: string) => {
  return useSWRImmutable(code ? `/ktru/${code}` : null, getData<KtruDetail>);
};

export const useGetTruCount = (preventFetch?: boolean) => {
  return useSWRImmutable(preventFetch ? null : '/tru-list/count', getData<TruCountResponse>);
};

export const useGetTruList = ({ searchString }: { searchString?: string }) => {
  const params = new URLSearchParams();
  searchString && params.append('searchstring', searchString);

  const url = searchString ? `/nmck-tru?${params}` : null;

  return useSWR(url, getData<Tru[]>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const useGetTruCompatibility = (
  omitEmptyColumns: boolean = false,
  nationalProject: boolean = false,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  return useSWR(`/tru-list?${params}`, getData<NmckCompatibilityByIdResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};

export const postOkpdKtruToTRUList = (
  body: Partial<{
    okpdCode: string;
    ktruCode: string;
    nkmiId: NmckRow['nkmiId'];
  }>,
) => postData('/tru-list', body);

export const createCalculationFromTru = (nationalProject: boolean) => {
  const params = new URLSearchParams();
  params.append('nationalProject', nationalProject.toString());

  return postData<number[]>(`/tru-list/nmck?${params}`);
};

export const updateTruCompatibilityCell = (
  _: unknown,
  rowId: number,
  body: CompatibilityUpdateBody,
) => postData<void>(`/tru-list/${rowId}/compatibility-user-selection`, body);

export const getTruCompatibilityXlsx = async (
  omitEmptyColumns: boolean,
  nationalProject: boolean,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  const response = await fetcher(`/tru-list/xlsx?${params}`);
  await downloadXlsx(response, 'Список позиций ОКПД2/КТРУ');
};

export const deleteTruCompatibilityList = () => deleteData('/tru-list');

export const getOkpdXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/okpd/${code}/xlsx`);
  await downloadXlsx(response, fileName);
};

export const getKtruXlsx = async (code: string, fileName: string) => {
  const response = await fetcher(`/ktru/${code}/xlsx`);
  await downloadXlsx(response, fileName);
};
