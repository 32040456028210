import { ConfigProvider, Flex, Tooltip } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { TableProps } from 'antd/lib';
import { Columns, TotalRow } from '../../data';
import { ColumnType } from 'antd/es/table';
import { formatNumber } from '@/utils/formatNumber';
import {
  DirectContract,
  DirectContractCause,
  DirectContractCauses,
  DirectContractsResponse,
} from '@/api/reestrDirectApi/reestrDirectApiTypes';
import {
  createNewDirectContract,
  deleteDirectContract,
  updateDirectContract,
} from '@/api/reestrDirectApi/reestrDirectApi';
import { DebounceInput, DirectContractEditModal } from '@/components';
import Copy from '/public/icons/copy.svg';
import Edit from '/public/icons/edit.svg';
import Trash from '/public/icons/trash.svg';
import { formatDate } from '@/utils';
import styles from './ReestrDirectPageResultsTable.module.scss';
import colors from '@/styles/colors.module.scss';
import { omit } from 'lodash';
import { AutoScrollTable } from '@/components/Ui/AutoScrollTable/AutoScrollTable';

const theme = {
  components: {
    Table: {
      rowHoverBg: colors.light_blue_2,
    },
  },
};

const isSummary = (name: string | undefined) => name?.startsWith('Итого');

const renderTooltip = (text: string, className: string, name?: string) =>
  !isSummary(name) && (
    <Tooltip
      title={text}
      arrow={false}
      placement="bottom"
    >
      <div className={className}>{text}</div>
    </Tooltip>
  );

const renderFormattedNumber = (value: number | undefined, className: string) => (
  <Flex
    className={className}
    justify="end"
  >
    {formatNumber(value ? value : 0)}
  </Flex>
);

const renderFormattedDate = (date: string, name: string, className: string) =>
  !isSummary(name) && <div className={className}>{formatDate(date)}</div>;

const resultsTableColumnsWithRender = (
  selectedColumns: TableProps<DirectContract>['columns'],
  handleCommentChange: (value: string, record: DirectContract) => void,
  openEditModal: (record: DirectContract) => () => void,
  causes: DirectContractCause[] | undefined,
  copyContract: (record: DirectContract) => () => void,
  deleteContract: (id: number) => () => void,
): TableProps<DirectContract>['columns'] => {
  return selectedColumns?.map((column: ColumnType<DirectContract>) => {
    switch (column.dataIndex) {
      case Columns.ITEM_NAME:
        return {
          ...column,
          render: (_: unknown, { itemName }: DirectContract) =>
            renderTooltip(itemName, styles.itemName),
        };
      case Columns.CONCLUSION_DATE:
        return {
          ...column,
          render: (_: unknown, { conclusionDate, itemName }: DirectContract) =>
            renderFormattedDate(conclusionDate, itemName, styles.conclusionDate),
        };
      case Columns.EXEC_DATE:
        return {
          ...column,
          render: (_: unknown, { executionDate, itemName }: DirectContract) =>
            renderFormattedDate(executionDate, itemName, styles.execDate),
        };
      case Columns.SUPPLIER:
        return {
          ...column,
          render: (_: unknown, { supplierName }: DirectContract) =>
            renderTooltip(supplierName, styles.supplier),
        };
      case Columns.CONTRACT_NUMBER:
        return {
          ...column,
          render: (_: unknown, { contractNumber }: DirectContract) => (
            <div className={styles.contractNumber}>{contractNumber}</div>
          ),
        };
      case Columns.PRICE:
        return {
          ...column,
          align: 'end',
          render: (_: unknown, { packagePrice }: DirectContract) =>
            renderFormattedNumber(packagePrice, styles.packagePrice),
        };
      case Columns.KBK:
        return {
          ...column,
          render: (_: unknown, { kbk }: DirectContract) => <div className={styles.kbk}> {kbk}</div>,
        };
      case Columns.CAUSE:
        return {
          ...column,
          render: (_: unknown, { cause, itemName }: DirectContract) => {
            const causeItem = causes?.find((causeItem) => causeItem.id === cause);
            const causeText = causeItem ? causeItem.cause : '—';
            return renderTooltip(causeText, styles.cause, itemName);
          },
        };
      case Columns.COMMENT:
        return {
          ...column,
          render: (_: unknown, record: DirectContract) => {
            if (!isSummary(record.itemName)) {
              return (
                <div className={styles.comment}>
                  <Tooltip
                    title={record.comment}
                    arrow={false}
                    placement="bottom"
                  >
                    <DebounceInput
                      value={record.comment}
                      onDebouncedChange={(value) => handleCommentChange(value, record)}
                      placeholder="Не указано"
                      variant="borderless"
                      className={styles.commentInput}
                    />
                  </Tooltip>
                </div>
              );
            }
            return null;
          },
        };
      case Columns.COPY:
        return {
          ...column,
          render: (_: unknown, record: DirectContract) => {
            if (!isSummary(record.itemName))
              return (
                <div
                  className={styles.copy}
                  onClick={copyContract(record)}
                >
                  <Copy />
                </div>
              );
          },
        };
      case Columns.EDIT:
        return {
          ...column,
          render: (_: unknown, record: DirectContract) => {
            if (!isSummary(record.itemName))
              return (
                <div
                  className={styles.edit}
                  onClick={openEditModal(record)}
                >
                  <Edit />
                </div>
              );
          },
        };
      case Columns.DELETE:
        return {
          ...column,
          render: (_: unknown, record: DirectContract) => {
            if (!isSummary(record.itemName))
              return (
                <div
                  className={styles.delete}
                  onClick={deleteContract(record.id)}
                >
                  <Trash />
                </div>
              );
          },
        };

      default:
        return column;
    }
  });
};

const calculateTotals = (contracts: DirectContractsResponse): { packagePrice: number } => {
  return {
    packagePrice: contracts.sumContractPrice,
  };
};

type ReestrDirectPageResultsTableProps = {
  contracts: DirectContractsResponse | undefined;
  isContractsLoading: boolean;
  selectedColumns: TableProps<DirectContract>['columns'];
  refreshContracts: () => void;
  causes: DirectContractCauses | undefined;
  isCausesLoading: boolean;
};

export const ReestrDirectPageResultsTable = memo(
  ({
    contracts,
    isContractsLoading,
    selectedColumns,
    refreshContracts,
    causes,
    isCausesLoading,
  }: ReestrDirectPageResultsTableProps) => {
    const [isOpenEditModal, setOpenEditModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<DirectContract | null>(null);

    const handleCommentChange = useCallback(
      async (value: string, record: DirectContract) => {
        const dataToSend = omit(record, ['priceForCalculation', 'id']);
        await updateDirectContract(record.id, {
          ...dataToSend,
          comment: value,
        });
        refreshContracts();
      },
      [refreshContracts],
    );

    const openEditModal = useCallback(
      (record: DirectContract) => () => {
        setSelectedRecord(record);
        setOpenEditModal(true);
      },
      [],
    );
    const closeEditModal = useCallback(() => {
      setSelectedRecord(null);
      setOpenEditModal(false);
    }, []);

    const handleRowDoubleClick = useCallback(
      (record: DirectContract) => {
        if (!isSummary(record.itemName)) {
          openEditModal(record)();
        }
      },
      [openEditModal],
    );

    const onRow = useCallback(
      (record: DirectContract | Partial<DirectContract>) => ({
        onDoubleClick: () => handleRowDoubleClick(record as DirectContract),
      }),
      [handleRowDoubleClick],
    );

    const copyContract = useCallback(
      (record: DirectContract) => async () => {
        const dataToSend = omit(record, ['priceForCalculation', 'id']);
        await createNewDirectContract(dataToSend);
        refreshContracts();
      },
      [refreshContracts],
    );

    const deleteContract = useCallback(
      (id: number) => async () => {
        await deleteDirectContract(String(id));
        refreshContracts();
      },
      [refreshContracts],
    );

    const resultsColumns = useMemo(() => {
      return resultsTableColumnsWithRender(
        selectedColumns,
        handleCommentChange,
        openEditModal,
        causes,
        copyContract,
        deleteContract,
      ) as ColumnType<DirectContract | TotalRow>[];
    }, [selectedColumns, handleCommentChange, openEditModal, causes, copyContract, deleteContract]);

    const dataSourceWithTotal = useMemo(() => {
      if (contracts) {
        const totals = calculateTotals(contracts);
        const totalRow: TotalRow = {
          ...totals,
          itemName: `Итого: ${contracts.count}`,
          id: Math.random(),
        };

        return [...contracts.results, totalRow];
      }
      return [];
    }, [contracts]);

    return (
      <ConfigProvider theme={theme}>
        {selectedRecord && (
          <DirectContractEditModal
            isOpenEditModal={isOpenEditModal}
            record={selectedRecord}
            closeEditModal={closeEditModal}
            refreshContracts={refreshContracts}
            causes={causes}
            isCausesLoading={isCausesLoading}
          />
        )}
        <div className={styles.wrapper}>
          <AutoScrollTable
            rowKey="id"
            className={styles.table}
            loading={isContractsLoading}
            dataSource={dataSourceWithTotal}
            columns={resultsColumns}
            pagination={false}
            rowClassName={styles.row}
            onRow={onRow}
          />
        </div>
      </ConfigProvider>
    );
  },
);
ReestrDirectPageResultsTable.displayName = 'ReestrDirectPageResultsTable';
