import { memo, useCallback } from 'react';
import { Popover } from 'antd';
import { contentPopOver } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';
import Question from '/public/icons/questionRound.svg';
import { UiSwitch } from '@/components';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { useNmckGridContractsActionsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsActionsContext';

export const ContractsTableIncreasingFactorSwitch = memo(() => {
  const { priceIncreasingFactor } = useNmckGridContractsContext();
  const { setPriceIncreasingFactor } = useNmckGridContractsActionsContext();

  const handlePriceIncreasingFactorChange = useCallback(
    () => setPriceIncreasingFactor((prev) => !prev),
    [setPriceIncreasingFactor],
  );

  return (
    <UiSwitch
      value={priceIncreasingFactor}
      onChange={handlePriceIncreasingFactorChange}
    >
      <span>Повышающий коэффициент цен</span>
      <Popover
        placement="bottom"
        arrow={false}
        content={contentPopOver}
      >
        <Question className="hint-icon" />
      </Popover>
    </UiSwitch>
  );
});

ContractsTableIncreasingFactorSwitch.displayName = 'ContractsTableIncreasingFactorSwitch';
