import { memo, useCallback } from 'react';
import Link from 'next/link';
import { Flex } from 'antd';
import styles from './GuardHeader.module.scss';
import cx from 'classnames';
import { DebounceInput } from '@/components';
import { OkpdKtruAutocomplete } from '@/widgets/NmckGuard/components/NmckGuardHeader/components/OkpdKtruAutocomplete/OkpdKtruAutocomplete';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';

export const NmckGuardHeader = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const handleNmckNameChange = useCallback(
    async (value: string) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, name: value });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  return (
    <Flex
      align="center"
      justify="space-between"
      className={styles.guardHeader}
      gap={32}
    >
      <Flex
        align="center"
        gap={32}
      >
        <div className={styles.title}>Расчёт НМЦК, охрана</div>
        <DebounceInput
          value={data?.name ?? ''}
          placeholder="Название"
          className={cx('input-line', styles.nameInput)}
          variant="borderless"
          onDebouncedChange={handleNmckNameChange}
        />
        <OkpdKtruAutocomplete />
      </Flex>
      <Flex gap={24}>
        {/*TODO: временно выпилили */}
        {/*<Link*/}
        {/*  href={'https://disk.yandex.ru/i/iGS7iBLkjFxOCA'}*/}
        {/*  target="_blank"*/}
        {/*  className={styles.link}*/}
        {/*>*/}
        {/*  Инструкция расчёта*/}
        {/*</Link>*/}
        <Link
          href={'https://base.garant.ru/400706484/'}
          target="_blank"
          className={styles.link}
        >
          Охрана (45 приказ)
        </Link>
        <Link
          href={'https://www.consultant.ru/law/ref/calendar/proizvodstvennye/2024/'}
          target="_blank"
          className={styles.link}
        >
          Производственный календарь
        </Link>
      </Flex>
    </Flex>
  );
});

NmckGuardHeader.displayName = 'NmckGuardHeader';
