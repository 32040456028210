'use client';

import { memo, useCallback, useEffect, useState } from 'react';
import VirtualList from 'rc-virtual-list';
import { Flex, List } from 'antd';
import { useGetNmcks } from '@/api/nmckApi/nmckApi';
import styles from './NmckHistory.module.scss';
import { getSplittedNmckIds, resetSplittedNmckIds } from '@/utils';
import { DebounceInput } from '@/components';
import { PageSizeSelect } from '@/components/Table/TablePagination/PageSizeSelect/PageSizeSelect';
import cx from 'classnames';
import { CalculationHistoryCard } from '@/widgets/NmckHistory/components/CalculationHistoryCard/CalculationHistoryCard';
import { NmckCommon } from '@/widgets/NmckHistory/types';

const containerHeight = 600;

export const NmckHistory = memo(() => {
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<NmckCommon[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [newNmckIds, setNewNmckIds] = useState<number[] | undefined>(undefined);

  const { data: nmcks, isLoading: isNmcksLoading } = useGetNmcks({ page, size, searchString });

  useEffect(() => {
    if (nmcks) {
      if (page === 1 || size !== -1) {
        setData(nmcks.results);
      } else {
        setData((prev) => [...prev, ...nmcks.results]);
      }
      setLoading(false);
    }
  }, [nmcks, page, size]);

  useEffect(() => {
    if (!isNmcksLoading) {
      setLoading(false);
    }
  }, [isNmcksLoading]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement, UIEvent>) => {
      if (
        Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - containerHeight) <= 1 &&
        !loading &&
        nmcks?.results.length
      ) {
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, nmcks?.results.length],
  );

  const handleLoad = useCallback(() => {
    const ids = getSplittedNmckIds();
    setNewNmckIds(ids);
    if (!newNmckIds) return;
    for (const newNmckId of newNmckIds) {
      const element = document.getElementById(`nmck-${newNmckId}`);
      if (element) {
        element.classList.add(styles.highlighted);
        setTimeout(() => {
          element.classList.remove(styles.highlighted);
        }, 1000);
      }
    }

    resetSplittedNmckIds();
  }, [newNmckIds]);

  useEffect(() => {
    if (nmcks && !isNmcksLoading) {
      handleLoad();
    }
  }, [handleLoad, isNmcksLoading, nmcks]);

  return (
    <>
      <div className={styles.header}>
        <Flex
          align="center"
          gap={32}
        >
          <div className={styles.title}>История расчётов НМЦК</div>
          <DebounceInput
            className={cx(styles.searchInput, 'input-line')}
            placeholder="Поиск расчёта"
            variant="borderless"
            onDebouncedChange={handleSearchChange}
          />
        </Flex>
      </div>
      <div className={styles.results}>
        <Flex
          align="center"
          gap={16}
          className={styles.listHeader}
        >
          <div className={styles.columnDate}>Дата</div>
          <div className={styles.columnName}>Название расчёта</div>
          <div className={styles.columnMethod}>Метод закупки</div>
          <div className={styles.columnPrice}>Итого</div>
          <PageSizeSelect
            pageSize={size}
            onPageSizeChange={setSize}
            className={styles.columnActions}
          />
        </Flex>

        <List
          loading={isNmcksLoading}
          className={styles.resultItems}
        >
          <VirtualList
            data={data}
            itemKey="id"
            onScroll={onScroll}
            height={size === -1 ? containerHeight : undefined}
          >
            {(nmck: NmckCommon) => (
              <CalculationHistoryCard
                nmck={nmck}
                setNmckList={setData}
              />
            )}
          </VirtualList>
        </List>
      </div>
    </>
  );
});

NmckHistory.displayName = 'NmckHistory';
