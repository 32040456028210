import { memo } from 'react';
import { Tooltip } from 'antd';
import { tooltipOverlayInnerStyleForShortTitle } from '@/constants/ui';
import WarningNotFilledIcon from 'public/icons/warningNotFilled.svg';
import styles from './NmckGridPropositionWarning.module.scss';
import { Proposition } from '@/api/nmckApi/nmckApiTypes';

type Props = {
  proposition: Proposition;
};

export const NmckGridPropositionWarning = memo(({ proposition }: Props) => {
  return (
    proposition.warning && (
      <Tooltip
        title={proposition.warning}
        overlayInnerStyle={tooltipOverlayInnerStyleForShortTitle}
      >
        <WarningNotFilledIcon className={styles.warning} />
      </Tooltip>
    )
  );
});

NmckGridPropositionWarning.displayName = 'NmckGridPropositionWarning';
