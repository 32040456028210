'use client';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Flex, FlexProps } from 'antd';
import PlusIcon from '/public/icons/plus.svg';
import CrossIcon from '/public/icons/cross.svg';
import styles from './CharacteristicsFilter.module.scss';
import { CharacteristicItemType, CharacteristicsParam, Option } from '@/types/types';
import { differenceBy, isEqual } from 'lodash';
import { CharacteristicsFilterItem } from '@/widgets/CharacteristicsFilter/components/CharacteristicsFilterItem/CharacteristicsFilterItem';
import { CharacteristicForFilter } from '@/api/okpdApi/okpdApiTypes';
import { getItemsMapByCharsListResponse } from '@/widgets/CharacteristicsFilter/utils';

export type CharacteristicsFilterProps = {
  list: CharacteristicForFilter[];
  isListLoading: boolean;
  characteristicsFilter: CharacteristicsParam[];

  titleText?: string;
  addButtonPosition?: 'header' | 'underList';
  headerJustify?: FlexProps['justify'];

  selectClassName?: string;

  onCharacteristicsChange: (newCharacteristics: CharacteristicsParam[]) => void;
  onReset?: () => void;
};

export const CharacteristicsFilter = memo(
  ({
    list,
    isListLoading,
    characteristicsFilter,
    headerJustify,
    titleText = 'Характеристики',
    addButtonPosition = 'header',
    selectClassName,
    onCharacteristicsChange,
    onReset,
  }: CharacteristicsFilterProps) => {
    const idCounter = useRef<number>(1);
    const [nameOptions, setNameOptions] = useState<Option[]>([]);
    const [valueOptionsMap, setValueOptionsMap] = useState<Record<string, Option[]>>({});
    const [items, setItems] = useState<CharacteristicItemType[]>([{ id: idCounter.current }]);

    const isAddDisabled = useMemo(() => {
      const lastItem = items.at(-1);
      return !lastItem?.name;
    }, [items]);
    const availableNameOptions: Option[] = useMemo(() => {
      const selectedNameOptions = items.map(({ name }) => name).filter(Boolean) as Option[];

      return differenceBy(nameOptions, selectedNameOptions, 'label');
    }, [items, nameOptions]);

    useEffect(() => {
      setNameOptions(
        list.map(
          ({ name, code }): Option => ({
            value: code,
            label: name,
          }),
        ),
      );

      setValueOptionsMap(getItemsMapByCharsListResponse(list));
    }, [list]);

    useEffect(() => {
      const newChars = items
        .filter(({ name, value }) => name && value)
        .map(({ name, value }) => ({
          name: name!.value,
          value: value!.value,
        }));

      if (!isEqual(newChars, characteristicsFilter)) {
        onCharacteristicsChange(
          items
            .filter(({ name, value }) => name && value)
            .map(({ name, value }) => ({
              name: name!.value,
              value: value!.value,
            })),
        );
      }
    }, [characteristicsFilter, items, onCharacteristicsChange]);

    const handleItemChange = useCallback((item: CharacteristicItemType) => {
      setItems((prev) => prev.map((prevItem) => (item.id === prevItem.id ? item : prevItem)));
    }, []);

    const handleAddItem = useCallback(() => {
      idCounter.current++;
      setItems((prev) => [...prev, { id: idCounter.current }]);
    }, []);

    const handleDeleteItem = useCallback(
      (item: CharacteristicItemType) => {
        if (items.length < 2) {
          idCounter.current = 1;
          setItems([{ id: idCounter.current }]);
        } else {
          setItems((prev) => prev.filter(({ id }) => id !== item.id));
        }
      },
      [items],
    );

    const handleResetAll = useCallback(() => {
      idCounter.current = 1;
      setItems([{ id: idCounter.current }]);
      onReset && onReset();
    }, [onReset]);

    return (
      <Flex
        vertical
        gap={24}
      >
        <Flex
          align="center"
          gap={32}
          justify={headerJustify}
        >
          <div className={styles.title}>{titleText}</div>
          {addButtonPosition === 'header' && (
            <Button
              type="link"
              icon={<PlusIcon />}
              className={styles.button}
              onClick={handleAddItem}
              disabled={isAddDisabled}
            >
              Добавить характеристику
            </Button>
          )}
          <Button
            type="link"
            icon={<CrossIcon />}
            className={styles.button}
            onClick={handleResetAll}
          >
            Сбросить все
          </Button>
        </Flex>
        <Flex
          vertical
          gap={20}
        >
          {items.map((item) => (
            <CharacteristicsFilterItem
              key={item.id}
              values={valueOptionsMap}
              names={availableNameOptions}
              isOptionsLoading={isListLoading}
              item={item}
              selectClassName={selectClassName}
              onChangeItem={handleItemChange}
              onDeleteItem={handleDeleteItem}
            />
          ))}
          {addButtonPosition === 'underList' && (
            <Button
              type="link"
              icon={<PlusIcon />}
              className={styles.button}
              onClick={handleAddItem}
              disabled={isAddDisabled}
            >
              Добавить характеристику
            </Button>
          )}
        </Flex>
      </Flex>
    );
  },
);

CharacteristicsFilter.displayName = 'CharacteristicsFilter';
