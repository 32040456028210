import { memo, useMemo } from 'react';
import { ResultsList } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsList/ResultsList';
import { GuardResultItem } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/types';
import { formatNumber } from '@/utils/formatNumber';
import { useNmckGuardContext } from '@/widgets/NmckGuard/context/useNmckGuardContext';
import { useGetNmckGuard } from '@/api/nmckGuardApi/nmckGuardApi';

type Props = {
  selectedYear?: number;
};

export const ResultsDictionaryData = memo(({ selectedYear }: Props) => {
  const { nmckId } = useNmckGuardContext();
  const { data: guardData } = useGetNmckGuard(nmckId);

  const dataForSelectedYear = useMemo(() => {
    if (guardData?.generalDataByYears) {
      return guardData.generalDataByYears.find((year) => year.year === selectedYear);
    }
  }, [guardData, selectedYear]);

  const list: GuardResultItem[] = useMemo(
    () =>
      guardData && dataForSelectedYear
        ? [
            {
              label: 'Код ОКПД2/КТРУ',
              value: guardData.okpdCode || '—',
            },
            {
              label: 'Единица измерения',
              value: 'чел. ч',
            },
            {
              label: 'Среднемесячная норма рабочего времени (СНР), ч',
              value: formatNumber(dataForSelectedYear.snr),
            },
            {
              label: 'Ставка страховых взносов(Y), %',
              value: formatNumber(dataForSelectedYear.ssvCoeff),
            },
            {
              label: 'Норма прибыли, %',
              value: formatNumber(dataForSelectedYear.npCoeff),
            },
            {
              label: 'Ставка НДС, %',
              value: formatNumber(dataForSelectedYear.ndsCoeff),
            },
            {
              label: 'Коэфф. инфляции (I инфл)',
              value: formatNumber(dataForSelectedYear.inflationCoeff),
            },
          ]
        : [],
    [dataForSelectedYear, guardData],
  );

  return (
    <ResultsList
      title="Справочные данные"
      list={list}
    />
  );
});

ResultsDictionaryData.displayName = 'ResultsDictionaryData';
