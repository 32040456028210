import { memo, useCallback, useState } from 'react';
import { Popover } from 'antd';
import Question from 'public/icons/questionRound.svg';
import { UiSwitch } from '@/components';
import { eventsGTM, sendEventGTM } from '@/metrika';

export const NmckGridHeaderUnderminedAmountSwitch = memo(() => {
  const [undeterminedAmount, setUndeterminedAmount] = useState(false);

  const handleChangeUndeterminedAmount = useCallback((value: boolean) => {
    setUndeterminedAmount(value);
    sendEventGTM(eventsGTM.nmckChangeUndeterminedAmount, { value });
  }, []);

  return (
    <UiSwitch
      value={undeterminedAmount}
      onChange={handleChangeUndeterminedAmount}
    >
      <div>Неопределённое кол-во ТРУ</div>
      <Popover
        placement="bottom"
        arrow={false}
        content="Расчёт НМЦК для закупок с неопределенным объемом согласно ч. 24 ст. 22 44-ФЗ"
      >
        <Question className="hint-icon" />
      </Popover>
    </UiSwitch>
  );
});

NmckGridHeaderUnderminedAmountSwitch.displayName = 'NmckGridHeaderUnderminedAmountSwitch';
