'use client';

import { memo, PropsWithChildren } from 'react';
import styles from './OkpdLayout.module.scss';
import { Flex } from 'antd';
import {
  OkpdKtruModalPageParams,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { OkpdKtruDetailTRU } from '@/components/OkpdKtruDetail/OkpdKtruDetailTRU/OkpdKtruDetailTRU';
import { OkpdLayoutSearch } from '@/layouts/OkpdLayout/components/OkpdLayoutSearch/OkpdLayoutSearch';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
} & PropsWithChildren;

export const OkpdLayout = memo(({ children, modalPageParams, setModalPageParams }: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Flex gap={32}>
          <div className={styles.title}>ОКПД 2 / КТРУ</div>
          <OkpdLayoutSearch
            modalPageParams={modalPageParams}
            setModalPageParams={setModalPageParams}
          />
          <OkpdKtruDetailTRU modalPageParams={modalPageParams} />
        </Flex>
      </div>
      {children}
    </>
  );
});

OkpdLayout.displayName = 'OkpdLayout';
