import { ColumnsType } from 'antd/lib/table';
import { ContractItem } from '@/api/nmckApi/nmckApiTypes';
import { Typography } from 'antd';
import styles from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable.module.scss';
import {
  getCopyableWithText,
  getParagraphEllipsis,
} from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';
import { ContractsTableCharacteristicColumn } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTableCharacteristicColumn/ContractsTableCharacteristicColumn';
import { ContractsTablePriceColumn } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTablePriceColumn/ContractsTablePriceColumn';
import Link from 'next/link';
import { preventEventPropagation } from '@/utils/preventEventPropagation';
import { formatDate } from '@/utils';

export const nmckContractsTableColumns: ColumnsType = [
  {
    key: 'name',
    title: 'Наименование',
    dataIndex: 'name',
    render: (_: unknown, { name }: ContractItem) => (
      <Typography.Paragraph
        ellipsis={getParagraphEllipsis(name)}
        className={styles.name}
      >
        <div>{name}</div>
      </Typography.Paragraph>
    ),
  },
  {
    key: 'okpd2',
    title: 'ОКПД2/КТРУ',
    dataIndex: 'okpd2',
    render: (_: unknown, record: ContractItem) => {
      const code = record.ktru?.code || record.okpd?.code;
      return <div className={styles.okpd2}>{code ?? '—'}</div>;
    },
  },
  {
    key: 'characteristics',
    title: 'Характеристики',
    dataIndex: 'characteristics',
    render: (_: unknown, record: ContractItem) => (
      <ContractsTableCharacteristicColumn contract={record} />
    ),
  },
  {
    key: 'price',
    title: 'Цена, ₽',
    dataIndex: 'price',
    align: 'right',
    render: (_: unknown, record: ContractItem) => <ContractsTablePriceColumn contract={record} />,
  },
  {
    key: 'unitMeasure',
    title: 'Ед. изм.',
    dataIndex: 'unitMeasure',
  },
  {
    key: 'nds',
    title: 'НДС, %',
    dataIndex: 'nds',
    align: 'right',
  },
  {
    key: 'count',
    title: 'Кол-во',
    dataIndex: 'count',
    align: 'right',
    className: styles.count,
  },
  {
    key: 'supplier',
    title: 'Поставщик',
    dataIndex: 'supplier',
    render: (_: unknown, record: ContractItem) =>
      record.contract.supplier?.name ? (
        <Typography.Paragraph
          ellipsis={getParagraphEllipsis(record.contract.supplier.name)}
          className={styles.supplier}
        >
          <div>{record.contract.supplier.name}</div>
        </Typography.Paragraph>
      ) : (
        '—'
      ),
  },
  {
    key: 'supplierInn',
    title: 'ИНН П',
    dataIndex: 'supplierInn',
    render: (_: unknown, record: ContractItem) => (
      <div className={styles.region}>{record.contract.supplier?.inn ?? '—'}</div>
    ),
  },
  {
    key: 'regionSupplier',
    title: 'Регион П',
    dataIndex: 'regionSupplier',
    render: (_: unknown, record: ContractItem) => (
      <div className={styles.region}>{record.contract.supplier?.region?.name ?? '—'}</div>
    ),
  },
  {
    key: 'regionConsumer',
    title: 'Регион З',
    dataIndex: 'regionConsumer',
    render: (_: unknown, record: ContractItem) => (
      <div className={styles.region}>{record.contract.consumer?.region?.name ?? '—'}</div>
    ),
  },
  {
    key: 'email',
    title: 'E-mail П',
    dataIndex: 'email',
    render: (_: unknown, record: ContractItem) => (
      <Link
        href={`mailto:${record.contract.supplier?.email}`}
        className={styles.email}
        onClick={preventEventPropagation}
      >
        {record.contract.supplier?.email}
      </Link>
    ),
  },
  {
    key: 'phone',
    title: 'Телефон П',
    dataIndex: 'phone',
    render: (_: unknown, record: ContractItem) => (
      <Link
        href={`tel:${record.contract.supplier?.phoneNumber}`}
        className={styles.phone}
        onClick={preventEventPropagation}
      >
        {record.contract.supplier?.phoneNumber}
      </Link>
    ),
  },
  {
    key: 'reestrNumber',
    title: 'Реестровый №',
    dataIndex: 'reestrNumber',
    render: (_: unknown, { contract: { reestrNumber } }: ContractItem) => (
      <Typography.Paragraph
        className={styles.copyLink}
        copyable={getCopyableWithText(reestrNumber)}
      >
        <Link
          href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${reestrNumber}`}
          target="_blank"
          className={styles.reestrNumber}
          onClick={preventEventPropagation}
        >
          {reestrNumber}
        </Link>
      </Typography.Paragraph>
    ),
  },
  {
    key: 'country',
    title: 'Страна',
    dataIndex: 'country',
    render: (_: unknown, record: ContractItem) => (
      <div className={styles.country}>{record.contract.supplier?.country || '—'}</div>
    ),
  },
  {
    key: 'conclusionDate',
    title: 'Дата заключения',
    dataIndex: 'conclusionDate',
    render: (_: unknown, record: ContractItem) => (
      <div>{formatDate(record.contract.conclusionDate)}</div>
    ),
  },
  {
    key: 'purchaseMethodName',
    title: 'Способ закупки',
    dataIndex: 'purchaseMethodName',
    width: 200,
    render: (_: unknown, record: ContractItem) =>
      record.contract.purchaseMethod?.name ? (
        <Typography.Paragraph ellipsis={getParagraphEllipsis(record.contract.purchaseMethod.name)}>
          <div>{record.contract.purchaseMethod.name}</div>
        </Typography.Paragraph>
      ) : (
        '—'
      ),
  },
  {
    key: 'smp',
    title: 'СМП',
    dataIndex: 'smp',
    render: (_: unknown, record: ContractItem) => {
      return <div className={styles.smp}> {record.smp ? 'Да' : 'Нет'}</div>;
    },
  },
];
