import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import { UiDatePicker } from '@/components';
import styles from './DatePeriodPicker.module.scss';
import { Moment } from 'moment';
import { DatePeriodPickerType, DatePeriodType } from '@/components/DatePeriodPicker/types';
import {
  getCurrentDate,
  getHalfYearAgoDate,
  getYearAgoDate,
} from '@/components/DatePeriodPicker/utils';

type Props = {
  startDate?: Moment;
  endDate?: Moment;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  onDatesChange: ({ startDate, endDate }: DatePeriodPickerType['updatedDates']) => void;
};

export const DatePeriodPicker = memo(
  ({
    startDate,
    endDate,
    onDatesChange,
    startDatePlaceholder = 'Не указан',
    endDatePlaceholder = 'Не указан',
  }: Props) => {
    const [activeConcludedPeriod, setActiveConcludedPeriod] = useState<DatePeriodType | null>(null);

    const handleStartDateChange = useCallback(
      (date: Moment) => {
        onDatesChange({ startDate: date, endDate: endDate });
        setActiveConcludedPeriod(null);
      },
      [onDatesChange, endDate],
    );

    const handleEndDateChange = useCallback(
      (date: Moment) => {
        onDatesChange({ startDate: startDate, endDate: date });
        setActiveConcludedPeriod(null);
      },
      [onDatesChange, startDate],
    );

    const handleYearClick = useCallback(() => {
      onDatesChange({
        startDate: getYearAgoDate(),
        endDate: getCurrentDate(),
      });
    }, [onDatesChange]);

    const handleHalfYearClick = useCallback(() => {
      onDatesChange({
        startDate: getHalfYearAgoDate(),
        endDate: getCurrentDate(),
      });
    }, [onDatesChange]);

    useEffect(() => {
      if (startDate && endDate) {
        const diffs = endDate.diff(startDate, 'months');
        if (diffs === 12) {
          setActiveConcludedPeriod(DatePeriodType.YEAR);
        } else if (diffs === 6) {
          setActiveConcludedPeriod(DatePeriodType.HALF_YEAR);
        } else {
          setActiveConcludedPeriod(null);
        }
      } else {
        setActiveConcludedPeriod(null);
      }
    }, [startDate, endDate]);

    return (
      <Flex
        align="center"
        gap={16}
      >
        <Flex
          align="center"
          gap={16}
        >
          <UiDatePicker
            className={styles.datePicker}
            placeholder={startDatePlaceholder}
            value={startDate}
            onChange={handleStartDateChange}
          />
          <UiDatePicker
            className={styles.datePicker}
            placeholder={endDatePlaceholder}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Flex>
        <Flex
          className={styles.buttonsWrap}
          align="center"
          wrap
        >
          <Button
            className={styles.button}
            type="link"
            size="small"
            disabled={activeConcludedPeriod === DatePeriodType.HALF_YEAR}
            onClick={handleHalfYearClick}
          >
            Полгода
          </Button>
          <Button
            className={styles.button}
            type="link"
            size="small"
            disabled={activeConcludedPeriod === DatePeriodType.YEAR}
            onClick={handleYearClick}
          >
            Год
          </Button>
        </Flex>
      </Flex>
    );
  },
);

DatePeriodPicker.displayName = 'DatePeriodPicker';
