import { memo, useCallback } from 'react';
import styles from './AdditionalServices.module.scss';
import { Flex, Tooltip } from 'antd';
import Question from 'public/icons/questionRound.svg';
import { DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { inputNumberControls } from '@/constants/ui';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';

export const AdditionalServices = memo(() => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();

  const handleStsoChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, stso: value ?? 0 });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  const handleSzjChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data) {
        await updateNmckGuardWithFallback({ ...data, szj: value ?? 0 });
      }
    },
    [data, updateNmckGuardWithFallback],
  );

  return (
    <Flex
      vertical
      gap={24}
    >
      <div className={styles.title}>Дополнительные услуги</div>

      <Flex
        gap={16}
        align="center"
      >
        <Flex
          align="center"
          gap={16}
        >
          <div className={styles.label}>СТСО, ₽:</div>
          <DebounceInputNumber
            value={data?.stso}
            className={styles.input}
            min={0}
            controls={inputNumberControls}
            onDebouncedChange={handleStsoChange}
          />
          <Tooltip
            arrow={false}
            title="Осуществление работ по проектированию, монтажу и эксплуатационному обслуживанию технических средств охраны. Указывается без НДС."
            placement="bottom"
          >
            <Question className="hint-icon" />
          </Tooltip>
        </Flex>

        <div className={styles.divider}></div>
        <Flex
          align="center"
          gap={16}
        >
          <div className={styles.label}>СЗЖ, ₽:</div>
          <DebounceInputNumber
            value={data?.szj}
            className={styles.input}
            min={0}
            controls={inputNumberControls}
            onDebouncedChange={handleSzjChange}
          />
          <Tooltip
            arrow={false}
            title="Услуги по защите жизни и здоровья граждан. Указывается без НДС."
            placement="bottom"
          >
            <Question className="hint-icon" />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
});

AdditionalServices.displayName = 'AdditionalServices';
