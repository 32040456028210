import { memo, ReactNode, useCallback, useState } from 'react';
import { GuardResultItem } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/types';
import styles from './ResultsList.module.scss';
import { Flex } from 'antd';
import VIcon from 'public/icons/v.svg';
import cx from 'classnames';

type Props = {
  title?: string | ReactNode;
  list: GuardResultItem[];
  isChildren?: boolean;
};

export const ResultsList = memo(({ list, title, isChildren = false }: Props) => {
  const isListExist = list.length;
  const [isChildrenOpen, setIsChildrenOpen] = useState(false);

  const handleToggleChildrenVisible = useCallback(() => setIsChildrenOpen((prev) => !prev), []);

  return (
    <Flex
      vertical
      gap={4}
      className={cx({
        [styles.resultsChildren]: isChildren,
      })}
    >
      {title && (
        <div
          className={cx(styles.title, {
            [styles.titleWithList]: isListExist,
          })}
        >
          {title}
        </div>
      )}

      {list.map(({ label, value, children }) => (
        <Flex
          key={label + value}
          vertical
          gap={24}
        >
          <div
            className={cx(styles.item, {
              [styles.itemParentWithChildren]: !!children?.length,
              [styles.itemChildren]: isChildren,
            })}
          >
            <div className={styles.itemLabel}>{label}:</div>
            <Flex
              className={styles.itemValue}
              align="center"
              gap={8}
            >
              <span>{value}</span>
              {!!children?.length && (
                <VIcon
                  className={cx('v-icon', styles.vIcon, {
                    [styles.vIconRotate]: isChildrenOpen,
                  })}
                  onClick={handleToggleChildrenVisible}
                />
              )}
            </Flex>
          </div>

          {children && isChildrenOpen && (
            <ResultsList
              list={children}
              isChildren
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
});

ResultsList.displayName = 'ResultsList';
