import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  NmckContractsFilter,
  NmckContractsRequestParams,
  NmckContractsSortType,
} from '@/components/NmckGrid/NmckGridContracts/types';
import { getInitialContractsFilterState } from '@/components/NmckGrid/NmckGridContracts/constants';
import { getRegionsFromStorage } from '@/components/NmckGrid/NmckGridContracts/helpers';
import { getCurrentDate, getYearAgoDate } from '@/components/DatePeriodPicker/utils';
import { DEFAULT_PAGE_SIZE } from '@/components/Table/TablePagination/constants';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { PageSizeChangeCallbackType } from '@/components/Table/TablePagination/types';

type NmckGridContractsContextType = {
  filter: NmckContractsFilter;
  pageSize: number;
  sort: NmckContractsSortType;
  priceIncreasingFactor: boolean;
  requestFilter: NmckContractsRequestParams;
};
type NmckGridContractsActionsContextType = {
  setFilter: Dispatch<SetStateAction<NmckContractsFilter>>;
  setPriceIncreasingFactor: Dispatch<SetStateAction<boolean>>;
  changePageSize: PageSizeChangeCallbackType;
  changeSort: (type: NmckContractsSortType) => void;
};

export const NmckGridContractsContext = createContext<NmckGridContractsContextType | null>(null);
export const NmckGridContractsActionsContext =
  createContext<NmckGridContractsActionsContextType | null>(null);

type NmckGridContractsProviderProps = {
  row: NmckRow;
} & PropsWithChildren;

export const NmckGridContractsProvider = ({ row, children }: NmckGridContractsProviderProps) => {
  const [filter, setFilter] = useState<NmckContractsFilter>({
    ...getInitialContractsFilterState(),
    regionIds: getRegionsFromStorage(),
    executionDateStart: getYearAgoDate(),
    executionDateEnd: getCurrentDate(),
  });
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sort, setSort] = useState(() =>
    row.name ? NmckContractsSortType.RELEVANCE : NmckContractsSortType.PRICE_ASC,
  );
  const [priceIncreasingFactor, setPriceIncreasingFactor] = useState(false);

  const requestFilter = useMemo<NmckContractsRequestParams>(
    () => ({
      filter,
      pageSize,
      rowInfo: { name: row.name, code: row.code },
      sort,
    }),
    [filter, pageSize, row.code, row.name, sort],
  );

  const changePageSize = useCallback((size: number) => setPageSize(size), []);
  const changeSort = useCallback((type: NmckContractsSortType) => setSort(type), []);

  const values = useMemo<NmckGridContractsContextType>(
    () => ({
      filter,
      pageSize,
      sort,
      priceIncreasingFactor,
      requestFilter,
    }),
    [filter, pageSize, priceIncreasingFactor, requestFilter, sort],
  );
  const actions = useMemo<NmckGridContractsActionsContextType>(
    () => ({
      setFilter,
      changePageSize,
      setPriceIncreasingFactor,
      changeSort,
    }),
    [changePageSize, changeSort],
  );

  return (
    <NmckGridContractsContext.Provider value={values}>
      <NmckGridContractsActionsContext.Provider value={actions}>
        {children}
      </NmckGridContractsActionsContext.Provider>
    </NmckGridContractsContext.Provider>
  );
};
