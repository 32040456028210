export type NmckGuardCreateResponse = {
  id: number;
};

export enum NmckGuardWorkingDaysEnum {
  CALENDAR_DAYS = 'CALENDAR_DAYS',
  WORKDAYS = 'WORKDAYS',
  EXTENDED_WORKDAYS = 'EXTENDED_WORKDAYS',
  HOLIDAYS = 'HOLIDAYS',
}

export type NmckGuardPost = {
  name: string;
  guardWorkingDays: NmckGuardWorkingDaysEnum;
  hoursPerDay: number;
  nightHours: number;
  postCount: number;
  ud1: boolean;
  ud2: boolean;
  ud3: boolean;
  ud4: boolean;
  ud5: boolean;
};

export type NmckGuardRegionalHolidays = {
  year: number;
  holidaysAmount: number;
};

export type NmckGuardUpdateBody = Pick<
  NmckGuardByIdResponse,
  'name' | 'startDate' | 'endDate' | 'okpdCode' | 'mrot' | 'stso' | 'szj'
> & {
  regionalCoefficient: number; // NmckGuard.mrotCoeff
  localHolidays: number; // не используется на фронте
  posts: NmckGuardPost[];
  regionalHolidays: NmckGuardRegionalHolidays[];
};

export type NmckGuardByIdResponse = {
  id: number;
  generalDataByYears: NmckGuardGeneralDataByYear[];
  resultsForEachPost: NmckGuardResultsForEachPost[];
  okpdCode: string | null;
  name: string | null;
  mrot: number;
  mrotCoeff: number;
  totalPrice: number | null;
  stso: number;
  szj: number;
  startDate: string | null;
  endDate: string | null;
  nmckGuardRawRequest: NmckGuardUpdateBody; // Костыль перед релизом 29.01.2025
};

export type NmckGuardGeneralDataByYear = {
  year: number;
  regionalHolidaysAmount: number;
  snr: number;
  ssvCoeff: number;
  npCoeff: number;
  ndsCoeff: number;
  inflationCoeff: number;
};

export type NmckGuardResultsForEachPost = {
  name: string;
  postCount: number;
  workingDays: NmckGuardWorkingDaysEnum;
  federalNonWorkingDays: number;
  regionalNonWorkingDays: number;
  nonWorkingDaysCount: number;
  workingDaysCount: number;
  holidaysWorkingHours: number;
  daytimeHours: number;
  nighttimeHours: number;
  ku: number;
  u: number;
  u6: number;
  ud1: number;
  ud2: number;
  ud3: number;
  ud4: number;
  ud5: number;
  postDataByYears: NmckGuardPostDataByYear[];
};

export type NmckGuardPostDataByYear = {
  year: number;
  bzp: number;
  dn: number;
  drk: number;
  dvp: number;
  ro: number;
  sv: number;
};
