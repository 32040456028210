import { memo, useCallback, useMemo } from 'react';
import { Flex, Select } from 'antd';
import VIcon from 'public/icons/v.svg';
import { graphicOptions } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/constants';
import styles from './GuardPostForm.module.scss';
import { DebounceInput, DebounceInputNumber, DebounceInputNumberValueType } from '@/components';
import { NmckGuardWorkingDaysEnum } from '@/api/nmckGuardApi/nmckGuardApiTypes';
import { onlyNumberParser } from '@/utils/onlyNumberParser';
import { inputNumberControls } from '@/constants/ui';
import { useNmckGuardFallbackDataHandler } from '@/widgets/NmckGuard/hooks/useNmckGuardFallbackDataHandler';
import { getMappedNmckGuardWithUpdatedPost } from '@/widgets/NmckGuard/utilts';

type Props = {
  index: number;
};

const DEFAULT_POST_COUNT = 1;
const DEFAULT_HOURS_PER_DAY = 3;
const DEFAULT_NIGHT_HOURS = 0;

export const GuardPostForm = memo(({ index }: Props) => {
  const {
    nmckGuardByIdResponse: { data },
    updateNmckGuardWithFallback,
  } = useNmckGuardFallbackDataHandler();
  const post = useMemo(
    () => data?.nmckGuardRawRequest.posts[index],
    [data?.nmckGuardRawRequest.posts, index],
  );

  const handleNameChange = useCallback(
    async (value: string) => {
      if (data && post) {
        await updateNmckGuardWithFallback(
          getMappedNmckGuardWithUpdatedPost(data, index, {
            ...post,
            name: value,
          }),
        );
      }
    },
    [data, index, post, updateNmckGuardWithFallback],
  );

  const handleCountChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data && post) {
        await updateNmckGuardWithFallback(
          getMappedNmckGuardWithUpdatedPost(data, index, {
            ...post,
            postCount: value ?? DEFAULT_POST_COUNT,
          }),
        );
      }
    },
    [data, index, post, updateNmckGuardWithFallback],
  );

  const handleGuardWorkingDaysChange = useCallback(
    async (value: NmckGuardWorkingDaysEnum) => {
      if (data && post) {
        await updateNmckGuardWithFallback(
          getMappedNmckGuardWithUpdatedPost(data, index, {
            ...post,
            guardWorkingDays: value,
          }),
        );
      }
    },
    [data, index, post, updateNmckGuardWithFallback],
  );

  const handleHoursPerDayChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data && post) {
        await updateNmckGuardWithFallback(
          getMappedNmckGuardWithUpdatedPost(data, index, {
            ...post,
            hoursPerDay: value ?? DEFAULT_HOURS_PER_DAY,
          }),
        );
      }
    },
    [data, index, post, updateNmckGuardWithFallback],
  );

  const handleNightHoursChange = useCallback(
    async (value: DebounceInputNumberValueType) => {
      if (data && post) {
        await updateNmckGuardWithFallback(
          getMappedNmckGuardWithUpdatedPost(data, index, {
            ...post,
            nightHours: value ?? DEFAULT_NIGHT_HOURS,
          }),
        );
      }
    },
    [data, index, post, updateNmckGuardWithFallback],
  );

  return (
    <Flex
      gap={30}
      className={styles.wrapper}
      wrap
    >
      <Flex
        align="center"
        gap={16}
      >
        <div className={styles.label}>Название постов:</div>
        <DebounceInput
          className={styles.postNameInput}
          placeholder="Не указано"
          value={post?.name}
          onDebouncedChange={handleNameChange}
        />
      </Flex>

      <Flex
        align="center"
        gap={16}
      >
        <div className={styles.label}>Постов охраны этого типа:</div>
        <DebounceInputNumber
          value={post?.postCount}
          className={styles.inputNumber}
          min={1}
          placeholder={String(DEFAULT_POST_COUNT)}
          controls={inputNumberControls}
          parser={onlyNumberParser}
          onDebouncedChange={handleCountChange}
        />
      </Flex>

      <Flex
        align="center"
        gap={16}
      >
        <div className={styles.label}>График работы:</div>
        <Select<NmckGuardWorkingDaysEnum>
          value={post?.guardWorkingDays}
          className={styles.select}
          placeholder="Не указано"
          suffixIcon={<VIcon />}
          options={graphicOptions}
          onChange={handleGuardWorkingDaysChange}
        />
      </Flex>

      <Flex
        align="center"
        gap={16}
      >
        <div className={styles.label}>Часы работы (3—24):</div>
        <DebounceInputNumber
          value={post?.hoursPerDay}
          defaultValue={DEFAULT_HOURS_PER_DAY}
          className={styles.inputNumber}
          min={DEFAULT_HOURS_PER_DAY}
          max={24}
          placeholder={String(DEFAULT_HOURS_PER_DAY)}
          controls={inputNumberControls}
          parser={onlyNumberParser}
          onDebouncedChange={handleHoursPerDayChange}
        />
      </Flex>

      <Flex
        align="center"
        gap={16}
      >
        <div className={styles.label}>Из них ночные (0—8):</div>
        <DebounceInputNumber
          value={post?.nightHours}
          type="number"
          className={styles.inputNumber}
          min={DEFAULT_NIGHT_HOURS}
          max={8}
          placeholder={String(DEFAULT_NIGHT_HOURS)}
          controls={inputNumberControls}
          parser={onlyNumberParser}
          onDebouncedChange={handleNightHoursChange}
        />
      </Flex>
    </Flex>
  );
});

GuardPostForm.displayName = 'GuardPostForm';
