import { memo, MouseEvent, useCallback, useMemo, useState } from 'react';
import styles from './ContractsTableCharacteristicColumn.module.scss';
import { Characteristic } from '@/components/NmckGrid/NmckGridContracts/components/types';
import { Button, Flex, Typography } from 'antd';
import { getParagraphEllipsis } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/utils';
import { ContractItem } from '@/api/nmckApi/nmckApiTypes';
import { preventEventPropagation } from '@/utils/preventEventPropagation';

type Props = {
  contract: ContractItem;
};

export const ContractsTableCharacteristicColumn = memo(
  ({ contract: { characteristics } }: Props) => {
    const isToggleButtonShown = characteristics.length > 1;
    const [isAllCharsShown, setIsAllCharsShown] = useState(false);
    const visibleChars = useMemo(
      () => (isAllCharsShown ? characteristics : characteristics.slice(0, 1)),
      [characteristics, isAllCharsShown],
    );

    const handleShowToggleButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsAllCharsShown((prev) => !prev);
    }, []);

    return (
      <Flex
        vertical
        gap={5}
        className={styles.characteristics}
        onClick={preventEventPropagation}
      >
        {visibleChars.length
          ? visibleChars.map((item: Characteristic) => (
              <div key={item.contractItemId + item.value + item.name}>
                <div className={styles.characteristicName}>{item.name}:</div>
                <Typography.Paragraph
                  ellipsis={getParagraphEllipsis(item.value)}
                  className={styles.characteristicValue}
                >
                  {item.value}
                </Typography.Paragraph>
              </div>
            ))
          : '—'}
        {isToggleButtonShown && (
          <Button
            type="link"
            size="small"
            className={styles.showButton}
            onClick={handleShowToggleButtonClick}
          >
            {isAllCharsShown ? 'Скрыть' : 'Ещё...'}
          </Button>
        )}
      </Flex>
    );
  },
);

ContractsTableCharacteristicColumn.displayName = 'ContractsTableCharacteristicColumn';
