import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import { pageRoutes } from '@/app/pageRoutes';
import { OkpdKtruModalPageParams, OkpdKtruPageTypes } from '@/components/OkpdFlowModal/types';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
};

export const useOkpdRoute = ({ modalPageParams }: Props) => {
  const { code = '' } = useParams<{ code?: string }>();
  const searchParams = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const pathname = usePathname();

  const modalCode = modalPageParams?.code ?? '';
  const modalSearchString = modalPageParams?.searchString ?? '';
  const modalPage = modalPageParams?.page;

  const isOkpdPage = useMemo(
    () => (!!code && pathname.startsWith(pageRoutes.okpd2)) || modalPage === OkpdKtruPageTypes.OKPD,
    [code, modalPage, pathname],
  );
  const isKtruPage = useMemo(
    () => (!!code && pathname.startsWith(pageRoutes.ktru)) || modalPage === OkpdKtruPageTypes.KTRU,
    [code, modalPage, pathname],
  );

  return {
    code: modalCode || code,
    searchValue: modalSearchString || searchValue,

    isOkpdPage,
    isKtruPage,
  };
};
