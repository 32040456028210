import { memo, ReactNode, useCallback } from 'react';
import { Button, Flex } from 'antd';
import { TokenBasedPaginationState } from '@/api/commonApi/commonApiTypes';
import { PageSizeSelect } from '@/components/Table/TablePagination/PageSizeSelect/PageSizeSelect';
import { LoadMoreCallbackType } from '@/components/Table/TablePagination/TableTokenBasedPagination/types';
import { PageSizeChangeCallbackType } from '@/components/Table/TablePagination/types';

type Props<T extends TokenBasedPaginationState> = {
  pagination?: T;
  hasMoreResults?: boolean;
  pageSize: number;
  onLoadMoreClick: LoadMoreCallbackType;
  onPageSizeChange: PageSizeChangeCallbackType;
  isDataLoading: boolean;
};

const TableTokenBasedPaginationComponent = <T extends TokenBasedPaginationState>({
  pagination,
  hasMoreResults,
  pageSize,
  onPageSizeChange,
  onLoadMoreClick,
  isDataLoading,
}: Props<T>) => {
  const isLoadMoreButtonDisabled =
    hasMoreResults === false || pagination?.hasMoreResults === false || isDataLoading;

  const handleLoadMoreButtonClick = useCallback(
    () => onLoadMoreClick(pagination?.token),
    [onLoadMoreClick, pagination?.token],
  );

  return (
    <Flex
      gap={24}
      align="center"
    >
      <Button
        disabled={isLoadMoreButtonDisabled}
        loading={isDataLoading}
        onClick={handleLoadMoreButtonClick}
      >
        Загрузить ещё
      </Button>
      <PageSizeSelect
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
      />
    </Flex>
  );
};

export const TableTokenBasedPagination = memo(TableTokenBasedPaginationComponent) as <
  T extends TokenBasedPaginationState,
>(
  props: Props<T>,
) => ReactNode;
