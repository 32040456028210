import {
  NmckGuardByIdResponse,
  NmckGuardWorkingDaysEnum,
} from '@/api/nmckGuardApi/nmckGuardApiTypes';

export const NMCK_GUARD_BY_ID_FALLBACK: NmckGuardByIdResponse = {
  id: 0,
  startDate: null,
  endDate: null,
  mrot: 22440,
  mrotCoeff: 1,
  stso: 0,
  szj: 0,
  generalDataByYears: [],
  resultsForEachPost: [],
  okpdCode: null,
  name: null,
  totalPrice: null,
  nmckGuardRawRequest: {
    name: '',
    stso: 0,
    szj: 0,
    startDate: null,
    endDate: null,
    localHolidays: 0,
    mrot: 0,
    regionalCoefficient: 1,
    okpdCode: null,
    posts: [
      {
        name: '',
        guardWorkingDays: NmckGuardWorkingDaysEnum.CALENDAR_DAYS,
        hoursPerDay: 3,
        nightHours: 0,
        postCount: 1,
        ud1: false,
        ud2: false,
        ud3: false,
        ud4: false,
        ud5: false,
      },
    ],
    regionalHolidays: [],
  },
};
