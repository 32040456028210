import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { NMCK_MED_DEVICES_BY_ID_FALLBACK } from '@/widgets/NmckMedDevices/constants';

export const getNmckMedDevicesFallbackDataWithSearchParams = ({
  codeSearchParam,
  nameSearchParam,
  nkmiSearchParam,
  isSearchParamsExist,
}: {
  codeSearchParam: string | null;
  nameSearchParam: string | null;
  nkmiSearchParam: string | null;
  isSearchParamsExist: boolean;
}): NmckByIdResponse =>
  isSearchParamsExist
    ? {
        ...NMCK_MED_DEVICES_BY_ID_FALLBACK,
        rows: NMCK_MED_DEVICES_BY_ID_FALLBACK.rows.with(0, {
          ...NMCK_MED_DEVICES_BY_ID_FALLBACK.rows[0],
          code: codeSearchParam,
          name: nameSearchParam ?? '',
          nkmiId: nkmiSearchParam ? Number(nkmiSearchParam) : null,
        }),
      }
    : NMCK_MED_DEVICES_BY_ID_FALLBACK;
