import { TableRef } from 'antd/lib/table';
import { ScrollDirectionType } from '@/components/Ui/AutoScrollTable/AutoScrollTable.types';

export const getAntdTableContentElement = (
  tableRef: TableRef | null,
): HTMLDivElement | undefined | null => tableRef?.nativeElement.querySelector('.ant-table-content');

export const getAntdTableHeader = (
  tableRef: TableRef | null,
): HTMLTableSectionElement | undefined | null =>
  tableRef?.nativeElement.querySelector('.ant-table-thead');

export const getAntdTableFooter = (tableRef: TableRef | null): HTMLDivElement | undefined | null =>
  tableRef?.nativeElement.querySelector('.ant-table-footer');

export const isScrollZoneAvailable = (
  tableContent: ReturnType<typeof getAntdTableContentElement>,
  direction: ScrollDirectionType,
): boolean => {
  if (!tableContent) return false;

  const { scrollLeft, scrollWidth, clientWidth } = tableContent;

  if (direction === 'left') {
    return scrollLeft <= 0;
  }
  if (direction === 'right') {
    return Math.round(scrollLeft + clientWidth) >= scrollWidth;
  }

  return false;
};
