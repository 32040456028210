import { useState, useEffect, useCallback } from 'react';

const PAGE_OFFSET = 2;

const hasAlreadyStoredData = (
  array: PaginationInfoType[],
  eisId?: number,
  rank?: string,
): boolean => {
  return array.some((obj) => obj.eisId === eisId && obj.rank === rank);
};

type PaginationInfoType = {
  eisId?: number;
  rank?: string;
};

// TODO: Удалить, заюзать готовое решение useInfiniteList
export const usePagination = (itemsPerPage: number) => {
  const [paginationInfo, setPagiantionInfo] = useState<PaginationInfoType[]>([]);

  const [items, setItems] = useState(itemsPerPage && paginationInfo.length * itemsPerPage);
  const pages = paginationInfo.length;

  const reset = useCallback(() => {
    setPagiantionInfo([]);
  }, []);

  useEffect(() => {
    reset();
  }, [itemsPerPage, reset]);
  useEffect(() => {
    setItems(itemsPerPage && paginationInfo.length * itemsPerPage);
  }, [itemsPerPage, paginationInfo]);

  const savePaginationInfo = useCallback(
    (eisId?: number, rank?: string) => {
      if (hasAlreadyStoredData(paginationInfo, eisId, rank)) return;
      setPagiantionInfo((prev) => [...prev, { eisId: eisId, rank: rank }]);
    },
    [paginationInfo],
  );

  const getPageInfo = useCallback(
    (page: number) => {
      if (page === 1)
        return {
          eisId: undefined,
          rank: undefined,
        };
      return paginationInfo[page - PAGE_OFFSET];
    },
    [paginationInfo],
  );

  const getLastInfo = useCallback(() => {
    return paginationInfo.at(-1);
  }, [paginationInfo]);

  return { pages, items, getPageInfo, getLastInfo, reset, savePaginationInfo };
};
