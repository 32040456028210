import { memo } from 'react';
import styles from './ContractsTablePriceColumn.module.scss';
import { formatNumber } from '@/utils/formatNumber';
import { Flex, Popover } from 'antd';
import { ContractItem } from '@/api/nmckApi/nmckApiTypes';
import { useNmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/useNmckGridContractsContext';
import { coefficientPopOverContent } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/components/ContractsTablePriceColumn/ContractsTablePriceColumn.constants';

type Props = {
  contract: ContractItem;
};

export const ContractsTablePriceColumn = memo(
  ({
    contract: {
      priceWithAppliedKoeff,
      unitPrice,
      maxIncreasePriceRatioCoeff,
      maxIncreasePricePeriodRatioCoeff,
    },
  }: Props) => {
    const { priceIncreasingFactor } = useNmckGridContractsContext();

    return (
      <Flex
        className={styles.price}
        justify="end"
        gap={8}
      >
        {priceIncreasingFactor ? formatNumber(priceWithAppliedKoeff) : formatNumber(unitPrice)}
        {priceIncreasingFactor && (
          <Popover content={coefficientPopOverContent}>
            <Flex
              vertical
              align="flex-end"
              className={styles.coefficients}
            >
              <div className={styles.maxIncreasePriceRatioCoeff}>{maxIncreasePriceRatioCoeff}%</div>

              <div className={styles.maxIncreasePricePeriodRatioCoeff}>
                {maxIncreasePricePeriodRatioCoeff}%
              </div>
            </Flex>
          </Popover>
        )}
      </Flex>
    );
  },
);

ContractsTablePriceColumn.displayName = 'ContractsTablePriceColumn';
