'use client';

import { memo, useEffect } from 'react';
import { NmckGridHeader } from '@/components/NmckGrid/NmckGridHeader/NmckGridHeader';
import styles from './NmckAnalysisPage.module.scss';
import { pageRoutes } from '@/app/pageRoutes';
import { NmckAnalysisTable } from '@/widgets/NmckAnalysis/components/NmckAnalysisTable';
import { NmckByIdResponse } from '@/api/nmckApi/nmckApiTypes';
import { useNmckGridContext } from '@/components/NmckGrid/context/useNmckGridContext';
import { useNmckGridActionsContext } from '@/components/NmckGrid/context/useNmckGridActionsContext';

type Props = {
  nmckId: NmckByIdResponse['id'];
};

export const NmckAnalysisPageContent = memo(({ nmckId: nmckIdParam }: Props) => {
  const { nmckId } = useNmckGridContext();
  const { updateNmckId } = useNmckGridActionsContext();

  useEffect(() => {
    nmckIdParam && updateNmckId(nmckIdParam);
  }, [nmckId, nmckIdParam, updateNmckId]);

  return (
    <>
      <NmckGridHeader newCalculationLink={pageRoutes.nmckAnalysis}>
        <div className={styles.title}>Расчёт НМЦК</div>
      </NmckGridHeader>
      <NmckAnalysisTable nmckId={nmckId || nmckIdParam} />
    </>
  );
});

NmckAnalysisPageContent.displayName = 'NmckAnalysisPageContent';
