import { memo, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './OkpdLayoutSearch.module.scss';
import { InputRef } from 'antd';
import { DebounceInput } from '@/components';
import { useOkpdRoute } from '@/widgets/OkpdKtru/hooks/useOkpdRoute';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruModalPageParams,
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { trim } from 'lodash';
import { eventsGTM, sendEventGTM } from '@/metrika';

type Props = {
  modalPageParams?: OkpdKtruModalPageParams;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdLayoutSearch = memo(({ modalPageParams, setModalPageParams }: Props) => {
  const { code, searchValue } = useOkpdRoute({ modalPageParams });
  const router = useRouter();

  const inputRef = useRef<InputRef>(null);
  const [searchString, setSearchString] = useState(code || searchValue);

  const navigateToListsPage = useCallback(
    (searchString: string = '') => {
      if (setModalPageParams) {
        setModalPageParams({
          page: OkpdKtruPageTypes.LISTS,
          searchString,
          code: '',
        });
      } else {
        const params = new URLSearchParams({
          search: searchString,
        });
        router.push(`${pageRoutes.okpd2}?${params}`, { scroll: false });
      }
    },
    [router, setModalPageParams],
  );

  const onSearchChange = useCallback(
    (value: string) => {
      const trimmedValue = trim(value);
      const isOnlyWhitespaceChange = trimmedValue === trim(searchString) && value !== searchString;
      setSearchString(value);

      if (!isOnlyWhitespaceChange) {
        sendEventGTM(eventsGTM.okpdSearch, { value: trimmedValue });
        navigateToListsPage(trimmedValue);
      }
    },
    [navigateToListsPage, searchString],
  );

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'end',
    });
  }, []);

  useEffect(() => {
    if (inputRef.current?.nativeElement !== document.activeElement) {
      setSearchString(code || searchValue);
    }
  }, [code, searchValue]);

  return (
    <DebounceInput
      ref={inputRef}
      value={searchString}
      onDebouncedChange={onSearchChange}
      className={cx('input-line', styles.input)}
      placeholder="Название / код"
      variant="borderless"
      autoFocus
    />
  );
});

OkpdLayoutSearch.displayName = 'OkpdLayoutSearch';
