import { Key, memo, useCallback, useContext, useState } from 'react';

import { Button, Flex, Pagination, Select } from 'antd';
import Vicon from '/public/icons/v.svg';
import Cross from '/public/icons/cross.svg';
import { PlanningAllPageResultsTable } from './components/PlanningAllPageResultsTable';
import { resultsTableColumns } from './data/data';
import styles from './PlanningAllPageResults.module.scss';
import { PlanningAllContext } from '@/widgets/PlanningAll/context/context';
import { actions } from '@/widgets/PlanningAll/context/actions';
import {
  PlanningPurchase,
  PlanningPurchasesResponse,
} from '@/api/planningAllApi/planningAllApiTypes';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { deletePlanningPurchases } from '@/api/planningAllApi/planningAllApi';
import { PAGE_SIZE_OPTIONS } from '@/components/Table/TablePagination/constants';

const dropdownStyle: React.CSSProperties = {
  width: 'max-content',
};

type PlanningAllPageResultsProps = {
  purchases?: PlanningPurchasesResponse;
  isPurchasesLoading: boolean;
  refreshPurchases: () => void;
};

export const PlanningAllPageResults = memo(
  ({ purchases, isPurchasesLoading, refreshPurchases }: PlanningAllPageResultsProps) => {
    const { state, dispatch } = useContext(PlanningAllContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

    const handleShowOnPageSelectChange = useCallback(
      (value: number) => {
        dispatch(actions.itemsPerPageUpdate(value));
      },
      [dispatch],
    );

    const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
      useSortableTableColumns<PlanningPurchase>({
        columns: resultsTableColumns,
      });

    const pageChange = useCallback(
      (page: number) => {
        dispatch(actions.currentPageUpdate(page));
      },
      [dispatch],
    );

    const handleDeletePlanningPurchases = useCallback(async () => {
      await deletePlanningPurchases(selectedRowKeys);
      refreshPurchases();
    }, [selectedRowKeys, refreshPurchases]);

    return (
      <div className={styles.wrapper}>
        <PlanningAllPageResultsTable
          purchases={purchases}
          isPurchasesLoading={isPurchasesLoading}
          selectedColumns={orderedColumns}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <div className={styles.settings}>
          <Flex
            align="center"
            gap={64}
          >
            <Button
              icon={<Cross />}
              className={styles.deleteBtn}
              disabled={selectedRowKeys.length === 0}
              onClick={handleDeletePlanningPurchases}
            >
              Удалить
            </Button>
            {/* TODO: Использовать стандартный TableFooter !! */}
            <Flex
              align="center"
              gap={42}
            >
              <div className={styles.pagination}>
                Страницы:
                <Pagination
                  showSizeChanger={false}
                  current={state.currentPage}
                  total={purchases?.count}
                  pageSize={state.itemsPerPage}
                  onChange={pageChange}
                />
              </div>
              <div className={styles.sorting}>
                Показывать на странице:
                <Select
                  dropdownStyle={dropdownStyle}
                  title="Показывать на странице:"
                  variant="borderless"
                  suffixIcon={
                    <div className={styles.iconV}>
                      <Vicon />
                    </div>
                  }
                  value={state.itemsPerPage}
                  onChange={handleShowOnPageSelectChange}
                  options={PAGE_SIZE_OPTIONS}
                />
              </div>
              <TableColumnsSettings<PlanningPurchase>
                orderedColumnMap={orderedColumnsMap}
                setOrderedColumnMap={setOrderedColumnsMap}
                columnsData={orderedColumns}
              />
            </Flex>
          </Flex>
        </div>
      </div>
    );
  },
);

PlanningAllPageResults.displayName = 'PlanningAllPageResults';
