import { SelectProps } from 'antd';
import { NmckContractsSortType } from '@/components/NmckGrid/NmckGridContracts/types';

export const nmckContractsSortSelectOptions: SelectProps['options'] = [
  {
    value: NmckContractsSortType.RELEVANCE,
    label: 'совпадению',
  },
  {
    value: NmckContractsSortType.PRICE_ASC,
    label: 'возрастанию цены',
  },
  {
    value: NmckContractsSortType.PRICE_DESC,
    label: 'убыванию цены',
  },
];
