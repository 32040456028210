import { memo, useMemo } from 'react';
import { ResultsList } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/components/ResultsList/ResultsList';
import { GuardResultItem } from '@/widgets/NmckGuard/components/NmckGuardPosts/components/GuardPost/components/Results/types';
import { NmckGuardResultsForEachPost } from '@/api/nmckGuardApi/nmckGuardApiTypes';

type Props = {
  result?: NmckGuardResultsForEachPost;
};

export const ResultsDeadline = memo(({ result }: Props) => {
  const list: GuardResultItem[] = useMemo(
    () =>
      result
        ? [
            {
              label: 'Нерабочих федеральных праздничных дней',
              value: result.nonWorkingDaysCount,
            },
            {
              label: 'Нерабочих региональных праздничных дней',
              value: result.regionalNonWorkingDays,
            },
            {
              label: 'Всего нерабочих праздничных дней',
              value: result.nonWorkingDaysCount, // TODO: тут поле из первой строки. так было до рефакторинга. вопрос отложили
            },
            {
              label: 'Количество дней работы поста',
              value: result.workingDaysCount,
            },
            {
              label: 'Нерабочих праздничных часов',
              value: result.holidaysWorkingHours,
            },
            {
              label: 'Дневных часов',
              value: result.daytimeHours,
            },
            {
              label: 'Ночных часов',
              value: result.nighttimeHours,
            },
            {
              label: 'Всего часов по контракту (Ku)',
              value: result.ku,
            },
          ]
        : [],
    [result],
  );

  return (
    <ResultsList
      title="Сроки"
      list={list}
    />
  );
});

ResultsDeadline.displayName = 'ResultsDeadline';
