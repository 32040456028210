import { useContext } from 'react';
import { NmckGridContractsContext } from '@/components/NmckGrid/NmckGridContracts/context/NmckGridContractsContext';

export const useNmckGridContractsContext = () => {
  const nmckGridContractsContext = useContext(NmckGridContractsContext);

  if (!nmckGridContractsContext) {
    throw new Error('NmckGridContractsContext must be used within a NmckGridContractsProvider');
  }

  return nmckGridContractsContext;
};
