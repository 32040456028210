import { memo, useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import Link from 'next/link';
import { ConfigProvider, Flex, Pagination, Select, Table, TableProps } from 'antd';

import { CurrentContext } from '@/widgets/Current/context/context';
import { actions } from '@/widgets/Current/context/actions';
import { PurchasePlanItem, PurchasePlanResponse } from '@/api/currentApi/currentApiTypes';
import { formatPrice } from '@/utils/formatPrice';
import {
  dropdownStyle,
  reestrLink,
  regNumberLink,
  theme,
} from '@/widgets/Current/constants/constants';

import Vicon from '/public/icons/v.svg';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';

import styles from './PlanedTable.module.scss';
import { PAGE_SIZE_OPTIONS } from '@/components/Table/TablePagination/constants';

const getKey = (record: PurchasePlanItem) => {
  return record.ikz;
};

const expandedRowClassName = () => {
  return styles.expandedRow;
};

const expandedRowRender = (record: PurchasePlanItem) => {
  return (
    <>
      {record.linkedPurchase.length !== 0 && (
        <div className={styles.linkedPurchase}>
          <div className={styles.purchaseRow}>
            Извeщeниe №
            <ul className={styles.purchaseList}>
              {record.linkedPurchase.map(({ puchaseRegNumber }, id) => {
                return (
                  puchaseRegNumber && (
                    <li
                      key={id}
                      className={styles.purchaseItem}
                    >
                      <Link
                        href={regNumberLink + puchaseRegNumber}
                        target="_blank"
                        className={styles.purchaseLink}
                      >
                        {puchaseRegNumber}
                        <ExportDiagonalIcon />
                      </Link>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
          <div className={styles.purchaseRow}>
            Контракт №
            <ul className={styles.purchaseList}>
              {record.linkedPurchase.map(({ contractReestrNumber }, id) => {
                return (
                  contractReestrNumber && (
                    <li
                      key={id}
                      className={styles.purchaseItem}
                    >
                      <Link
                        href={reestrLink + contractReestrNumber}
                        target="_blank"
                        className={styles.purchaseLink}
                      >
                        {contractReestrNumber}
                        <ExportDiagonalIcon />
                      </Link>
                    </li>
                  )
                );
              })}
            </ul>
          </div>

          <ul className={styles.expandableText}>
            {record.linkedPurchase.map(({ sum }, id) => {
              return (
                <li
                  key={id}
                  className={styles.purchaseSum}
                >
                  -{formatPrice(sum)}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

type PlannedTableProps = {
  data: PurchasePlanResponse;
  loading: boolean;
};

export const PlannedTable = memo(({ data, loading }: PlannedTableProps) => {
  const { state, dispatch } = useContext(CurrentContext);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpandRow = useCallback(
    (record: PurchasePlanItem) => {
      const newExpandedRowKeys = [...expandedRowKeys];
      const rowIndex = newExpandedRowKeys.indexOf(record.ikz);
      if (rowIndex > -1) {
        newExpandedRowKeys.splice(rowIndex, 1);
      } else {
        newExpandedRowKeys.push(record.ikz);
      }

      setExpandedRowKeys(newExpandedRowKeys);
    },
    [expandedRowKeys],
  );

  const columns = useMemo((): TableProps<PurchasePlanItem>['columns'] => {
    return [
      {
        title: 'Наименование объекта закупки',
        dataIndex: 'name',
        key: 'name',
        width: 525,
      },
      {
        title: '',
        dataIndex: '',
        key: 'expand',
        width: 80,
        render: (_, record) => {
          return (
            <>
              {record.linkedPurchase.length !== 0 && (
                <Flex
                  onClick={() => handleExpandRow(record)}
                  align="center"
                  gap={8}
                  className={styles.linked}
                >
                  <span>Связанные</span>
                  <Vicon className={styles.viconActive} />
                </Flex>
              )}
            </>
          );
        },
      },
      {
        title: 'ИКЗ',
        dataIndex: 'ikz',
        key: 'ikz',
        width: 354,
        className: styles.ikzRow,
        render: (_, { ikz, ikzHref }) => (
          <Link
            href={ikzHref}
            key={ikz}
            className={styles.ikz}
            target="_blank"
          >
            <ExportDiagonalIcon />

            <span className={styles.ikzText}>{ikz}</span>
          </Link>
        ),
      },
      {
        title: `Фин. обесп. на ${moment().year()}, ₽`,
        dataIndex: 'currentFiscalYearPaymentPlan',
        key: 'currentFiscalYearPaymentPlan',
        className: styles.priceRow,
        width: 195,
        render: (_, { currentFiscalYearPaymentPlan }) => {
          return formatPrice(currentFiscalYearPaymentPlan);
        },
      },
      {
        title: 'Остаток, ₽',
        dataIndex: 'remains',
        key: 'remains',
        width: 110,
        className: styles.priceRow,
        render: (_, { remains }) => {
          return <p className={styles.remains}>{formatPrice(remains)}</p>;
        },
      },
      {},
    ];
  }, [handleExpandRow]);

  const rowClassName: TableProps<PurchasePlanItem>['rowClassName'] = useCallback(
    (record: PurchasePlanItem) => {
      return expandedRowKeys.includes(record.ikz) ? styles.expandedRow : '';
    },
    [expandedRowKeys],
  );

  const getExpandable = useMemo((): TableProps<PurchasePlanItem>['expandable'] => {
    return {
      expandedRowKeys,
      expandedRowRender,
      expandedRowClassName,
    };
  }, [expandedRowKeys]);

  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(actions.setPlannedCurrentPage(page));
    },
    [dispatch],
  );

  const handleShowOnPageSelectChange = useCallback(
    (value: number) => {
      dispatch(actions.setPlannedItemsPerPage(value));
    },
    [dispatch],
  );

  const getFooter: TableProps<PurchasePlanItem>['footer'] = useCallback(() => {
    // TODO: Использовать стандартный TableFooter !!
    return (
      <div className={styles.tableFooter}>
        <div className={styles.paginationContainer}>
          <p className={styles.paginationText}>Страницы:</p>
          <Pagination
            current={state.plannedCurrentPage}
            total={data.count}
            pageSize={state.plannedItemsPerPage}
            showSizeChanger={false}
            onChange={handlePageChange}
            className={styles.pagination}
          />
        </div>
        <div className={styles.pageSize}>
          Показывать на странице:
          <Select
            dropdownStyle={dropdownStyle}
            variant="borderless"
            suffixIcon={<Vicon className={styles.iconV} />}
            value={state.plannedItemsPerPage}
            onChange={handleShowOnPageSelectChange}
            options={PAGE_SIZE_OPTIONS}
          />
        </div>
      </div>
    );
  }, [
    data.count,
    handlePageChange,
    handleShowOnPageSelectChange,
    state.plannedCurrentPage,
    state.plannedItemsPerPage,
  ]);

  return (
    <div className={styles.wrapper}>
      <ConfigProvider theme={theme}>
        <Table
          expandable={getExpandable}
          loading={loading}
          columns={columns}
          dataSource={data.results}
          className={styles.table}
          pagination={false}
          rowClassName={rowClassName}
          rowKey={getKey}
          footer={getFooter}
        />
      </ConfigProvider>
    </div>
  );
});

PlannedTable.displayName = 'PlannedTable';
